import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { EnumUserRoles, USER_ROLES } from '../authorization'
import { EmptyGuid } from '../settings'
import { GuidID } from '../types/Item'
import { istrEqual } from '../utils/string-utils'

interface ClientState {
	userRole: EnumUserRoles,
	tenantId: string,
	clientId: string,
	clientName: string,
	username: string,
	userOid: string|null,
	isAdmin: () => boolean,
	setUserRole: (role: EnumUserRoles) => void,
	setTenantId: (id: GuidID) => void,
	setClient: (id: GuidID, name: string) => void,
	setUsername: (username: string) => void,
	setUserOid: (username: string) => void
}

export const useClientStore = create<ClientState>()(
	persist(
		(set, get) => ({
			userRole: USER_ROLES.Anonymous,
			tenantId: EmptyGuid,
			clientId: EmptyGuid,
			clientName: '',
			username: "",
			userOid: null,
			isAdmin: () => {
				const { userRole } = get()
				return istrEqual(userRole, USER_ROLES.Admin)
			},
			setUserRole: (role) => set((state) => {
				return { ...state, userRole: role }
			}),
			setTenantId: (id) => set((state) => ({ ...state, tenantId: id })),
			setClient: (id, name) => set((state) => ({ ...state, clientId:id, clientName: name })),
			setUsername: (username) => set((state) => ({ ...state, username: username })),
			setUserOid: (oid) => set((state) => ({ ...state, userOid: oid })),
		}),
		{
			name: 'smart360', // unique name
			storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
		}
	)
)