import { useClientStore } from "../hooks/clientStore"
import { SmartCardUser, UpdateUserPortalDto } from "../types/Item"
import { PageTitle } from "./PageTitle"
import { useConfirmModal } from "../hooks/useConfirmModal"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { cardApi, updateUserPortal } from "../Services/ProcdedureCardApi"
import { FormEvent, useState } from "react"
import { toast } from "react-toastify"
import { usePortals } from "../hooks/usePortals"
import { istrEqual } from "../utils/string-utils"
import { useFormState } from "../hooks/useFormState"
import { EllipsisSpinner } from './EllipsisSpinner/EllipsisSpinner';

interface assignedStatus {
    id: string
    name: string
    isAssigned: boolean
}

export function SelectPortalPage() {
    const userOid = useClientStore((s) => s.userOid);

    const clientId = useClientStore((state) => state.clientId)
    const setClient = useClientStore(state => state.setClient)

    const { data: portals, isLoading: isPortalsLoading } = usePortals()
    const { formRef, formValues, formIsValid, onChangeHandler, isModified, setIsModified } = useFormState({ userPortal: clientId})

    const {
        modal,
        open: openModal,
        close: closeModal,
    } = useConfirmModal({
        message: "Are you sure to change your portal?",
        confirm: changePortal,
    })

    const queryClient = useQueryClient()

    const changeUserPortal = useMutation({
        mutationFn: (data: UpdateUserPortalDto) =>
            cardApi.post<SmartCardUser>(updateUserPortal, data),
    
        onSuccess: (data) => {
            console.log("### changeUserPortal data = ", data)

            //Update clientId in client store
            setClient(data.data.clientId, data.data.clientName)

            // Reset react query queries
            queryClient.resetQueries()
        },
    })
    
    const [cardIdToDelete, setCardIdToDelete] = useState<assignedStatus>()

    function changePortal() {
        closeModal()
        const selectedPortal = portals!.filter(x => istrEqual(x.clientId, formValues.userPortal))[0]
        changeUserPortal.mutate({ clientId: selectedPortal.clientId, oid: userOid! }, {
            onSuccess: () => {
                toast.info(`Portal changed to ${selectedPortal.clientName}`, { autoClose: 1000 })
            },
            onError: () => {
                const currentPortal = portals!.filter(x => istrEqual(clientId, formValues.userPortal))[0]
                toast.error(`Could not change portalPortal changed to ${currentPortal.clientName}`, { autoClose: 1000 })
            }
        })
    }
  
    const formSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        e.stopPropagation()
        console.log("form=", formRef.current)
        console.log("formValues=", formValues)
        openModal()
    }

    return (
        <>
            <PageTitle title="Select a portal" />
            {isPortalsLoading && <EllipsisSpinner/>}
            {!isPortalsLoading && <form ref={formRef} onSubmit={formSubmit} className="mt-4 flex justify-center">
                <div
                    className="mx-2 mt-1 flex w-10/12 flex-col gap-3 text-white"
                    role="radiogroup"
                >
                    {portals?.map((x, idx) => {
                        return (
                            <label
                                key={x.clientId}
                                htmlFor={x.clientId}
                                className={`relative h-16 flex flex-col justify-center break-all rounded-md bg-sky-700 pl-1 font-medium shadow-md hover:bg-sky-600`}
                            >
                                <input
                                    role="radio"
                                    id={x.clientId}
                                    type="radio"
                                    value={x.clientId}
                                    name="userPortal"
                                    className={`absolute inset-0 appearance-none checked:bg-amber-500 ${istrEqual(clientId, x.clientId)?'bg-green-600':''}`}
                                    onChange={onChangeHandler}
                                />
                                <span className="z-0 text-center">
                                    {x.clientName + ' (' + x.environment + ')'}
                                </span>
                            </label>
                        )
                    })}
                <div className="flex flex-row justify-end items-center my-1">
                    {<button type="submit" className="btn-primary m-1" onClick={() => {}}>Select</button>}
                </div>
                </div>

            </form>}
            {modal}
        </>
    )
}
