/**
 * From Multi-Tenant database table [dbo].[DataFactoryc360ImportStatus]
 */
export const FAILED_TO_MIGRATE_FILE = 1
export const AWAITING_VALIDATION = 2
export const HEADING_VALIDATION_FAILED = 3
export const READY_TO_PROCESS_TO_LIVE = 4
export const IMPORT_COMPLETED = 5
export const ROW_VALIDATION_FAILED = 6
export const READY_TO_IMPORT_TO_LIVE = 7
export const AWAITING_APPROVAL = 8
export const SYSTEM_ISSUE = 9
export const APPROVAL_DENIED = 10
