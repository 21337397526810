import { NavLink } from "react-router-dom";
import { useUsers } from "../hooks/useUsers";
import { USERS } from "../routing/routes";
import { compareUsers } from "../types/Item";
import { EllipsisSpinner } from "./EllipsisSpinner/EllipsisSpinner";
import { PageTitle } from "./PageTitle";

export function ManageUsersPage() {
    const { isLoading, data } = useUsers()
    return (<>
        <PageTitle title={"Manage Users"} />
        {isLoading && <EllipsisSpinner />}
        {data?.sort(compareUsers).map(x =>
            <div key={x.userId} className="flex sm:justify-center items-center sm:h-12 odd:bg-white even:bg-slate-200 hover:bg-slate-300">
                <NavLink to={`${USERS}/${x.userId}`} className="sm:w-1/2">
                    <div className="w-full flex flex-col sm:grid sm:grid-cols-6">
                        <div className="sm:col-span-2">{x.displayName}</div>
                        <div className="sm:col-span-3">{x.username}</div>
                        <div className="sm:col-span-1">{x.roleName}</div>
                    </div>
                </NavLink>
            </div>
        )}
    </>)
}