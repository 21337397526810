import { FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { USER_ROLES } from "../authorization";
import { useFormState } from "../hooks/useFormState";
import { useUsers } from "../hooks/useUsers";
import { istrEqual } from "../utils/string-utils";
import { EllipsisSpinner } from "./EllipsisSpinner/EllipsisSpinner";
import { PageTitle } from "./PageTitle";


export function UpdateUserPage() {
    const { userId } = useParams();
    const { isLoading, data } = useUsers()
    const navigate = useNavigate()
    const { formRef, formValues, formIsValid, onChangeHandler, isModified, setIsModified } = useFormState({ userRole: USER_ROLES.Anonymous })

    if (isLoading) {
        return <EllipsisSpinner />
    }

    const found = data?.find(x => x.userId === userId)

    const formSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        toast.info('Feature not implemented yet!')
    }

    const cancelHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        navigate(-1)
    }

    return (
        <>
            <PageTitle title="Add User Page" />
            <form onSubmit={formSubmit} ref={formRef} className="px-4 text-md font-normal text-gray-600">
                <div className="mb-6">
                    <h2 className="text-2xl block mb-2 ">{found?.displayName}</h2>
                    <div className="font-semibold">Role</div>
                    <div className="flex flex-col border border-gray-100 pl-1 w-full sm:w-1/4">
                        <div className="flex items-center">
                            <input className="" id="role-radio-1" type="radio" value="User" name="userRole" onChange={onChangeHandler} defaultChecked={istrEqual(found?.roleName ?? USER_ROLES.Anonymous, USER_ROLES.User)} />
                            <label htmlFor="role-radio-1" className="ml-1">Regular user</label>
                        </div>
                        <div className="flex items-center">
                            <input id="role-radio-2" type="radio" value="Admin" name="userRole" onChange={onChangeHandler} defaultChecked={istrEqual(found?.roleName ?? USER_ROLES.Anonymous, USER_ROLES.Admin)} />
                            <label htmlFor="role-radio-2" className="ml-1">Admin</label>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-start items-center my-1">
                    <button type="submit" className="btn-primary m-1" disabled={!isModified || !formIsValid()}>Update user</button>
                    <button type="button" className="btn-primary m-1" disabled>Delete</button>
                    <button type="button" className="btn-danger m-1" onClick={cancelHandler}>Cancel</button>
                </div>
            </form>
        </>
    )
}
