import { ChangeEvent, FormEvent, useContext, useRef, useState } from "react";
import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query';
import { useClientStore } from "../hooks/clientStore";
import { cardApi, cardApiCreate } from "../Services/ProcdedureCardApi";
import { Basket, getImageUrl, Item } from "../types/Item";
import { FormInput } from "./FormInput";
import { LocationsSelect, SelectOption } from "./LocationsSelect";
import { procedureTypes } from "../../data";
import { useLocationData } from "../hooks/useLocationData";
import { NumberSpin2 } from "./NumberSpin2";
import { useNavigate, useParams } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { PageTitle } from "./PageTitle";
import { useConfirmModal } from "../hooks/useConfirmModal";
import { getSelectAccountingLocations } from "../utils/location";
import { qkSmartCardList } from "../hooks/queryKeys";
import { SmartCardCreateDto } from "../dtos";
import { toTitleCase } from "../utils/string-utils";
import { useImagePrefix } from "../hooks/useImagePrefix";
import { LIST_PAGE } from "../routing/routes";
import { BasketContext } from "../App";

export enum EnumTasks {
    CreateSmartCard = 1,
    AssignAccountingLocation = 2,
    ViewSmartCard = 3
}

interface BasketPageProps {
    task?: EnumTasks
}

export function BasketPage({ task = EnumTasks.CreateSmartCard }: BasketPageProps) {
    const params = useParams()
    const navigate = useNavigate()
    const clientId = useClientStore(s => s.clientId)
    const { data: clientLocations } = useLocationData(clientId)
    const { data: imagePrefix } = useImagePrefix(clientId)

    const [itemToRemove, setItemToRemove] = useState<number | null>(null)

    const queryClient = useQueryClient()

    // Form
    const formRef = useRef<HTMLFormElement>(null)
    const [formValues, setFormValues] = useState({
        name: '',
        location: '',
        type: '',
    })

    // Add new card
    const createCard = useMutation({mutationKey: ['newCard'], mutationFn: (data: SmartCardCreateDto) => cardApi.post(cardApiCreate, data), 
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey:[qkSmartCardList]})
            basketContext?.setBasket(new Map<number, Item>())
            navigate(LIST_PAGE)
        },
    })

    const isMutating = Boolean(useIsMutating({mutationKey: ['newCard']}))

    // Basket
    const basketContext = useContext(BasketContext)
    const basket = basketContext?.basket

    function isBasket(basket: Basket | undefined): basket is Basket {
        return basket !== undefined;
    }

    // Modal
    const { modal: removeItemModal, open: openItemModal, close: closeItemModal } = useConfirmModal({message: 'Please confirm removal of the item', confirm: removeItemFromBasket})
    const { modal: clearBasketModal, open: openClearBasketModal, close: closeClearBasketModal } = useConfirmModal({message: 'Are you sure you wish to clear the basket?', confirm: clearBasket})

    const confirmItemRemoval = (itemId: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
        setItemToRemove(itemId)
        openItemModal()
    }

    function removeItemFromBasket() {
        closeItemModal()
        if (itemToRemove) {
            basketContext?.basket.delete(itemToRemove)
            basketContext?.setBasket(new Map<number, Item>(basketContext.basket))
            toast.info('Item removed from basket', { autoClose: 1000 })
        }
    }

    const itemQtyChanged = (itemId: number) => (quantity: number | undefined) => {
        if (isBasket(basket)) {
            const found = basket.get(itemId)
            if (found !== undefined) {
                found.pickQty = quantity ?? 0
                basketContext?.setBasket(new Map<number, Item>(basketContext.basket))
            }
        }
    }

    function confirmClearBasket() {
        openClearBasketModal()
    }

    function clearBasket() {
        basketContext?.setBasket(new Map<number, Item>())
        closeClearBasketModal()
        toast.info('Basket is cleared')
    }

    //--- Form ---
    // TODO - Get data from Api
    const types: Array<SelectOption> = procedureTypes.map((x) => ({ id: x.ProcedureTypeID.toString(), name: x.ProcedureTypeName }))

    const formInputProps = [{
        id: 1,
        name: 'name',
        label: 'Name',
        placeholder: 'Smart card name',
        errorMessage: 'Name is required.',
        type: 'text',
        required: true,
    }
    ]

    function getDto(): SmartCardCreateDto {
        const dto: SmartCardCreateDto = {
            name: formValues.name,
            clientId: clientId,
            procedureTypeId: Number(formValues.type),
            items: (basketContext?.basket) ? Array.from(basketContext?.basket, ([key, value]) => { return value }) : [],
        }
        return dto
    }

    const formSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        createCard.mutate(getDto(), {
            onSuccess: (data) => toast.info(`New smart card added`),
            onError: (data, variables, context) => {
                toast.info(`Error encountered when attempting to add new smart card`)
            },
        })
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value })
    }

    function tableHeading() {
        return (
            <div className={`text-xs font-medium text-left uppercase bg-sky-500 text-white hidden ${smColumnCount(task)}`} role="rowgroup">
                <div className="py-3 sm:block sm:col-span-1" role="columnheader">
                </div>
                <div className="py-3 sm:col-span-3" role="columnheader">
                    Name
                </div>
                <div className="py-3 sm:col-span-2" role="columnheader">
                    Quantity
                </div>
                <div className="py-3" role="columnheader">
                    Qty on hand
                </div>
                {task === EnumTasks.AssignAccountingLocation &&
                    <div className="py-3" role="columnheader">
                        Pick location
                    </div>
                }
                <div className="py-3 sm:col-span-2" role="columnheader">
                    Manufacturer
                </div>
            </div>
        )
    }

    function formIsValid() {
        if (formRef.current) {
            return formRef.current.checkValidity()
        }
        return false
    }

    function smColumnCount(task: EnumTasks) {
        switch (task) {
            case EnumTasks.CreateSmartCard:
                return 'sm:grid sm:grid-cols-10'
            case EnumTasks.AssignAccountingLocation:
                return 'sm:grid sm:grid-cols-11'
            case EnumTasks.ViewSmartCard:
                return 'sm:grid sm:grid-cols-11'
            default:
                return 'sm:grid sm:grid-cols-9'
        }
    }

    return (
        <>
            <PageTitle title={"Basket"} />
            <form ref={formRef} onSubmit={formSubmit} >
                <div className="grid grid-cols-1 grid-rows-2 sm:grid-cols-2 sm:grid-rows-1 sm:gap-2
                bg-gray-200 text-gray-600 rounded mb-1 p-1">
                    {formInputProps.map((item) => {
                        const { id, ...noid } = item
                        return (
                            <div className="grid grid-cols-3 gap-2 my-1 sm:mt-0" key={id}>
                                <div className="rounded col-span-1 bg-sky-600 pl-1 text-sky-50 align-middle">{item.label}</div>
                                <div className="rounded col-start-2 col-span-2 bg-white">
                                    {params.pcId ? null : <FormInput className="w-full h-full rounded " key={item.id} {...noid} onChange={handleOnChange} />}
                                </div>
                            </div>
                        )
                    })}
                    {task === EnumTasks.AssignAccountingLocation &&
                        <div className="grid grid-cols-3 gap-2 mb-1">
                            <div className="rounded col-span-1 bg-sky-600 pl-1 text-sky-50 align-middle">Location</div>
                            <div className="rounded col-span-2">
                                <LocationsSelect name="location" required errorMessage="Location is required."
                                    className="w-full py-1.5 rounded"
                                    data={getSelectAccountingLocations(clientLocations ?? [])}
                                    onChange={handleOnChange} />
                            </div>
                        </div>
                    }
                    {task === EnumTasks.CreateSmartCard &&
                        <div className="grid grid-cols-3 gap-2 mb-1">
                            <div className="rounded col-span-1 bg-sky-600 pl-1 text-sky-50 align-middle">Card type</div>
                            <div className="rounded col-span-2">
                                <LocationsSelect name="type" required errorMessage="Card type is required."
                                    className="w-full py-1.5 rounded"
                                    data={types}
                                    onChange={handleOnChange} />
                            </div>
                        </div>
                    }
                </div>
                {tableHeading()}
                {basket && [...basket.keys()].map(k => {
                    const item = basket.get(k)
                    if (item) {
                        return (
                            <div key={item.itemID} className={`font-normal text-gray-600 pb-5 sm:pb-0 mb-3 sm:mb-0 rounded-lg sm:rounded-none m-2 sm:m-0 
                                    flex flex-col ${smColumnCount(task)} bg-sky-200 sm:bg-white row-hover shadow-lg sm:shadow-none odd:bg-white even:bg-gray-100`}>
                                <div className="py-4 sm:py-0 px-6 sm:px-0 sm:m-1 hidden sm:flex sm:justify-center sm:col-span-1">
                                    <img className="img-24" src={getImageUrl(item.image, imagePrefix)} />
                                </div>
                                <div className="py-4 sm:py-0 px-6 sm:px-0 text-xl sm:text-sm font-large sm:font-normal sm:col-span-3 flex sm:self-center">
                                    <div className="w-full text-center sm:text-start mt-1">
                                        <div>{toTitleCase(item.name)}</div>
                                        <div className="text-red-600">{item.code}</div>
                                    </div>
                                </div>
                                <div className="py-4 sm:py-1 px-6 sm:px-0 text-gray-600 whitespace-nowrap sm:col-span-2 h-30 sm:h-fit flex flex-row justify-center sm:justify-start items-center sm:self-center">
                                    <div className="text-5xl sm:text-sm w-1/2 sm:w-20 h-32 sm:h-fit sm:pl-2">
                                        <NumberSpin2
                                            className="h-full border border-sky-300 sm:border-gray-300 shadow-md sm:shadow-none"
                                            name='quantity'
                                            start={item.pickQty}
                                            min={0}
                                            display='vertical'
                                            onChange={itemQtyChanged(item.itemID)} />
                                    </div>
                                    <span className="ml-1 lowercase">{item.uomName}</span>
                                </div>
                                <div className="hidden sm:block pt-4 sm:pt-0 px-6 sm:px-0 text-sm sm:flex-auto sm:w-1/12 sm:self-center">
                                    <div className="line-heading">On hand quantity</div>
                                    <div className="line-text">
                                        {item.lastcheckonhandqty ?? 'unknown'}
                                    </div>
                                </div>
                                {task === EnumTasks.AssignAccountingLocation &&
                                    <div className="px-6 sm:px-0 text-sm  sm:flex-auto sm:w-1/12 sm:self-center">
                                        <div className="line-heading">Pick location</div>
                                        <div className="line-text">
                                            {'Pick location'}
                                        </div>
                                    </div>
                                }
                                <div className="hidden sm:block px-6 sm:px-0 text-sm sm:col-span-2 sm:self-center">
                                    <div className="line-heading">Manufacturer</div>
                                    <div className="line-text">
                                        {toTitleCase(item.manufacturer)}
                                    </div>
                                </div>
                                {task === EnumTasks.CreateSmartCard &&
                                    <div className="flex justify-center items-start sm:self-center">
                                        <button type="button" onClick={confirmItemRemoval(item.itemID)}>
                                            <TrashIcon className="h-5 w-5 stroke-red-600 hover:bg-orange-300" />
                                        </button>
                                    </div>
                                }
                            </div>
                        )
                    }
                })
                }
                <hr />
                <div className="flex flex-row justify-end items-center my-1 ">
                    <button type="button" className="btn-danger m-1" onClick={() => confirmClearBasket()} disabled={createCard.isPending || basket?.size === 0}>Clear basket</button>
                    <button type="submit" className="btn-primary m-1" disabled={createCard.isPending || !formIsValid() || isMutating}>Create smart card</button>
                </div>
            </form>
            {removeItemModal}
            {clearBasketModal}
        </>
    );
}
