import { useEffect, useState } from "react";
import { IdName, NoOp } from "../types/Item";
import { useConfirmModal } from "../hooks/useConfirmModal";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";

interface TabsProps {
    options: Array<IdName>;
    onTabSelected: (item: IdName) => void;
    onTabDeleted?: (item: IdName) => void;
    onNewTab?: () => void;
    hasDataChanged?: () => boolean;
    defaultOption?: IdName;
    saveChanges?: () => void;
}

export function Tabs({
    options,
    onTabSelected = (item: IdName) => {},
    onTabDeleted = (item: IdName) => {},
    saveChanges = NoOp,
    onNewTab = NoOp,
    hasDataChanged = () => false,
    defaultOption,
}: TabsProps) {
    const [currentTabs, setCurrentTabs] = useState(options);
    const [active, setActive] = useState<string | null>(
        defaultOption?.id?.toString() ?? null
    );

    useEffect(() => {
        setCurrentTabs([...options]);
    }, [options]);

    useEffect(() => {
        if (defaultOption) {
            setActive(defaultOption.id);
        }
    }, [defaultOption]);

    const { modal, open, close } = useConfirmModal({
        message: "Save changes made to the current map?",
        confirm: () => {
            saveChanges();
            close();
        },
        confirmButtonText: "Yes",
        cancelButtonText: "No",
    });

    /**
     * What to do when user select a tab
     */
    async function tabSelected(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        item: IdName
    ) {
        event.stopPropagation();

        // Do nothing if it's the current active tab
        if (item.id === active) {
            return false;
        }

        // Check if user needs to save data via a modal
        if (hasDataChanged()) {
            await open();
        }
        setActive(item.id);
        onTabSelected(item);
    }

    /**
     * User select add new tab button
     */
    async function addNewTab(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) {
        if (hasDataChanged()) {
            await open();
        }
        onNewTab();
    }

    useEffect(() => {
        setActive(defaultOption?.id?.toString() ?? null);
    }, [defaultOption]);

    return (
        <>
            <div className="flex flex-row">
                <div className="grid w-full grid-cols-fluid bg-white">
                    {currentTabs.map((item) => {
                        return (
                            <div
                                key={item.id}
                                className={`relative flex w-full rounded-t-xl border-x border-t ${
                                    active === item.id
                                        ? "bg-hte-light-gray"
                                        : "border-b bg-white"
                                }`}
                            >
                                <button
                                    type="button"
                                    key={item.id}
                                    className={`z-0 h-9 pt-2 w-full overflow-hidden text-sm text-gray-500 whitespace-nowrap mx-0.5 ${(active === item.id)&&'font-medium'}`}
                                    onClick={(e) => tabSelected(e, item)}
                                >
                                    {item.name}
                                </button>
                                <button type="button">
                                    <XMarkIcon
                                        className="absolute right-1 top-1 z-10 h-3 w-3 text-slate-500"
                                        onClick={(e) => onTabDeleted(item)}
                                    />
                                </button>
                            </div>
                        );
                    })}
                </div>
                <button
                    type="button"
                    className="flex w-[40px] items-center justify-center border-b"
                    onClick={addNewTab}
                >
                    <PlusIcon className="h-4 w-4" />
                </button>
            </div>
            {modal}
        </>
    );
}
