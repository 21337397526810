import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useClientStore } from "../hooks/clientStore";
import { USER_ROLES } from "../authorization";
import { AddUserDto } from "../dtos";
import { cardApi, cardApiAddUser } from "../Services/ProcdedureCardApi";
import { EmptyGuid } from "../settings";
import { SmartCardUser } from "../types/Item";

export function ValidateUser() {
    const navigate = useNavigate()

    const setTenantId = useClientStore(state => state.setTenantId)
    const setClient = useClientStore(state => state.setClient)
    const setUserRole = useClientStore(state => state.setUserRole)
    const setUsername = useClientStore(state => state.setUsername)
    const setUserOid = useClientStore(state => state.setUserOid)
    
    const addUser = useMutation({ mutationKey: ['user'], mutationFn: (user: AddUserDto) => cardApi.post<SmartCardUser>(cardApiAddUser, user) })

    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal()

    useEffect(() => {
        if (isAuthenticated) {
            const account = instance.getActiveAccount()
            if (account && account.idTokenClaims) {

                addUser.mutate({
                    tenantId: account.tenantId,
                    oidClaim: account.idTokenClaims['oid']!,
                    nameClaim: account.idTokenClaims['name']!,
                    preferredUsernameClaim: account.idTokenClaims['preferred_username']!,
                }, {
                    onError: (error, variables, context) => {
                        if (error instanceof Error) {
                            toast.error(`Error validating user: ${error.message}`)
                        } else {
                            toast.error(`I'm sorry but I am not able to validate your login at the current time.`)
                        }
                        setUserRole(USER_ROLES.Anonymous)
                        navigate('/message', { state: 'Please contact your software vendor for assistance.' })
                    },
                    onSuccess: (data) => {
                        setTenantId(data.data.tenantId)
                        setClient(data.data.clientId, data.data.clientName)
                        setUserRole(data.data.roleName)
                        setUsername(data.data.username)
                        setUserOid(data.data.oid)
                        if (data.data.clientId && data.data.clientId === EmptyGuid) {
                            navigate('/message', { state: 'Please request your software vendor to setup your client id.' })
                        }
                    }
                })
            }
        }
    }, [isAuthenticated]);
    return (null)
}