import { EmptyGuid } from './../settings';
import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { qkImagePrefix } from "./queryKeys"
import { cardApi, getImageHost } from '../Services/ProcdedureCardApi';
import { istrEqual } from '../utils/string-utils';

interface ImagePrefixType {
    imageHost: string
}

const fetchImagePrefix = ({ queryKey }: QueryFunctionContext): Promise<ImagePrefixType> => {
    const clientId = queryKey[1] as string
    if (!clientId || istrEqual(clientId, EmptyGuid)) {
        return new Promise(resolve => '')
    }
    return cardApi.get(`${getImageHost}clientId=${clientId}`).then(response => response.data)
}

export function useImagePrefix(clientId: string) {
    return useQuery({queryKey: [qkImagePrefix, clientId], queryFn: fetchImagePrefix, select: (data) => data.imageHost })
}