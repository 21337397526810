
export function istrEqual(a: string, b: string) {
    return a.toLowerCase() === b.toLowerCase()
}

/**
 * Returm string if it is falsey. Only convert non falsey words
 * @param str - String to convert
 * @returns 
 */
export function toTitleCase(str: string): string {
    if (!str) {
        return str
    }

    return str.toLowerCase()
        .split(' ')
        .map(function (word) {
            if (!word) 
                return word
            return word.replace(word[0], word[0].toUpperCase());
        })
        .join(' ');
}

function randomString(length:number, chars:string) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
}

/**
 * This is from c360. This is how it creates value for the class and data-rid attributes for a table row (tr) in the requisition screen.
 * @returns 
 */
export function getRandomId() {
    return randomString(16, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
}

export function humanFileSize(size: number) {
    const sizeUnits = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) + ' ' + sizeUnits[i];
}

export function randomInteger(range: number=10000) {
    return Math.ceil(Math.random() * range)
}

export function capitalize(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}