import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { IProcedureCard, Item, ItemPricing } from "../types/Item";
import { useIsMutating, useMutation } from "@tanstack/react-query";
import {
    cardApi,
    cardApiAddToBasket,
    cardApiUpdateInventory,
} from "../Services/ProcdedureCardApi";
import { useClientStore } from "../hooks/clientStore";
import { UserItemCard } from "./UserItemCard";
import { AccountingLocation } from "../utils/location";
import {
    AddToBasketDto,
    InventoryItemDto,
    InventoryItemUpdateDto,
    ItemInventoryDto,
    ItemRequestLineDto,
} from "../dtos";
import { useInventory } from "../hooks/useInventory";
import { useItemPricing } from "../hooks/useItemPricing";
import { useAccount } from "@azure/msal-react";
import { useRequestStatusModal } from "../hooks/useRequestStatusModal";
import {
    MSG_NO_ACCOUNTING_LOCATION,
    MSG_NO_INVENTORY,
    MSG_NO_PRICING,
    MSG_PROMISE_REJECTED,
} from "../messages";
import { useIdSearch } from "../hooks/useIdSearch";
import { ItemSourceDto } from "../Services/ProductSearch";
import { LogRequestItemsDto, useLogRequestItems } from "../DataLoader/data-services/useLogRequestItems";

interface ItemAudit {
    itemID: number;
    readonly oldPickQuantity: number;
    readonly oldPickLocationID: string;
    newPickQuantity: number;
    newPickLocationID: string;
}

interface UserItemsTableProps {
    /** Items in the smart card. Users can modify quantities or pick locations */
    data: Item[];
    /** A smart card record */
    smartCard: IProcedureCard;
    /** An existing accounting location */
    accountingLocation: AccountingLocation;
}

/**
 * This component handles the regular user view of a smart card with an assigned accounting location.
 * This is the only view allowed to such users apart from login/logout.
 * Such users can change the quantities and pick locations of the items on display before submitting.
 * Submission will result in inventory updates and a request sent to catalog360 when pricing are available.
 * @param param0
 * @returns
 */
export function UserItemsTable({
    smartCard,
    data: initialItems,
    accountingLocation,
}: UserItemsTableProps) {
    const userCols = [
        "",
        "Item Name",
        "Quantity",
        "Qty on hand",
        "Pick location",
        "Manufacturer",
    ];

    const [itemsOnCard, setItemsOnCard] = useState<Array<Item>>(initialItems);
    const [isModified, setIsModified] = useState(false);
    const sendToBasket = useMutation({
        mutationFn: (data: AddToBasketDto) =>
            cardApi.post(cardApiAddToBasket, data)
    });
    const isCardUpdating = Boolean(useIsMutating());
    const clientId = useClientStore((s) => s.clientId);
    const userOid = useClientStore((s) => s.userOid);
    const accountInfo = useAccount();
    const {
        modal,
        open: openStatusModal,
        setStatusData,
    } = useRequestStatusModal();

    const itemIds = useMemo(() => {
        return itemsOnCard.map((x) => x.itemID).join();
    }, [itemsOnCard]);

    // Inventory
    const inventoryQuery = useInventory(
        accountingLocation?.locationID ?? "",
        itemIds
    );
    const updateInventory = useMutation({
        mutationFn: (data: InventoryItemUpdateDto) =>
            cardApi.post(cardApiUpdateInventory, data),
        mutationKey: ["inventory"],
    });
    const updateInventoryPayload = useRef<InventoryItemUpdateDto>(); // Use for status report
    const basketRequestPayload = useRef<AddToBasketDto>(); // Use for status report
    const pricingQuery = useItemPricing(
        accountingLocation?.salesAccountUid,
        itemIds
    );
    const { data: details } = useIdSearch(itemIds);
    const logRequestItemsApi = useLogRequestItems()

    useEffect(() => {
        const updated = itemsOnCard.map((item) => {
            const found = inventoryQuery.data?.find(
                (x) => x.itemId === item.itemID
            );
            if (found) {
                item.lastcheckonhandqty = found.lastCheckOnHandQty;
            }
            return item;
        });
        setItemsOnCard(updated);
    }, [inventoryQuery.data]);

    /**
     * Audit user modifications
     */
    function initItemAuditList(itemsList: Array<Item>): Map<number, ItemAudit> {
        const audit = new Map<number, ItemAudit>();
        if (itemsList) {
            itemsList.forEach((x) => {
                audit.set(x.itemID, {
                    itemID: x.itemID,
                    oldPickLocationID: x.pickLocationID,
                    oldPickQuantity: x.pickQty,
                    newPickLocationID: x.pickLocationID,
                    newPickQuantity: x.pickQty,
                });
            });
        }
        return audit;
    }

    const itemAuditList = useRef(initItemAuditList(initialItems));

    /**
     * Update item
     */
    function getItem(itemID: number): Item {
        let found = itemsOnCard.find((item) => item.itemID === itemID);
        if (!found) {
            throw new Error("Smart card item not found");
        }
        return found;
    }

    const auditTrackItem = <K extends keyof ItemAudit>(
        itemId: number,
        key: K,
        value: ItemAudit[K]
    ) => {
        const audit = itemAuditList.current.get(itemId);
        if (audit) {
            audit[key] = value;
        } else {
            throw new Error("Audit item not found");
        }
    };

    const quantityChanged =
        (itemId: number) => (quantity: number | undefined) => {
            let found = getItem(itemId);

            if (found.pickQty === quantity) {
                return;
            }

            found.pickQty = quantity ?? 0;
            setItemsOnCard([...itemsOnCard]);
            setIsModified(true);

            auditTrackItem(itemId, "newPickQuantity", found.pickQty);
        };

    const pickLocationChanged =
        (itemId: number) =>
        (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            let found = getItem(itemId);

            if (found.pickLocationID == e.target.value) return;

            found.pickLocationID = e.target.value;
            setItemsOnCard([...itemsOnCard]);
            setIsModified(true);

            auditTrackItem(itemId, "newPickLocationID", found.pickLocationID);
        };

    function getChangedItemIds(itemAuditList: Map<number, ItemAudit>) {
        const changed = new Array<number>();
        itemAuditList.forEach((value, key) => {
            if (
                value.newPickLocationID !== value.oldPickLocationID ||
                value.newPickQuantity !== value.oldPickQuantity
            ) {
                changed.push(key);
            }
        });
        return changed;
    }

    /**
     * Log updates to item quantities and pick location.
     * Only update changed values.
     * @param itemsInCard
     */
    const auditLogUserUpdates = (
        itemAuditList: Map<number, ItemAudit>
    ): void => {
        const auditIds = getChangedItemIds(itemAuditList);
        // TODO - After successful submission we'll need to reset the audit list to avoid logging the same thing again
    };

    function makeRequestLine(
        lineNo: number,
        itemToOrder: Item,
        pricing: ItemPricing,
        qtyToOrder: number,
        details: ItemSourceDto
    ) {
        const goodstotal = qtyToOrder * pricing.unitPrice;
        const tax = goodstotal * pricing.taxRate;
        const reqLine: ItemRequestLineDto = {
            Line: lineNo,
            Itemid: itemToOrder!.itemID.toString(), //ItemId.toString() - It's number everywhere else
            Code: pricing!.itemCode,
            Sdesc: itemToOrder!.name, //Item name
            Unitprice: pricing.unitPrice,
            PackQty: pricing.packQty,
            Uom: pricing.uomCode,
            Uomdesc: itemToOrder!.uomName,
            Unitquantity: qtyToOrder,
            Minorder: pricing.minOrderQty,
            Freetext: false,
            Goodstotal: goodstotal,
            Tax: tax,
            Linetotal: goodstotal + tax,
            Currency: pricing.currency,
            CanView: true,
            NormalPackPrice: pricing.normalUnitPrice,
            NormalUnitPrice: pricing.normalUnitPrice,
            PackPrice: pricing.unitPrice,
            Savings: pricing.savings,
            ContractCode: pricing.contractCode,
            Supplier: details.supplierName,
            Suppliercode: details.supplierItemCode,
            Manufacturer: details.manufacturerName,
            ManufacturerCode: details.manufacturerCode,
            ActualUnitPrice: pricing.pricePerUnit,
        };
        return reqLine;
    }

    /**
     * Basket request and inventory request are mutually exclusive with respect to items. An item that has
     * inventory information will not be submitted in a basket request.
     * As inventory and pricing information might not be available for some items,
     * we only submit request line for items with pricing info available.
     * @param itemsList List of items on smart card
     * @param inventory Item inventory information
     * @param pricing Item pricing information
     */
    function sendRequestLinesToC360Basket(
        itemsList: Array<Item>,
        inventory: Array<ItemInventoryDto>,
        pricing: Array<ItemPricing>,
        details: Array<ItemSourceDto>
    ) {
        if (!accountingLocation) {
            return Promise.reject(new Error(MSG_NO_ACCOUNTING_LOCATION));
        }

        if (pricing.length === 0) {
            return Promise.reject(MSG_NO_PRICING);
        }

        // Create basket line items for items having pricing info
        const reqLines: Array<ItemRequestLineDto> = [];
        pricing.forEach((p, index) => {
            const foundInventory = inventory.find((x) => x.itemId === p.itemID);
            const foundItem = itemsList.find((x) => x.itemID === p.itemID);
            const foundDetails = details.find((x) => x.itemID === p.itemID);

            if (!foundInventory) {
                // raise req line for pickqty
                const reqLine = makeRequestLine(
                    index + 1,
                    foundItem!,
                    p,
                    foundItem!.pickQty,
                    foundDetails!
                );
                reqLines.push(reqLine);
            }
        });

        const payload: AddToBasketDto = {
            Siteurl: document.location.origin,
            ClientId: clientId,
            Username: accountInfo ? accountInfo?.username : "USER NOT KNOWN!",
            Salesaccountuid: accountingLocation.salesAccountUid,
            Salesaccountcode: accountingLocation.salesAccountCode,
            Billtoaddressuid: accountingLocation.billToAddressUid,
            Billtoaddresscode: accountingLocation.billToAddressCode,
            Shiptoaddressuid: accountingLocation.shipToAddressUid,
            Shiptoaddresscode: accountingLocation.shipToAddressCode,
            Goodstotal: reqLines.reduce((acc, el) => {
                return acc + el.Goodstotal;
            }, 0),
            Total: reqLines.reduce((acc, el) => {
                return acc + el.Linetotal;
            }, 0),
            Currency: pricing[0].currency,
            Items: reqLines,
        };

        basketRequestPayload.current = payload;

        return sendToBasket.mutateAsync(payload);
    }

    /**
     * Perform actions when a regular user submit this form:
     * We are only interested with items having inventory info.
     *  - Update item on hand quantity in inventory
     *  - Audit log user changes
     *  - Add order lines to c360 cart
     */
    function submitInventoryUpdates(inventory: Array<ItemInventoryDto>) {
        if (!accountingLocation?.locationID) {
            return Promise.reject(new Error(MSG_NO_ACCOUNTING_LOCATION));
        }

        const newdOnHandQtys: Array<InventoryItemDto> = inventory.map((x) => {
            const found = itemsOnCard.find((y) => y.itemID === x.itemId)!;
            return {
                itemId: x.itemId,
                LastCheckOnHandQty: x.lastCheckOnHandQty - found.pickQty,
            };
        });

        const qtyDto: InventoryItemUpdateDto = {
            clientId: clientId,
            salesAddressId: accountingLocation?.locationID,
            items: newdOnHandQtys,
        };

        updateInventoryPayload.current = qtyDto;

        if (newdOnHandQtys.length === 0) {
            return Promise.resolve(MSG_NO_INVENTORY);
        }

        return updateInventory.mutateAsync(qtyDto, {
            onError: (error) => {
                if (error instanceof Error) {
                    setIsModified(true);
                }
            },
            onSuccess: () => {
                setIsModified(false);
                inventoryQuery.refetch();
            },
        });
    }

    /**
     * Display an item in a regular user view
     * @param item
     * @returns JSX.Element
     */
    function showUserItem(item: Item) {
        return (
            <UserItemCard
                key={item.itemID}
                data={item}
                quantityChanged={quantityChanged(item.itemID)}
                pickLocationChanged={pickLocationChanged(item.itemID)}
                accountingLocation={accountingLocation}
                inventory={inventoryQuery.data ?? []}
            />
        );
    }

    function smGridStyles(colCount: number) {
        if (colCount === 7) {
            return "smartcard";
        }
        return `sm:grid sm:grid-cols-${colCount} sm:gap-x-2`;
    }

    /**
     * Show table grid header row
     * @param headerCols
     * @returns JSX.Element
     */
    function showHeader(headerCols: string[]): JSX.Element {
        return (
            <div
                className={`hidden bg-sky-500 font-medium uppercase text-white ${smGridStyles(
                    headerCols.length
                )}`}
                role="row"
            >
                {headerCols.map((x, idx) => (
                    <div className="py-3 text-xs" role="columnheader" key={idx}>
                        {x}
                    </div>
                ))}
            </div>
        );
    }

    interface logSubmittedRequestItemsArgs {
        requestOrderRef: string;
        userOid: string;
        clientId: string;
        smartCard: IProcedureCard;
        accountingLocation: AccountingLocation;
        itemsInRequest: Array<ItemRequestLineDto>;
        itemsOnCard: Array<Item>;
    }

    function logSubmittedRequestItems({
        requestOrderRef, 
        userOid,
        clientId,
        smartCard,
        accountingLocation,
        itemsInRequest,
        itemsOnCard,
    }: logSubmittedRequestItemsArgs) {
        const requestItems = itemsInRequest.map((x) => {
            const found = itemsOnCard.find(
                (y) => y.itemID === parseInt(x.Itemid)
            );
            return {
                requestOrderRef: requestOrderRef,
                userOid: userOid,
                clientId: clientId,
                smartCardId: smartCard.procedureCardID,
                locationId: accountingLocation.locationID,
                locationName: accountingLocation.locationName,
                itemId: parseInt(x.Itemid),
                pickQuantity: x.Unitquantity,
                pickLocationId: found?.pickLocationID,
            };
        });

        const dto: LogRequestItemsDto = {
            requestItems: requestItems
        }

        logRequestItemsApi.mutate(dto)
    }

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const inventoryReq = submitInventoryUpdates(inventoryQuery.data ?? []);
        auditLogUserUpdates(itemAuditList.current);
        const basketReq = sendRequestLinesToC360Basket(
            itemsOnCard,
            inventoryQuery.data ?? [],
            pricingQuery.data?.pricing ?? [],
            details ?? []
        );

        Promise.allSettled([inventoryReq, basketReq]).then((results) => {
            const invRes = results[0];
            const basketRes = results[1];
            let reqNo: string | undefined = undefined;
            let basketRequestSuccess: boolean;
            let inventoryRequestSuccess = true;
            let basketRequestError: string | undefined;
            let inventoryRequestError: string | undefined;

            if (invRes.status === MSG_PROMISE_REJECTED) {
                //Inventory request failed
                inventoryRequestError = invRes.reason;
                inventoryRequestSuccess = false;
            }

            if (basketRes.status === MSG_PROMISE_REJECTED) {
                //Basket request failed
                basketRequestSuccess = false;
                basketRequestError = basketRes.reason;
            } else {
                //Basket request success
                reqNo = basketRes.value.data;
                basketRequestSuccess = true;
            }

            if (basketRequestSuccess) {
                logSubmittedRequestItems({
                    requestOrderRef: reqNo!,
                    userOid: userOid!,
                    clientId,
                    smartCard,
                    accountingLocation,
                    itemsInRequest: basketRequestPayload.current?.Items ?? [],
                    itemsOnCard,
                });
            }
            setStatusData({
                reqNo,
                basketRequestError,
                inventoryRequestError,
                updateInventoryPayload: updateInventoryPayload.current,
                itemsList: itemsOnCard,
                inRequest: basketRequestPayload.current?.Items,
                noPricing: itemsOnCard.filter(
                    (x) =>
                        !pricingQuery.data?.pricing
                            ?.map((y) => y.itemID)
                            .includes(x.itemID)
                ),
            });

            openStatusModal();
        });
    };

    return (
        <>
            <div className="items-table flex flex-col bg-gray-100">
                <div
                    className="min-w-full divide-y divide-gray-200"
                    role="table"
                >
                    {showHeader(userCols)}
                    <div className=" pb-4 sm:bg-white sm:pb-0" role="rowgroup">
                        {itemsOnCard?.map(showUserItem)}
                    </div>
                </div>
                <hr />
                <div className="my-1 flex flex-row items-center justify-end">
                    <button
                        type="button"
                        disabled={isCardUpdating}
                        className="btn-primary m-1"
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            </div>
            {modal}
        </>
    );
}
