import { Reducer, useReducer } from "react";
import { ApprovalChainType } from "./useApprovalChains";
import { useClientUsers } from "./useClientUsers";
import { ApprovalChainPubsub, EVENT_APPROVAL_CHAIN_UPDATED } from "../Services/CustomEvents";

type ApprovalChainTableData = Array<ApprovalChainType>

export enum ActionTypes {
    updateField = 'update-field',
    reset = 'reset',
    add = 'add',
    delete = 'delete',
}

interface ApprovalChainActions {
    type: string,
    payload: Array<ApprovalChainType>|ApprovalChainType
}

interface ActionReset {
    type: ActionTypes.reset,
    data: Array<ApprovalChainType>
}

interface ActionUpdateField {
    type: ActionTypes.updateField,
    rowIndex: number,
    columnId: string,
    value: string|number|boolean
}

interface ActionAdd {
    type: ActionTypes.add,
    newRow: ApprovalChainType
}

interface ActionDelete {
    type: ActionTypes.delete,
    rowIndex: number
}

type Action = ActionReset | ActionUpdateField | ActionAdd| ActionDelete

export function useApprovalChainsTableData() {
    const { data: clientUsers } = useClientUsers()

    function buildNewRow(row: ApprovalChainType, action: ActionUpdateField) {
        const { rowIndex, columnId, value } = action
        let newRowData = {...row, [columnId]:value}
        if (['level','rank','limit'].includes(columnId)) {
            newRowData = {...row, [columnId]:parseInt(value as string)}
        }
        if (columnId === 'userId') {
            const user = clientUsers?.find(x => x.id.toString() === value)
            const email = user?.emailAddress.slice() || 'unknown'
            const username = user?.name.slice() || 'unknown'
            newRowData = {...newRowData, email: email, username: username}
        }
        return newRowData
    }

    const reducer : Reducer<ApprovalChainTableData, Action> = (state, action): ApprovalChainTableData => {
        switch (action.type) {
            case ActionTypes.updateField: 
                const {rowIndex, columnId, value} = action
                return state.map((row, index) => {
                    if (index !== rowIndex) return row;
                    let newRowData = buildNewRow(row, action)
                    ApprovalChainPubsub.publish(EVENT_APPROVAL_CHAIN_UPDATED, newRowData)
                    return newRowData
                })
            case ActionTypes.reset: 
                return [...action.data]
            case ActionTypes.add: 
                return [action.newRow, ...state]
            case ActionTypes.delete: 
                return [...state.slice(0, action.rowIndex), ...state.slice(action.rowIndex+1)]
            default:
                return state
        }
    }

    return useReducer(reducer, [])
}

