/**
* ProductSearchList
*
* @component
* @remarks
* Attempting a more generic version of ItemSearchList
* The idea is to allow users to search for and select products. The selected products will be returned to caller.*
*/

import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useItemSearch } from "../hooks/useItemSearch"
import { Item, NoOp } from "../types/Item"
import { EllipsisSpinner } from "./EllipsisSpinner/EllipsisSpinner"
import { SearchItemCard } from "./SearchItemCard"

interface ProductSearchListProps {
    /**
    * Caller action when an item is selected
    */
    onItemSelected?: (term: Item) => void,
    /**
     * Allow parents to change a state item (a search term) to see different search results
     */
    onMount?: (setSearchTerm: React.Dispatch<React.SetStateAction<string>>) => void
}

const addToList = (item: Item, onItemSelected: (term: Item) => void) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onItemSelected(item)
    toast.info('Product selected', { autoClose: 1000 })
}

export function ProductSearchList({ onItemSelected = NoOp, onMount = NoOp }: ProductSearchListProps) {
    const [localSearchTerm, setLocalSearchTerm] = useState('')
    const { isLoading, data, error, isFetching } = useItemSearch(localSearchTerm)

    onMount(setLocalSearchTerm)

    if (localSearchTerm && isLoading) {
        return <EllipsisSpinner />
    }

    return (
        <div className="min-w-full shadow-lg flex flex-col" role="table">
            {/* Heading */}
            <div className="text-xs font-medium text-left uppercase bg-sky-500 text-white 
                hidden sm:grid sm:grid-cols-5" role="rowgroup">
                <div className="py-3 hidden sm:block" role="columnheader">
                </div>
                <div className="py-3" role="columnheader">
                    Item Name
                </div>
                <div className="py-3 flex flex-row justify-center" role="columnheader">
                    Quantity
                </div>
                <div className="py-3 flex flex-row justify-center" role="columnheader">
                    Manufacturer
                </div>
                <div className="py-3 flex flex-row justify-center" role="columnheader">
                    Add to cart
                </div>
            </div>
            <div className="bg-white" role="rowgroup">
                {data?.map((item) => {
                    return (
                        <SearchItemCard key={item.itemID} item={item} buttonClicked={addToList(item, onItemSelected)} />
                    )
                })}
            </div>
        </div>
    )
}