import { getImageUrl, Item, QtyChangeHandler } from "../types/Item";
import { NumberSpin2 } from "./NumberSpin2";
import imgUrl from '../images/placeholder.jpg'
import { stockLevelStyles } from "../inventory";
import { EmptyGuid } from "../settings";
import { LocationsSelect } from "./LocationsSelect";
import { useLocationData } from "../hooks/useLocationData";
import { useClientStore } from "../hooks/clientStore";
import { ChangeEvent } from "react";
import { AccountingLocation, getSelectPickLocations } from "../utils/location";
import { ItemInventoryDto } from "../dtos";
import { toTitleCase } from "../utils/string-utils";
import { useImagePrefix } from "../hooks/useImagePrefix";


interface UserItemCardProps {
    data: Item,
    quantityChanged: QtyChangeHandler,
    pickLocationChanged: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void,
    accountingLocation: AccountingLocation | null,
    inventory: Array<ItemInventoryDto>
}

/**
 * Normal non admin user view of a smart card.
 * A normal user should never see a card without an assigned location as there would be no pick locations to pick.
 * Additionally quantities and pick locations made by a non admin user will not be saved back to the smart card. They
 * will only be save in an audit table every time the user select the submit button.
 */
export function UserItemCard({ data, quantityChanged, pickLocationChanged, accountingLocation, inventory }: UserItemCardProps) {
    const clientId = useClientStore(s => s.clientId)
    const { data: clientLocations } = useLocationData(clientId)
    const { data: imagePrefix } = useImagePrefix(clientId)

    return (
        <div role="row" key={data.itemID} aria-rowindex={data.itemID}
            className={`bg-sky-200 sm:bg-white text-sm font-normal text-gray-600 pb-5 sm:pb-0 mb-3 sm:mb-0 rounded-lg sm:rounded-none m-2 sm:m-0 
            flex flex-col sm:grid sm:grid-cols-6 sm:gap-x-2 ${stockLevelStyles(data, inventory)} shadow-lg sm:shadow-none row-hover odd:bg-white even:bg-gray-100`}>
            <div className="py-4 sm:py-0 px-6 sm:px-0 sm:m-1 hidden sm:flex sm:justify-center">
                <img className="img-24" src={getImageUrl(data.image, imagePrefix)}/>
            </div>
            <div role="cell" className="py-4 sm:py-0 px-6 sm:px-0 text-xl sm:text-sm font-large sm:flex-auto flex sm:self-center">
                <div className="w-full text-center sm:text-start mt-1">
                    <div>{toTitleCase(data.name)}</div>
                    <div className="text-red-600">{data.code}</div>
                </div>
            </div>
            <div role="cell" className="py-4 sm:py-1 px-6 sm:px-0 whitespace-nowrap sm:flex-auto h-30 sm:h-fit flex flex-row justify-center sm:justify-start items-center sm:self-center">
                <div className="text-5xl sm:text-sm w-1/2 sm:w-16 h-32 sm:h-fit ">
                    <NumberSpin2
                        className="border-sky-300 sm:border-gray-300 shadow-md sm:shadow-none"
                        name='quantity'
                        start={data.pickQty}
                        min={0}
                        display='vertical'
                        onChange={quantityChanged} />
                </div>
                <span className="ml-1">{data.uomName}</span>
            </div>
            <div role="cell" className="hidden sm:block pt-4 sm:pt-0 px-6 sm:px-0 text-sm sm:self-center">
                <div className="line-heading">On hand quantity</div>
                <div className="line-text">
                    {data.lastcheckonhandqty}
                </div>
            </div>

            <div role="cell" className="px-6 sm:px-0 sm:flex-auto sm:self-center">
                <div className="line-heading">Pick location</div>
                <div className="sm:pt-1">
                    <LocationsSelect name={`pickLocation${data.itemID}`}
                        data={getSelectPickLocations(clientLocations ?? [], accountingLocation?.salesAccountUid) ?? []}
                        className="w-full sm:w-10/12 sm:h-fit"
                        currentSelection={data.pickLocationID === EmptyGuid ? '' : data.pickLocationID}
                        onChange={pickLocationChanged}
                    />
                </div>
            </div>
            <div role="cell" className="hidden sm:block px-6 sm:px-0 sm:flex-auto sm:self-center">
                <div className="line-heading">Manufacturer</div>
                <div className="line-text">
                    {toTitleCase(data.manufacturer)}
                </div>
            </div>
        </div>
    )
}