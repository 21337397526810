import { EnumUserRoles } from './../authorization';
import { compareByPropFactory } from "../utils/array-utils"
import imgUrl from '../images/placeholder.jpg'

export type GuidID = string

export interface SmartCardUser {
    userId: GuidID,
    tenantId: GuidID,
    clientId: GuidID,
    clientName: string,
    oid: GuidID,
    displayName: string,
    email: string,
    username: string,
    roleName: EnumUserRoles,
}

export interface Item {
    itemID: number,
    name: string,
    code: string,
    image: string,
    supplier: string,
    manufacturer: string,
    uomName: string,
    pickQty: number,
    minonhandqty: number,
    maxonhandqty: number,
    lastcheckonhandqty: number,
    pickLocationID: string,
}

export interface ItemsDto {
    data: Item[]
}

export interface IProcedureCard {
    name: string,
    procedureCardID: string,
    clientName: string,
    procedureType: string,
    createdDate: string,
    locationCode: string,
    locationName: string,
    locationTypeId: string,
    locationAccountID: string,
}

export interface SmartCardDeleteDto {
    smartCardID: string,
    clientId: string,
}

export interface ProcedureCardDto extends IProcedureCard {
    items: Item[],
}

export type QtyChangeHandler = (quantity: number | undefined) => void
export type ButtonClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => void
export const NoOp = () => { }

export type Basket = Map<number, Item>
export interface ClientLocation {
    salesAccountUid: string,
    salesAccountCode: string
    billToAddressUid: string
    billToAddressCode: string
    shipToAddressUid: string
    shipToAddressCode: string

    clientID: string;
    locationCode: string;
    locationName: string;
    locationID: string,
    locationType: string,
}

export enum EnumLocations {
    AccountingLocation = 'N',
    PickLocation = 'L',
    ShipLocation = 'S'
}

export interface ItemPricing {
    itemID: number,
    itemCode: string,
    itemName: string,
    supplierName: string,
    unitPrice: number,
    packQty: number,
    minOrderQty: number,
    maxOrderQty: number,
    currency: string,
    contractCode: string,
    status: string,
    taxGroup: string,
    taxGroupName: string,
    taxRate: number,
    contractStart: Date,
    contractEnd: Date,
    salesAccountUid: GuidID,
    uomCode: string,
    normalUnitPrice: number,
    pricePerUnit: number,
    savings: number,
}

export interface IdName {
    id: string|null,
    name: string|null
}

export const sortByName = (a: { name: string }, b: { name: string }) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
}

const compareStrings = (a: string, b: string) => {
    const nameA = a.toUpperCase();
    const nameB = b.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
}

export const sortByLocationName = compareByPropFactory<ClientLocation>('locationName')
export function compareUsers(a: SmartCardUser, b: SmartCardUser) {
    return compareStrings(a.displayName, b.displayName)
}
export function getImageUrl(imageUrl: string, imageUrlPrefix: string|undefined) {
    return !imageUrl || !imageUrlPrefix ? imgUrl : imageUrlPrefix + imageUrl
}

import { z } from "zod"

const UpdateUserPortalDtoSchema = z.object({
  clientId: z.string().uuid(),
  oid: z.string().uuid(),
})

export type UpdateUserPortalDto = z.infer<typeof UpdateUserPortalDtoSchema>
