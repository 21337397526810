import { useIsAuthenticated } from "@azure/msal-react"
import { Outlet, RouteProps, useNavigate } from "react-router-dom"
import { useClientStore } from "../hooks/clientStore"
import { EllipsisSpinner } from "../components/EllipsisSpinner/EllipsisSpinner"
import { UnauthorizedPage } from "../components/UnauthorizedPage"
import { useLoginUser } from "../hooks/useLoginUser"
import { useEffect } from "react"
import { EmptyGuid } from "../settings"
import { EnumUserRoles } from "../authorization"

export function ProtectedRoute({
    roles,
    children,
}: RouteProps & { roles: Array<string> }) {
    const navigate = useNavigate()

    const setTenantId = useClientStore(state => state.setTenantId)
    const setClient = useClientStore(state => state.setClient)
    const setUserRole = useClientStore(state => state.setUserRole)
    const setUsername = useClientStore(state => state.setUsername)
    const setUserOid = useClientStore(state => state.setUserOid)

    const role = useClientStore((s) => s.userRole)
    const isAuthenticated = useIsAuthenticated()
    const { data: userDetail, isLoading } = useLoginUser()

    useEffect(() => {
        if (userDetail) {
            setTenantId(userDetail.tenantId)
            setClient(userDetail.clientId, userDetail.clientName)
            setUserRole(userDetail.roleName as EnumUserRoles)
            setUsername(userDetail.username)
            setUserOid(userDetail.oid)
            if (userDetail.clientId && userDetail.clientId === EmptyGuid) {
                navigate('/message', { state: 'Please request your software vendor to setup your client id.' })
            }
        }
        
    }, [userDetail])

    if (!isAuthenticated) {
        return <UnauthorizedPage />
    }

    if (isLoading) {
        return <EllipsisSpinner/>
    }

    if (!isLoading && !userDetail) {
        return <UnauthorizedPage />
    }
    
    if (
        !roles ||
        roles.length === 0 ||
        !roles.find((x) => x.toLowerCase() === userDetail?.roleName.toLowerCase())
    ) {
        return <UnauthorizedPage />
    }

    return <>{children ? children : <Outlet />}</>
}
