import { ItemInventoryDto } from './dtos/index';
import { Item } from "./types/Item";

export const enum StockLevels {
    Normal,
    OutOfStock,
    TooLittle,
    TooMuch
}
export const enum StockLevelStyles {
    Normal = 'border-none',
    OutOfStock = 'border-red-700 border-x-4',
    TooLittle = 'border-orange-400 border-x-4',
    TooMuch = 'border-orange-400 border-x-4',
}

export function checkStockLevels(item: Item, inventory: Array<ItemInventoryDto>) {
    const found = inventory.find(x => x.itemId === item.itemID)
    if (!found) return StockLevels.OutOfStock

    const qtyLeft = found?.lastCheckOnHandQty - item.pickQty

    if (qtyLeft <= 0) {
        return StockLevels.OutOfStock
    }

    if (qtyLeft <= found.minOnHandQty) {
        return StockLevels.TooLittle
    }

    return StockLevels.Normal
}

export function stockLevelStyles(item: Item, inventory: Array<ItemInventoryDto>, defaultStyles: string = StockLevelStyles.Normal) {
    switch (checkStockLevels(item, inventory)) {
        case StockLevels.OutOfStock:
            return StockLevelStyles.OutOfStock
        case StockLevels.TooLittle:
            return StockLevelStyles.TooLittle
        default:
            return defaultStyles
    }
}
