import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { z } from "zod"
import { useClientStore } from "../hooks/clientStore";
import { cardApi, getUserPortals } from "../Services/ProcdedureCardApi";
import { fiveMinutes, qkUserPortals } from "./queryKeys";
  
export const PortalSchema = z.object({
  clientId: z.string(),
  clientName: z.string(),
  environment: z.string()
})

const apiResponse = PortalSchema.array()

const fetchUserPortals = ({ queryKey }: QueryFunctionContext) => {
    const clientId = queryKey[1]
    const userOid = queryKey[2]
    return cardApi.post(`${getUserPortals}`, { clientId: clientId, oid: userOid }).then(response => apiResponse.parse(response.data))
}

export function usePortals() {
    const clientId = useClientStore(s => s.clientId)
    const userOid = useClientStore(s => s.userOid)
  return useQuery({ queryKey: [qkUserPortals, clientId, userOid], queryFn: fetchUserPortals, staleTime: fiveMinutes })
}