import axios from "axios";
import { cardApiBaseUrl, cardApiSubKey } from "../settings";

export const cardApi = axios.create({
    baseURL: cardApiBaseUrl,
    headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'apikey': cardApiSubKey,
    }
})

// azuresearch function app
export const itemApiUrl = 'Search'
export const getImageHost = 'GetImageHost?'
export const itemPricingApi = 'GetItemPricing?'

// procedurecard function app
export const cardApiGet = 'ProcedureCardGet?'
export const cardApiUpdate = 'ProcedureCardUpdate?'
export const cardApiCreate = 'ProcedureCardCreate?'
export const cardApiDelete = 'ProcedureCardDelete?'
export const cardApiList = 'ProcedureCardGetAll?'
export const cardApiLocations = 'GetAccountingLocations?'
export const cardApiInventory = 'GetItemInventory?'
export const cardApiUpdateInventory = 'UpdateInventoryItem?'
export const cardApiAddUser = 'AddUser'
export const cardApiGetUsers = 'GetUsers'
export const cardApiAddToBasket = 'AddToBasket'
export const cardApiInviteUser = 'UserInviteFunction'
export const logRequestItems = 'LogRequestItems'
export const getLoginUser = 'GetLoginUser'
export const getUserPortals = 'GetUserPortals'
export const updateUserPortal = 'UpdateUserPortal'
export const getCostCentres = 'GetCostCentres'
export const getApprovalChains = 'GetApprovalChains'
export const getApprovalTypes = 'GetApprovalTypes'
export const getClientUsers = 'getClientUsers'
export const updateApprovalChain = 'updateApprovalChain'
export const deleteApprovalChain = 'deleteApprovalChain'
export const addApprovalChain = 'addApprovalChain'
export const publishApprovalChains = 'publishApprovalChains'

// filesmanagement function app
export const getImportJobs = 'GetImportJobs'
export const getImportJobLogs = 'GetImportJobLogs'
export const getCurrentMappings = 'GetCurrentMappings'
export const updateCustomMapping = 'UpdateCustomMapping'
export const deleteCustomMapping = 'DeleteCustomMapping'
export const getDefaultMapping = 'GetMappableColumns'
export const getImportTypes = 'GetImportTypes'
export const getCustomMaps = 'GetCustomMaps'

// Import data approval processing
export const previewPrefix = 'https://catalog360.files.com/preview/'
