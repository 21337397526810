import { useLocation } from "react-router-dom";

export function NoClientPage() {
  const location = useLocation();
  return (
    <div className="flex items-center justify-center min-h-full bg-white sm:py-44">
      <div className="flex flex-col items-center">
        <div className="font-bold text-3xl xl:text-7xl lg:text-6xl md:text-5xl mt-10 text-center">
          {`${location.state}`}
        </div>
      </div>
    </div>
  )
}