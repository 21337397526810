import { ColumnFiltersState, FilterFn, Row, SortingState, createColumnHelper, flexRender, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { FormEvent, useEffect, useState } from "react";
import { makeCellSelect } from "./makeCellSelect";
import { LocationsSelect, SelectOption } from "./LocationsSelect";
import { PageTitle } from "./PageTitle";
import { useCostCentres } from "../hooks/useCostCentres";
import { useFormState } from "../hooks/useFormState";
import { FormInput2 } from "./FormInput2";
import { useClientStore } from "../hooks/clientStore";
import { ApprovalChainSchema, ApprovalChainType, useApprovalChains } from "../hooks/useApprovalChains";
import { useApprovalTypes } from "../hooks/useApprovalTypes";
import { ClientUserType, useClientUsers } from "../hooks/useClientUsers";
import { ApprovalChainPubsub, EVENT_APPROVAL_CHAIN_UPDATED } from "../Services/CustomEvents";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addApprovalChain, cardApi, deleteApprovalChain, updateApprovalChain } from "../Services/ProcdedureCardApi";
import { qkApprovalChains } from "../hooks/queryKeys";
import { makeCellTextbox, validateLimit } from "./makeCellTextbox";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ActionTypes, useApprovalChainsTableData } from "../hooks/useApprovalChainsTableData";
import { istrEqual } from "../utils/string-utils";
import { toast } from "react-toastify";
import { useConfirmModal } from "../hooks/useConfirmModal";
import { TableColumnFilter } from "./TableColumnFilter";

const levels: Array<SelectOption> = [{ id: "unknown", name: "Unknown" }, { id: "1", name: "1" }, { id: "2", name: "2" }, { id: "3", name: "3" }];

const columnHelper = createColumnHelper<ApprovalChainType>()

export function ApprovalChainsPage() {
    const queryClient = useQueryClient()
    const clientId = useClientStore(state => state.clientId)
    const clientName = useClientStore(state => state.clientName)
    const userOid = useClientStore(state => state.userOid)

    const [rowToDeleteIndex, setRowToDeleteIndex] = useState<number|undefined>(undefined)

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [sorting, setSorting] = useState<SortingState>([])
    const [rerenderForm, setRerenderForm] = useState(0)
    const [tableData, dispatch] = useApprovalChainsTableData()
    const [userOptions, setUserOptions] = useState<Array<SelectOption>>([])
    const { modal: confirmModal, open: openConfirmModal, close: closeConfirmModal } = useConfirmModal({message: 'Please confirm item removal', confirm: deleteItem})


    // Read apis

    const { data: clientUsers, isLoading: clientUsersIsLoading, isSuccess: clientUsersIsSuccess } = useClientUsers()
    const { data: approvalTypes, isLoading: approvalTypesIsLoading, isSuccess: approvalTypesIsSuccess } = useApprovalTypes()
    const { data: costCentres, isLoading: costCentresIsLoading, isSuccess: costCentresIsSuccess } = useCostCentres()
    const { data: srvApprovalChains, isLoading: acIsLoading, isSuccess: acIsSuccess } = useApprovalChains(clientUsers?true:false)

    // Update apis
    const updateChain = useMutation({
        mutationFn: (data: apiDto<ApprovalChainType>) => cardApi.post(`${updateApprovalChain}?clientId=${clientId}`, data),
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [qkApprovalChains, clientId] })
        },
        onError: (error, variables, context) => {
            if (error instanceof Error) {
            }
        },
    })

    interface apiDto<T> {
        clientId: string
        userOid: string,
        payload: T
    }

    // Update apis
    const addChain = useMutation({
        mutationFn: (data: apiDto<ApprovalChainType>) => cardApi.post(`${addApprovalChain}?clientId=${clientId}`, data),
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [qkApprovalChains, clientId] })
        },
        onError: (error, variables, context) => {
            if (error instanceof Error) {
            }
        },
    })

    const deleteChain = useMutation({
        mutationFn: (data: apiDto<ApprovalChainType>) => cardApi.post(`${deleteApprovalChain}?clientId=${clientId}`, data),
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [qkApprovalChains, clientId] })
        },
    })

    // Update table data from server data
    useEffect(() => {
        if (srvApprovalChains && clientUsers) {
            dispatch({ type: ActionTypes.reset, data: setUserIds(srvApprovalChains, clientUsers) })
        }
    }, [srvApprovalChains, clientUsers]);
    
    useEffect(() => {
        setUserOptions([{ id: 'unknown', name: 'Unknown' }, ...(clientUsers ?? []).map(x => ({ id: x.id.toString(), name: x.name + ' - ' + x.emailAddress }))])
    }, [clientUsers])
    
    useEffect(() => {
        const unsubscribeUpdates = ApprovalChainPubsub.subscribe(EVENT_APPROVAL_CHAIN_UPDATED, (eventData: ApprovalChainType) => {
            if (!ApprovalChainSchema.parse(eventData)) {

            }
            updateChain.mutate({clientId: clientId, userOid: userOid??'', payload: eventData})
        })
        return () => {
            unsubscribeUpdates()
        }
    }, [])

    function setUserIds(srvChains:Array<ApprovalChainType>, clientUsers: Array<ClientUserType>) {
        return srvChains.map((x, index) => {
            const knownUser = clientUsers.find(a => istrEqual(a.emailAddress, x.email) && istrEqual(a.name, x.username));
            const foundUserId = knownUser ? knownUser.id.toString() : 'unknown';
            return ({ ...x, userId: foundUserId });
        });
    }

    function deleteItem() {
        closeConfirmModal()
        if (rowToDeleteIndex!==undefined) {
            const rowToDelete = { ...tableData[rowToDeleteIndex] }
            dispatch({ type: ActionTypes.delete, rowIndex: rowToDeleteIndex })
            console.log("### Delete chain ###", clientId,userOid??'',rowToDelete)
            deleteChain.mutate({clientId: clientId, userOid: userOid??'', payload: rowToDelete}, {
                onSuccess: () => toast.info('Item deleted', { autoClose: 1000 }),
                onError: (data, variables, context) => {
                    toast.info(`Error encountered when attempting to delete item`)
                }
            })
            return
        }
        toast.info('Item not deleted due to missing id')
    }
    // UI table

    function confirmItemRemoval(row: Row<ApprovalChainType>) {
        setRowToDeleteIndex(row.index)
        openConfirmModal()
    }

    interface ChainActionsProps {
        rowData: Row<ApprovalChainType>
    }

    function ChainActions( {rowData}: ChainActionsProps ) {
        return (<>
            <div className="flex justify-center">
                <button type="button" onClick={e=>confirmItemRemoval(rowData)} >
                    <TrashIcon className="h-5 w-5 stroke-red-600 hover:stroke-sky-400" />
                </button>
            </div>
        </>)
    }

    const filterNumbers: FilterFn<ApprovalChainType> = (row, columnId, filterValue: number) => {
        const value = row.getValue(columnId) as Number;
        if (filterValue===Number.NEGATIVE_INFINITY) return true
        return Number(value)===filterValue
    };

    const columns = [
        columnHelper.accessor('costCentreName', {
            header: 'Cost centre',
            cell: info => <span className="text-gray-400">{info.getValue()}</span>,
            // enableColumnFilter: true,
            filterFn: 'equalsString',
        }),
        columnHelper.accessor('approvalType', {
            header: 'Approval Type',
            cell: info => <span className="text-gray-400">{info.getValue()}</span>,
        }),
        columnHelper.accessor('level', {
            header: 'Level',
            cell: info => <span className="text-gray-400">{info.getValue()}</span>,
            filterFn: filterNumbers
        }),
        columnHelper.accessor('rank', {
            header: 'Rank',
            cell: info => <span className="text-gray-400">{info.getValue()}</span>,
            filterFn: filterNumbers
        }),
        columnHelper.accessor('limit', {
            header: 'From value',
            cell: makeCellTextbox({}, validateLimit)<number>,
            meta: {
                required: true,
                pattern: '\\d+',
                title: 'From value must be integer',
            },
            filterFn: filterNumbers
        }),
        columnHelper.accessor('userId', {
            header: 'Username',
            cell: makeCellSelect(userOptions)<string>
        }),
        columnHelper.accessor('email', {
            header: 'Email',
            cell: info => <span className="text-gray-400">{info.getValue()}</span>,
        }),
        columnHelper.display({
            header: 'Actions',
            id: 'actions',
            cell: props => <ChainActions rowData={ props.row} />,
        }),
    ]

    const table = useReactTable({
        columns, data: tableData, debugTable: true,
        getCoreRowModel: getCoreRowModel(),
        enableColumnResizing: true,
        columnResizeMode: "onChange",
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(), 

        enableColumnFilters: true,
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        onColumnFiltersChange: setColumnFilters,

        state: {
            sorting,
            columnFilters
        },
        meta: {
            updateData: function <T>(rowIndex: number, columnId: string, value: T) {
                dispatch({ type: ActionTypes.updateField, rowIndex, columnId, value: value as number | string | boolean })
                toast.info('Row has been updated')
            }
        },
    });

    // Form
    type Nullable<T> = { [K in keyof T]: T[K] | null };
    type FormDataType = Nullable<Record<keyof Omit<ApprovalChainType, 'portal' | 'costCentreGuid' | 'userId' | 'email'>, string>>
    const emptyForm = {
        costCentreName: '',
        approvalType: '',
        limit: '',
        level: '',
        rank: '',
        username: '',
    }
    const { formRef, formValues, formIsValid, onChangeHandler, isModified, setIsModified, clearForm } = useFormState<FormDataType>(emptyForm)

    function hasNulls(x: FormDataType): boolean {
        for (let p in x) {
            if (x[p as keyof FormDataType] == '') return true
        }
        return false
    }

    function validateApprovalChain(chain: ApprovalChainType) {
        const found = tableData.find(x => istrEqual(x.costCentreGuid, chain.costCentreGuid)
            && istrEqual(x.approvalType, chain.approvalType)
            && x.level === chain.level
            && x.rank === chain.rank)
        
        if (found) {
            toast.warn('Approval chain already there!', { autoClose: 2000 })
            return false;
        }

        return true
    }

    const formSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!hasNulls(formValues)) {
            const userDetails = clientUsers?.find(x => x.id.toString() == formValues.username)
            const newData: ApprovalChainType = {
                costCentreGuid: formValues.costCentreName!,
                approvalType: formValues.approvalType!,
                limit: parseInt(formValues.limit ?? '0'),
                level: parseInt(formValues.level??'0'),
                rank: parseInt(formValues.rank??'0'),
                email: userDetails?.emailAddress ?? 'unknown',
                userId: formValues.username!,
                username: userDetails?.name??'unknown',
                portal: clientName,
                costCentreName: costCentres?.find((x: SelectOption) => x.id === formValues.costCentreName)?.name!
            }

            if (validateApprovalChain(newData)) {
                dispatch({ type: ActionTypes.add, newRow: newData })
                addChain.mutate({clientId: clientId, userOid: userOid??'', payload: newData})
            }
        }
    }

    function clearTheForm() {
        setRerenderForm(rerenderForm - 1)
        clearForm(emptyForm)
    }

    return (<>
        <PageTitle title="Manage approval chains" />
        <form ref={formRef} onSubmit={formSubmit} className="text-md font-normal text-gray-600 border border-gray-200 mx-2 my-2" key={rerenderForm}>
            <div className="flex flex-col md:flex-row pl-1 w-full ">
                <div className="flex flex-col items-center w-1/6 mr-1">
                    <label htmlFor="costCentreName" className="block font-medium text-sm">Cost centre</label>
                    <LocationsSelect name="costCentreName" required errorMessage="Cost centre is required."
                        className="w-full py-1.5 rounded"
                        data={costCentres?.filter(x=>x.id!=='unknown')??[]}
                        onChange={onChangeHandler} />
                </div>
                <div className="flex flex-col items-center w-1/12 mr-1">
                    <label htmlFor="approvalType" className="block font-medium text-sm">Approval types</label>
                    <LocationsSelect name="approvalType" required errorMessage="Approval type is required."
                        className="w-full py-1.5 rounded"
                        data={approvalTypes?.filter(x=>x.id!=='unknown')??[]}
                        onChange={onChangeHandler} />
                </div>
                <div className="flex flex-col items-center w-1/12 mr-1">
                    <label htmlFor="level" className="block font-medium text-sm">Level</label>
                    <LocationsSelect name="level" required errorMessage="Level is required."
                        className="w-full py-1.5 rounded"
                        data={levels.filter(x=>x.id!=='unknown')??[]}
                        onChange={onChangeHandler} />
                </div>
                <div className="flex flex-col items-center w-1/12 mr-1">
                    <label htmlFor="rank" className="block font-medium text-sm">Rank</label>
                    <LocationsSelect name="rank" required errorMessage="Rank is required."
                        className="w-full py-1.5 rounded"
                        data={levels.filter(x=>x.id!=='unknown')??[]}
                        onChange={onChangeHandler} />
                </div>
                <div className="flex flex-col items-center w-1/6 mr-1">
                    <label htmlFor="username" className="block font-medium text-sm">User name</label>
                    <LocationsSelect name="username" required errorMessage="Username is required."
                        className="w-full py-1.5 rounded"
                        data={userOptions.filter(x=>x.id!=='unknown')??[]}
                        onChange={onChangeHandler} />
                </div>
                <div className="flex flex-col items-center w-1/6 mr-1">
                    <label htmlFor="limit" className="block font-medium text-sm">Limit</label>
                    <FormInput2 className="w-full h-9 rounded border border-gray-300"
                        required type="number" min="0" label="" name="limit" errorMessage="Limit is required." onChange={onChangeHandler} />
                </div>
            </div>
            <div className="flex flex-row justify-start items-center my-1">
                <button type="submit" className="btn-primary m-1" disabled={!isModified || !formIsValid()}>Add</button>
                <button type="button" className="btn-danger m-1" onClick={clearTheForm}>Clear</button>
            </div>
        </form>
        <div className="px-2 mt-1">
            <table>
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id} className="border bg-sky-500 text-white">
                            {headerGroup.headers.map(header => (
                                <th key={header.id}
                                    className={`border-l p-2 relative ${header.column.getCanSort()
                                    ? 'cursor-pointer select-none'
                                    : ''}`} style={{ width: `${header.getSize()}px` }}
                                    onClick={header.column.getToggleSortingHandler()}
                                    title={
                                        header.column.getCanSort()
                                            ? header.column.getNextSortingOrder() === 'asc'
                                                ? 'Sort ascending'
                                                : header.column.getNextSortingOrder() === 'desc'
                                                    ? 'Sort descending'
                                                    : 'Clear sort'
                                            : undefined
                                    }>
                                    <div className="flex flex-row justify-between">
                                        <div>{flexRender(header.column.columnDef.header, header.getContext())}
                                        </div>
                                        <div>
                                            {{
                                                asc: ' 🔼',
                                                desc: ' 🔽',
                                            }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                        <div onMouseDown={header.getResizeHandler()} onTouchStart={header.getResizeHandler()} className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}></div>
                                    </div>
                                    {header.column.getCanFilter() ? (
                                        header.column.id=='userId'?
                                        <TableColumnFilter column={header.column} table={table} selectOptions={userOptions}/>
                                        :<TableColumnFilter column={header.column} table={table} />
                                    ) : null}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id} className="odd:bg-gray-100">
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id} className="p-2">
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        {confirmModal}
    </>)
}