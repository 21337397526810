import { Outlet } from "react-router-dom";
import { SideBar } from "../SideBar";

export function TopLayout() {
    return (
        <div id="toplayout" className="flex flex-row items-stretch">
            <SideBar></SideBar>
            <div id="content" className="grow">
                <Outlet />
            </div>
        </div>
    )
}