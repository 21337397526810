import { GuidID } from "../types/Item"

export const HOME_PAGE = '/'
export const SEARCH_PAGE = '/new'
export const BASKET_PAGE = '/basket'
export const ASSIGN_PAGE ='/admin/assign'
export const ADD_USER_PAGE ='/admin/invite-user'
export const LIST_PAGE ='/pc/list'
export const CARD_PAGE ='/pc/:pcId'
export const CARD_ASSIGNMENT_PAGE ='/pc/:pcId/locations'
export const ASSIGNED_CARD_PAGE ='/pc/:pcId/locations/:locId/admin'
export const QR_PAGE ='/pc/:pcId/locations/:locId/qr'
export const MESSAGE_PAGE ='/message'
export const DL_NEWIMPORT = '/dataloader/newimport'
export const DL_VIEWIMPORTS = '/dataloader/importjobs'
export const DL_MAPPINGS = '/dataloader/mappings'
export const USERS ='/users'
export const SELECT_PORTAL ='/selectportal'
export const APPROVAL_CHAINS ='/admin/approvalchains'

export function mkCardLocationsRoute(pcId:GuidID) {
    return CARD_ASSIGNMENT_PAGE.replace(':pcId', pcId)
}