

import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { z } from "zod"
import { cardApi, getLoginUser } from "../Services/ProcdedureCardApi"

const LoginUserSchema = z.object({
    userId: z.string().uuid(),
    tenantId: z.string().uuid(),
    clientId: z.string().uuid(),
    clientName: z.string(),
    oid: z.string(),
    displayName: z.string().nullable(),
    email: z.string().nullable(),
    username: z.string(), // name is not capitalised!
    roleName: z.string()
}).nullable();

export type LoginUserType = z.infer<typeof LoginUserSchema>

const fetchLoginUser = ({ queryKey }: QueryFunctionContext): Promise<LoginUserType> => {
    const oidClaim = queryKey[1]
    if (!oidClaim) {
        return new Promise(resolve => undefined)
    }
    return cardApi.get(`${getLoginUser}?oidClaim=${oidClaim}`).then(response => {
        if (response.status === 204) {
            return null
        }
        return LoginUserSchema.parse(response.data)
    })
}

export function useLoginUser() {
    const { instance } = useMsal()
    const isAuthenticated = useIsAuthenticated();

    const account = instance.getActiveAccount()
    let oidClaim: string | undefined
    if (account && account.idTokenClaims) {
        oidClaim = account.idTokenClaims['oid']
    }
    return useQuery({ queryKey: ['loginUser', oidClaim], queryFn: fetchLoginUser, enabled: !!isAuthenticated })
}