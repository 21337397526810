import { GuidID } from './../types/Item';
import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { useClientStore } from "../hooks/clientStore"
import { cardApi, itemPricingApi } from '../Services/ProcdedureCardApi';
import { ItemPricingDto } from '../dtos';

const fetchPricing = ({ queryKey }: QueryFunctionContext): Promise<ItemPricingDto|null> => {
    const clientId = queryKey[1]
    const salesAccountId = queryKey[2]
    const itemIds = queryKey[3]
    if (!itemIds || itemIds === ''||!salesAccountId) {
        return Promise.reject<ItemPricingDto>()
    }
    return cardApi.get(`${itemPricingApi}clientId=${clientId}&salesaccountId=${salesAccountId}&itemIds=${itemIds}`).then(response => response.data)
}

export function useItemPricing(salesAccountId: GuidID|undefined, itemIds: string) {
    const clientId = useClientStore(s => s.clientId)

    return useQuery({ queryKey: ['pricing', clientId, salesAccountId, itemIds], queryFn: fetchPricing })
}