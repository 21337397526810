import { ButtonClickHandler, getImageUrl, Item, NoOp, QtyChangeHandler } from "../types/Item";
import imgUrl from '../images/placeholder.jpg'
import RoundPlusIcon from "../icons/RoundPlusIcon";
import { NumberSpin2 } from "./NumberSpin2";
import { useImagePrefix } from "../hooks/useImagePrefix";
import { useClientStore } from "../hooks/clientStore";

interface SearchItemCardProps {
    item: Item,
    quantityChanged?: QtyChangeHandler,
    buttonClicked: ButtonClickHandler,
}

export function SearchItemCard({ item, quantityChanged = NoOp, buttonClicked = NoOp }: SearchItemCardProps) {
    const clientId = useClientStore(s => s.clientId)
    const { data: imagePrefix } = useImagePrefix(clientId)

    const quantityChangedLocal = (quantity: number | undefined) => {
        if (quantity !== undefined) {
            item.pickQty = quantity
            quantityChanged(quantity)
        }
    }

    return (
        <>
            <div key={item.itemID} className="font-normal text-gray-600 pb-5 sm:pb-0 mb-3 sm:mb-0 
                rounded-lg sm:rounded-none m-2 sm:m-0 
                flex flex-col sm:grid sm:grid-cols-5 
                bg-sky-200 sm:bg-white sm:hover:bg-gray-200 shadow-lg sm:shadow-none odd:bg-white even:bg-gray-100">
                <div className="py-4 sm:py-0 px-6 sm:px-0 sm:m-1 hidden sm:flex sm:justify-center">
                    <img className="img-24" src={getImageUrl(item.image, imagePrefix)} />
                </div>
                <div className="py-4 sm:py-0 px-6 sm:px-0 text-xl sm:text-sm font-large sm:font-normal sm:self-center">
                    <div className="w-full text-center sm:text-start">
                        <div className="break-all">{item.name}</div>
                        <div className="text-red-600">{item.code}</div>
                    </div>
                </div>
                <div className="py-4 sm:py-1 px-6 sm:px-0  text-gray-600 whitespace-nowrap 
                    h-30 sm:h-fit flex flex-row justify-center items-center sm:self-center">
                    <div className="text-5xl sm:text-sm w-1/2 sm:w-16 h-32 sm:h-fit">
                        <NumberSpin2
                            className="h-full border border-sky-300 sm:border-gray-300 shadow-md sm:shadow-none"
                            name='quantity'
                            start={item.pickQty}
                            min={0}
                            onChange={quantityChangedLocal} />
                    </div>
                    <span className="ml-1 lowercase">{item.uomName}</span>
                </div>
                <div className="hidden sm:block px-6 sm:px-0 text-sm sm:self-center">
                    <div className="line-heading">Manufacturer</div>
                    <div className="line-text">
                        {item.manufacturer}
                    </div>
                </div>
                <div className="py-4 px-6 flex justify-center sm:self-center">
                    <button type="button" onClick={buttonClicked} className="">
                        <RoundPlusIcon className="w-16 sm:w-6 hover:fill-amber-600 fill-white" />
                    </button>
                </div>
            </div>
        </>
    )
}