import { useMsal } from "@azure/msal-react"
import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { GetUsersDto } from "../dtos"
import { cardApi, cardApiGetUsers } from "../Services/ProcdedureCardApi"
import { SmartCardUser } from "../types/Item"
import { fiveMinutes } from "./queryKeys"

const fetchUsers = ({ queryKey }: QueryFunctionContext): Promise<Array<SmartCardUser>> => {
    const adminOid = queryKey[1]
    if (!adminOid) {
        return new Promise(resolve => [])
    }
    return cardApi.post(`${cardApiGetUsers}`, { adminOid: adminOid }).then(response => response.data)
}

export function useUsers() {
    const { instance } = useMsal()
    const account = instance.getActiveAccount()
    let adminOid: string | undefined
    if (account && account.idTokenClaims) {
        adminOid = account.idTokenClaims['oid']
    }
    return useQuery({ queryKey: ['users', adminOid], queryFn: fetchUsers, staleTime: fiveMinutes })
}