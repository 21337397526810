/**
 * Status to display in a dialog when submiting a request to Catalog360.
 * List items where no inventory was available
 * List items where no pricing was available
 * List items sent in request if one was sent (due to inventory triggers)
 * @returns 
 */

import { Fragment } from "react"
import { InventoryItemUpdateDto, ItemRequestLineDto } from "../dtos"
import { MSG_NO_PRICING } from "../messages"
import { Item } from "../types/Item"

export interface RequestStatusProps {
    basketRequestError: string | undefined,
    inventoryRequestError: string | undefined,
    itemsList: Array<Item>,
    updateInventoryPayload: InventoryItemUpdateDto | undefined,
    reqNo: string | undefined,
    inRequest: Array<ItemRequestLineDto> | undefined,
    noPricing: Array<Omit<Item, 'code'>>
}

export function RequestStatus({ basketRequestError, inventoryRequestError, itemsList, updateInventoryPayload, reqNo = 'unknown', inRequest = [], noPricing = [] }: RequestStatusProps) {
    const inventoryUpdates = updateInventoryPayload?.items?.map(x => {
        const found = itemsList.find(y => y.itemID === x.itemId)
        return {
            name: found?.name,
            itemID: x.itemId,
            pickQty: found?.pickQty,
            supplier: found?.supplier,
        }
    })

    function bgStyle(value: number) {
        if (value % 2 === 0) {
            return 'bg-white'
        }
        return 'bg-slate-200'
    }

    function nameStyle(value: number) {
        return `border-l border-b border-gray-200 ${bgStyle(value)}`
    }

    function supplierStyle(value: number) {
        return `border-l border-b border-r border-gray-200 ${bgStyle(value)}`
    }

    function qtyStyle(value: number) {
        return `border-l border-b border-gray-200 text-center ${bgStyle(value)}`
    }

    return (<>
        <h1 className="font-semibold text-center text-2xl">Request Status Report</h1>
        {inventoryRequestError &&
            <h2 className="font-semibold text-center mt-4 text-red-500">{`Ineventory Request has failed with reason ${inventoryRequestError}`}</h2>
        }
        {inventoryUpdates && inventoryUpdates.length > 0 &&
            <>
                {
                    inventoryRequestError
                        ? <h2 className="font-semibold text-center mt-4 text-red-500">Error updating inventory for the following items.</h2>
                        : <h2 className="font-semibold text-center mt-4">Inventory for the following items were updated.</h2>
                }
                <div className="grid grid-cols-1 sm:grid-cols-[1fr_min-content_1fr] print:grid-cols-7 font-light odd:bg-white even:bg-slate-200">
                    <div className="print:col-span-2 border-l border-b border-gray-200  bg-hte-dark-blue text-slate-100 pl-1">Name</div>
                    <div className="print:col-span-1 border-l border-b border-gray-200  bg-hte-dark-blue text-slate-100 px-1 sm:text-center">Quantity</div>
                    <div className="print:col-span-4 border-l border-b border-gray-200  bg-hte-dark-blue text-slate-100 pl-1"> Supplier</div>
                    {inventoryUpdates.map((item, idx) => (
                        <Fragment key={item.itemID}>
                            <div className={nameStyle(idx)}>{item.name}</div>
                            <div className={qtyStyle(idx)}>{item.pickQty}</div>
                            <div className={supplierStyle(idx)}>{item.supplier}</div>
                        </Fragment>
                    ))}
                </div>
            </>
        }
        {
            basketRequestError && basketRequestError!==MSG_NO_PRICING && 
            <h2 className="font-semibold text-center mt-4 text-red-500">{`Basket Request has failed with reason ${basketRequestError}`}</h2>
        }
        {
            inRequest?.length > 0 && <>
                {
                    basketRequestError
                        ? <h2 className="font-semibold text-center mt-4 text-red-500">The failed basket request was submitted with the following items/quantities</h2>
                        : <h2 className="font-semibold text-center mt-4">Request no. {reqNo} was submitted successfully for the following items/quantities</h2>
                }

                <div className="grid grid-cols-1 sm:grid-cols-[1fr_min-content_min-content_1fr] print:grid-cols-7 font-light">
                    <div className="print:col-span-2 border-l border-b border-gray-200  bg-hte-dark-blue text-slate-100 pl-1">Name</div>
                    <div className="print:col-span-1 border-l border-b border-gray-200  bg-hte-dark-blue text-slate-100 px-1 sm:text-center">Code</div>
                    <div className="print:col-span-1 border-l border-b border-gray-200  bg-hte-dark-blue text-slate-100 px-1 sm:text-center">Quantity</div>
                    <div className="print:col-span-4 border-l border-b border-gray-200  bg-hte-dark-blue text-slate-100 pl-1"> Supplier</div>
                    {inRequest.map((item, idx) => (
                        <Fragment key={item.Itemid}>
                            <div className={nameStyle(idx)}>{item.Sdesc}</div>
                            <div className={qtyStyle(idx)}>{item.Code}</div>
                            <div className={qtyStyle(idx)}>{item.Unitquantity}</div>
                            <div className={supplierStyle(idx)}>{item.Supplier}</div>
                        </Fragment>
                    ))}
                </div>
            </>
        }
        {
            noPricing?.length > 0 && <>
                <h2 className="font-semibold text-red-500 text-center mt-4">Pricing data not found for the following items</h2>
                <div className="grid grid-cols-1 sm:grid-cols-[1fr_min-content_1fr] print:grid-cols-7 font-light">
                    <div className="print:col-span-2 border-l border-b border-gray-200  bg-hte-dark-blue text-slate-100 pl-1">Name</div>
                    <div className="print:col-span-1 border-l border-b border-gray-200  bg-hte-dark-blue text-slate-100 px-1 sm:text-center">Quantity</div>
                    <div className="print:col-span-4 border-l border-b border-gray-200  bg-hte-dark-blue text-slate-100 pl-1"> Manufacturer</div>
                    {noPricing.map((item, idx) => (
                        <Fragment key={item.itemID}>
                            <div className={nameStyle(idx)}>{item.name}</div>
                            <div className={qtyStyle(idx)}>{item.pickQty}</div>
                            <div className={supplierStyle(idx)}>{item.manufacturer}</div>
                        </Fragment>
                    ))}
                </div>
            </>
        }
    </>)
}