import { useContext } from "react"
import { Link } from "react-router-dom"
import { BasketContext } from "../App"
import BasketIcon from "../images/BasketIcon"
import OutlineCartIcon from "../images/OutlineCartIcon"
import SolidCartIcon from "../images/SolidCartIcon"

interface BasketButtonProps {
    outline?: boolean,
    icon?: string,
}

export function BasketButton({ outline = false, icon = 'basket' }: BasketButtonProps) {
    const basketContext = useContext(BasketContext)

    const chooseIcon = (icon: string) => {
        switch (icon) {
            case 'solid': return <SolidCartIcon className="w-10 h-10" />
            case 'outline': return <OutlineCartIcon className="w-10 h-10" />
            default: return <BasketIcon className="w-10 h-10" />
        }
    }

    return (
        <div className="w-12 lg:inline-block ml-1 sm:ml-0 mt-1 relative">
            <Link to='/basket' role="button">
                {chooseIcon(icon)}
                <span className="absolute right-0 top-0 rounded-full bg-red-600 w-4 h-4 p-0 m-0 text-white font-mono text-sm  leading-tight text-center">{basketContext?.basket.size}
                </span>
            </Link>
        </div>
    )
}