/**
 * SearchBox
 * @component
 * @remark
 * @return
 */
import { ChangeEvent, FormEvent, MutableRefObject, useRef } from "react"
import { NoOp } from "../types/Item";

interface SearchBoxProps {
    /**
     * Input function to call when the search box is updated
     */
    onUpdated?: (term: string) => void,
}

export function SearchBox({ onUpdated = NoOp }: SearchBoxProps) {
    const searchTermRef = useRef() as MutableRefObject<HTMLInputElement>;

    const submitForm = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onUpdated(searchTermRef.current.value)
    }

    return (
        <form className="m-3" onSubmit={submitForm}>
            <div className="flex justify-center">
                <div className="relative rounded-full flex items-center w-full sm:w-1/2">
                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </div>
                    <label htmlFor="searchTerm" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Search</label>
                    <input type="search" id="searchTerm"
                        name="searchTerm"
                        ref={searchTermRef}
                        className="block p-4 pl-10 w-full text-md text-gray-600 bg-gray-50 rounded-full border border-gray-300 focus:border-blue-500 outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search products to add to basket and create a new smart card"
                        required />
                    <button type="submit"
                        className="text-white absolute right-2.5 bottom-3 bg-sky-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                </div>
            </div>
        </form>
    )
}