export function compareByPropFactory<T>(p: keyof T) : (a: T, b: T) => 0|-1|1 {
    return (a: T, b: T) => {
        const nameA = (a[p] as unknown as string).toUpperCase();
        const nameB = (b[p] as unknown as string).toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }
}