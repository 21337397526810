import { BuildingOffice2Icon, EyeIcon, QrCodeIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ProcedureCardListDto } from "../dtos";
import { useLocationData } from "../hooks/useLocationData";
import { useProcedureCardListData } from "../hooks/useProcedureCardListData";
import { EmptyGuid } from "../settings";
import { ClientLocation, sortByLocationName } from "../types/Item";
import { getAccountingLocations, getAssignedLocations, sortCardsByLocationName } from "../utils/location";
import { EllipsisSpinner } from "./EllipsisSpinner/EllipsisSpinner";
import { PageTitle } from "./PageTitle";
import { useClientStore } from "../hooks/clientStore";

export function AssignmentPage() {
    let params = useParams();
    const clientId = useClientStore(s => s.clientId)
    const { data: clientLocations, isLoading } = useLocationData(clientId)
    const listQuery = useProcedureCardListData(clientId)
    const navigate = useNavigate()

    const accountingLocations = useMemo(() => {
        return getAccountingLocations(clientLocations)
    }, [clientLocations])

    const { assigned: assignedLocations, notAssigned: availableLocations } = useMemo(() => {
        let assigned: Array<ProcedureCardListDto> = [];
        if (listQuery.data) {
            assigned = listQuery.data.filter(x => x.id === params.pcId && x.accountingLocationId !== EmptyGuid)
                .sort(sortCardsByLocationName)
        }

        const notAssigned = accountingLocations.filter(x => !assigned.some(y => y.accountingLocationId === x.locationID)).sort(sortByLocationName)

        return { assigned, notAssigned }
    }, [listQuery.data, accountingLocations])

    /**
     * Display the accounting locations already assigned to this card
     * @param locations Assigned locations
     * @returns JSX.Element - Table of assigned locations
     */
    function displayAssignedLocations(locations: Array<ProcedureCardListDto>) {
        if (locations.length === 0) {
            return (
                <h3 className="text-2xl text-center">No accounting locations have been assigned for this smart card.</h3>
            )
        }
        return (
            locations.map(x =>
                <div key={`${x.id}+${x.accountingLocationId}`} className="flex flex-col items-center sm:grid gap-1 text-xl mb-1 sm:grid-cols-12">
                    <div className="flex flex-col items-center sm:grid sm:grid-cols-[min-content_1fr] sm:col-span-9 ">
                        <Link to={`/pc/${x.id}/locations/${x.accountingLocationId}/qr?name=${x.name}`}
                            className="my-1 pl-1 pr-1 text-center">
                            <QrCodeIcon className="h-7 w-7 hover:bg-slate-300 hover:rounded-md" />
                        </Link>
                        <div className="sm:col-start-2 sm:col-span-8">{x.accountingLocationName}</div>
                    </div>
                    <div className="sm:col-start-11 sm:col-span-2 flex justify-end">
                        <Link to={`/pc/${x.id}/locations/${x.accountingLocationId}/admin`} type="button" className="w-28 rounded-md bg-blue-400 hover:bg-blue-500 text-white border border-gray-300 text-base flex items-center justify-center px-2">
                            <EyeIcon className="w-5 h-5 mr-2" />
                            <div className="flex items-center pb-1 pr-1">Edit</div>
                        </Link>
                    </div>
                </div>
            )
        )
    }

    /**
     * Display unassigned accounting locations for the current smart card
     * @param pcId A smart card ID
     * @param unassigned List of available accounting locations (not yet assigned to the card)
     * @returns JSX.Element
     */
    function displayUnassignedLocations(pcId: string, unassigned: Array<ClientLocation>) {
        if (unassigned === undefined || unassigned.length === 0) {
            return (
                <h3 className="text-2xl text-center">There are no unassigned accounting locations for this smart card</h3>
            )
        }

        return (
            <>
                {unassigned.map(x => {
                    return (
                        <div key={x.locationID} role="row" className="flex flex-col items-center sm:flex-none sm:grid sm:grid-cols-12 sm:gap-3 text-xl mb-1" >
                            <div className="sm:col-span-10">{x.locationName}</div>
                            <div className="sm:col-start-11 sm:col-span-2 flex justify-end">
                                <Link to={`/pc/${pcId}/locations/${x.locationID}/admin`} type="button" className="w-28 rounded-md bg-green-500 hover:bg-green-600 text-white border border-yellows-300 text-base flex items-center justify-center px-2 ">
                                    <BuildingOffice2Icon className="w-5 h-5 mr-2" />
                                    <div className="flex items-center pb-1 pr-1">Assign</div>
                                </Link>
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }

    const goToPreviousPage = () => {
        navigate(-1)
    }

    return (
        <div className="relative">
            <PageTitle title="Accounting Location Assignments" />
            <XMarkIcon role="button" onClick={goToPreviousPage} className="w-6 h-6 text-slate-500 absolute top-10 right-2 hover:bg-slate-200" />
            {isLoading && <EllipsisSpinner />}
            {!isLoading && <>
                <h1 className="font-medium leading-tight text-5xl mt-2 mb-2 text-blue-600 text-center">
                    {listQuery.data?.find(x => x.id === params.pcId)?.name}
                </h1>
                <div className="flex flex-col items-center">
                    <div className="flex justify-start w-3/4">
                        <h2 className="font-medium leading-tight text-xl mt-2 mb-2 text-blue-600 text-center">Assigned Accounting Locations</h2>
                    </div>
                    <div className="border border-slate-200 p-2 w-3/4" role="rowgroup">
                        {displayAssignedLocations(assignedLocations)}
                    </div>
                </div>
                <div className="flex flex-col items-center">
                    <div className="flex justify-start w-3/4">
                        <h2 className="font-medium leading-tight text-xl mt-2 mb-2 text-blue-600">Accounting Locations NOT Assigned</h2>
                    </div>
                    <div className="border border-slate-200 p-2 w-3/4" role="rowgroup">
                        {displayUnassignedLocations(params.pcId ?? '', availableLocations)}
                    </div>
                </div>
            </>}
        </div>
    )
}