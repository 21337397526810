import { ChangeEvent, useState } from "react"

export function useNumberSpin(start = 0, min = Number.MIN_VALUE, max = Number.MAX_VALUE) {
    const [value, setValue] = useState<number | undefined>(start)

    const valueChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const qty = parseFloat(e.currentTarget.value)
        if (!isNaN(qty)) {
            setValue(qty)
            return
        }
        setValue(undefined)
    }

    const inc = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        const current = value ?? 0
        const newValue = current + 1
        if (newValue <= max) {
            setValue(newValue)
        }
    }

    const dec = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        const current = value ?? 0
        const newValue = current - 1
        if (newValue >= min) {
            setValue(newValue)
        }
    }

    return {inc, dec, value, valueChanged}
}