import { cardApi, itemApiUrl } from '../Services/ProcdedureCardApi';
import { getItemsForDisplay, SearchResponseDto } from '../Services/ProductSearch';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { useClientStore } from '../hooks/clientStore';
import { fiveMinutes, qkItemSearch } from './queryKeys';

const filterItems = ({ queryKey }: QueryFunctionContext): Promise<SearchResponseDto> => {
    const searchTerm = queryKey[1]
    const clientId = queryKey[2]
    if (!searchTerm) {
        return new Promise(resolve => { })
    }
    return cardApi.post(`${itemApiUrl}`, {
        "RequestType": "FullSearch",
        "ClientId": `${clientId}`,
        "Search": `${searchTerm}`,
        "Skip": "0",
        "Top": 50,
    }).then(response => response.data)
}

export function useItemSearch(searchTerm: string | '') {
    const clientId = useClientStore(s => s.clientId)
    return useQuery({
        queryKey: [qkItemSearch, searchTerm, clientId], queryFn: filterItems,
        staleTime: fiveMinutes,
        select: (data) => {
            const items = getItemsForDisplay(data.response)
            return items
        }
    })
}
