import { CellContext, RowData } from "@tanstack/react-table";
import { InputHTMLAttributes, useEffect, useState, FocusEvent } from "react";
import { ApprovalChainType } from "../hooks/useApprovalChains";
import { toast } from "react-toastify";

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends unknown, TValue> {
    required?: boolean
    pattern?: string
    title?: string
  }
}

export function validateLimit<T>(value: T) {
    const regex = /^\d{1,5}$/
    if (typeof value=='string' && !value.match(regex))
        return false
    return true
}

export function makeCellTextbox(props: InputHTMLAttributes<HTMLInputElement>, validate: <T>(arg: T) => boolean) {
    return function <T>({ getValue, row, column, table }: CellContext<ApprovalChainType, T>) {
        const initialValue = getValue();
        const [value, setValue] = useState<T>(initialValue);
        
        const columnMeta = column.columnDef.meta;

        const onBlur = (e: FocusEvent<HTMLInputElement>) => {
            if (validate(value)) {
                table.options.meta?.updateData<T>(row.index, column.id, value);
                return
            }
            toast.error('Invalid input value. Row not updated')
        }

        // If the initialValue is changed external, sync it up with our state
        useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);

        return (
            <input {...props} className="form-input"
                required={columnMeta?.required}
                pattern={columnMeta?.pattern}
                title={columnMeta?.title}
                value={value as string}
                onChange={(e) => setValue(e.target.value as T)}
                onBlur={onBlur} />
        );
    }
}