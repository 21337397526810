import { NavLink } from "react-router-dom";
import { PageTitle } from "../components/PageTitle";
import { useImportJobs } from "./data-services/useImportJobs";
import * as routeConstants from "../routing/routes";
import { EllipsisSpinner } from "../components/EllipsisSpinner/EllipsisSpinner";
import { ROW_VALIDATION_FAILED, HEADING_VALIDATION_FAILED, SYSTEM_ISSUE, FAILED_TO_MIGRATE_FILE, APPROVAL_DENIED } from "./ImportJobStatus";
import { humanFileSize } from "../utils/string-utils";

export function ViewImports() {
  const { data: importJobs, isLoading } = useImportJobs();

  if (isLoading) {
    return <EllipsisSpinner />;
  }

  return (
    <>
      <PageTitle title="View Imports" />
      <div className="flex flex-col items-center mt-3">
        <div className="w-10/12">
          <div
            className="hidden sm:grid grid-cols-12 text-white font-semibold 
                bg-hte-mid-blue shadow-none break-words min-h-[50px]"
          >
            <div className="contents">
              <div className="pl-2 col-span-3 flex items-center">
                Original File
              </div>
              <div className="pl-1 flex items-center">Import Type</div>
              <div className="pl-1 flex items-center">Line Count</div>
              <div className="pl-1 flex items-center">File Size</div>
              <div className="pl-1 col-span-2 flex items-center">Date</div>
              <div className="pl-1 col-span-2 flex items-center">Status</div>
              <div className="pl-1 col-span-2 flex items-center">User</div>
            </div>
          </div>
          <div className=" h-[80vh] overflow-auto">
            {importJobs?.map((job, index) => {
              return (
                <NavLink
                  key={job.key}
                  to={`${routeConstants.DL_VIEWIMPORTS}/${job.key}`}
                  className={`font-normal ${
                    job.importStatusId === ROW_VALIDATION_FAILED || job.importStatusId === SYSTEM_ISSUE ||
                    job.importStatusId === HEADING_VALIDATION_FAILED || job.importStatusId === FAILED_TO_MIGRATE_FILE 
                    || job.importStatusId === ROW_VALIDATION_FAILED || job.importStatusId === APPROVAL_DENIED
                      ? "text-red-600"
                      : "text-gray-600"
                  } mb-1 sm:mb-0
                grid grid-cols-[max-content_1fr] sm:grid-cols-12
                sm:bg-white sm:hover:bg-gray-200 sm:shadow-none odd:bg-white even:bg-gray-100 break-words sm:border-b sm:min-h-[40px]`}
                >
                  <div className="contents">
                    <div className="bg-hte-mid-blue text-white pl-1 sm:hidden">
                      Original File
                    </div>
                    <div className="pl-1 sm:pl-2 sm:col-span-3 flex items-center border-b sm:border-b-0 overflow-clip">
                      {job.originalFileName}
                    </div>

                    <div className="bg-hte-mid-blue text-white pl-1 sm:hidden">
                      Import Type
                    </div>
                    <div className="pl-1 flex items-center border-b sm:border-b-0 overflow-hidden">
                      {job.importTypeName}
                    </div>

                    <div className="bg-hte-mid-blue text-white pl-1 sm:hidden">
                      Line Count
                    </div>
                    <div className="pl-1 flex items-center border-b sm:border-b-0">
                      {job.totalLineCount}
                    </div>

                    <div className="bg-hte-mid-blue text-white pl-1 sm:hidden">
                      File Size
                    </div>
                    <div className="pl-1 flex items-center border-b sm:border-b-0">
                      {humanFileSize(job.fileSizeBytes)}
                    </div>

                    <div className="bg-hte-mid-blue text-white pl-1 sm:hidden">
                      Date
                    </div>
                    <div className="pl-1 sm:grid sm:col-span-2 flex items-center border-b sm:border-b-0">
                      {job.createdDt}
                    </div>

                    <div className="bg-hte-mid-blue text-white pl-1 sm:hidden">
                      Status
                    </div>
                    <div className="pl-1 sm:col-span-2 flex items-center border-b sm:border-b-0 bg-amber-50">
                      {job.importStatus}
                    </div>
                    <div className="bg-hte-mid-blue text-white pl-1 sm:hidden">
                      User
                    </div>
                    <div className="pl-1 sm:col-span-2 flex items-center border-b sm:border-b-0">
                      {job.username}
                    </div>
                  </div>
                </NavLink>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
