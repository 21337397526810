import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { cardApi, cardApiGet } from "../Services/ProcdedureCardApi";
import { ProcedureCardDto } from "../types/Item";
import { qkSmartCard } from "./queryKeys";

const fetchSmartCard = ({ queryKey }: QueryFunctionContext): Promise<ProcedureCardDto> => {
    const pcId = queryKey[1]
    const accountingLocationId = queryKey[2]
    if (!pcId) {
        return new Promise(resolve => { })
    }
    return cardApi.get(`${cardApiGet}pc=${pcId}&locId=${accountingLocationId}`).then(response => response.data)
}

export function useSmartCardData(pcId: string | '', accountingLocationId: string) {
    return useQuery({ queryKey: [qkSmartCard, pcId, accountingLocationId], queryFn: fetchSmartCard })
}