import { useQuery } from "@tanstack/react-query"
import { z } from "zod"
import { cardApi, getApprovalTypes } from "../Services/ProcdedureCardApi"
import { qkApprovalTypes } from "./queryKeys"
import { SelectOption } from "../components/LocationsSelect"

const apiResponse = z.string().array()

const fetchApprovalTypes = () => {
    return cardApi.get(`${getApprovalTypes}`).then(response => apiResponse.parse(response.data))
}

export function useApprovalTypes() {
    return useQuery({
        queryKey: [qkApprovalTypes],
        queryFn: fetchApprovalTypes,
        staleTime: Infinity,
        refetchOnWindowFocus: true,
        select: (data):Array<SelectOption> => {
            return [{id: 'unknown', name: 'Unknown'},...data.map(x => ({ id: x, name: x }))]
        }
    })
}