import { EnumLocations } from './../types/Item';
import { SelectOption } from "../components/LocationsSelect"
import { ProcedureCardListDto } from "../dtos"
import { EmptyGuid } from "../settings"
import { ClientLocation } from "../types/Item"
import { compareByPropFactory } from "./array-utils"

export interface AccountingLocation {
    salesAccountUid: string,
    salesAccountCode: string
    billToAddressUid: string
    billToAddressCode: string
    shipToAddressUid: string
    shipToAddressCode: string

    locationID: string;
    locationName: string;
    clientID: string;
}

/**
 * 
 * @param arr An array of arbitrary objects
 * @param identifier Function return an identifier for an obejct in the array
 * @returns An array of unique objects from the input array as identified by the indentity function
 */
export const unique = <T, I>(arr: Array<T>, identifier: (x: T) => I) => {
    return arr
        .filter(
            (x, i, self) => i === self.findIndex(y => identifier(x) === identifier(y))
        )
}

/**
 * Get the pick locations for a sales account as a select box options
 * @param locations List of sales accounts with their locations (possibly all three types in EnumLocations) for a client
 * @param salesAccountUid The salesAccountUid connects all three types of locations in EnumLocations
 * @returns Array<SelectOption> 
 */
export function getSelectPickLocations(locations: Array<ClientLocation>, salesAccountUid: string | undefined): Array<SelectOption> {
    if (!salesAccountUid || salesAccountUid === EmptyGuid) {
        return []
    }
    const pickLocations = locations.filter(x => x.salesAccountUid === salesAccountUid && x.locationType === EnumLocations.PickLocation)
    if (pickLocations.length === 0) {
        const defaultPickLocation = locations.find(x => x.salesAccountUid === salesAccountUid &&
            (x.locationType === EnumLocations.AccountingLocation || x.locationType === EnumLocations.ShipLocation))
        if (defaultPickLocation) pickLocations.unshift(defaultPickLocation);
    }
    return pickLocations
        .map(x => { return { id: x.locationID, name: x.locationName } })
}

/**
 * Returns identifying property
 * @param x A ClientLocation record
 * @returns 
 */
const identifier = (x: ClientLocation) => x.locationID

/**
 * Retrieve unique list of accounting locations with no duplicates
 * @param locations Client's full list of sales account and their locations
 */
export function getAccountingLocations(locations: Array<ClientLocation>|undefined) {
    if (!locations) return []
    const accLocations = locations.filter(x => x.locationType === EnumLocations.AccountingLocation || x.locationType === EnumLocations.ShipLocation)
    return unique<ClientLocation, string>(accLocations, identifier)
}

/**
 * Get the accounting locations as list of SelectOption
 * @param locations List of client's sales accounts and their locations
 * @returns Array of SelectOption
 */
export function getSelectAccountingLocations(locations: Array<ClientLocation>): Array<SelectOption> {
    const accLocations = locations.filter(x => x.locationType === EnumLocations.AccountingLocation || x.locationType === EnumLocations.ShipLocation)
    const noDuplicates = unique<ClientLocation, string>(accLocations, identifier)
    return noDuplicates
        .map(x => { return { id: x.locationID, name: x.locationCode + ' - ' + x.locationName } })
}

/**
 * 
 * @param pcId ID of a smart card
 * @param smartCardList List of smart card/accounting locations pairs
 * @returns Array of accounting locations assigned to smart card pcId
 */
export function getAssignedLocations(pcId: string, smartCardList: Array<ProcedureCardListDto>): Array<{ accountingLocationId: string, accountingLocationName: string }> {
    return smartCardList.filter(x => x.id === pcId && x.accountingLocationName).map(x => {
        const { accountingLocationId, accountingLocationName, ...rest } = x
        return {
            accountingLocationId,
            accountingLocationName
        }
    })
}

export const sortCardsByLocationName = compareByPropFactory<ProcedureCardListDto>('accountingLocationName')
