import { ArrowLeftOnRectangleIcon, ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import circle360url from '../images/circle360.png'
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { SideBarSubMenu, SideBarSubMenuSpec } from "./SideBarSubMenu";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { login, logout } from "../authentication";
import * as routeConstants from '../routing/routes'
import { ADD_USER_PAGE, USERS } from "../routing/routes";

export const menuNames = {
    SmartCard: 'Smart Cards', 
    DataLoader: 'Data Loader', 
    Admin: 'Admin', 
}

export function SideBar() {
    const [open, setOpen] = useState(true)
    const { instance } = useMsal()
    const isAuthenticated = useIsAuthenticated();
    const showSmartCard = true;

    const smartCardSubmenu: SideBarSubMenuSpec = {
        name: menuNames.SmartCard,
        options: [
            { name: 'Create Card', url: '/new' },
            { name: 'Show Cards', url: '/pc/list' },
        ]
    }

    const dataLoaderSubmenu: SideBarSubMenuSpec = {
        name: menuNames.DataLoader,
        options: [
            { name: 'Import File', url: routeConstants.DL_NEWIMPORT },
            { name: 'View Imports', url: routeConstants.DL_VIEWIMPORTS },
            { name: 'Mappings', url: routeConstants.DL_MAPPINGS }
        ]
    }

    const adminSubmenu: SideBarSubMenuSpec = {
        name: menuNames.Admin,
        options: [
            { name: 'Add users', url: ADD_USER_PAGE },
            { name: 'Show users', url: USERS },
            { name: 'Select portals', url: routeConstants.SELECT_PORTAL },
            { name: 'Approval chains', url: routeConstants.APPROVAL_CHAINS },
        ]
    }

    return (
        <div className={` ${open ? "w-72" : "w-20 "} bg-hte-dark-blue min-h-screen shrink-0 p-5 pt-8 relative duration-300 print:hidden`} onClick={() => { setOpen(true) }}>
            <ChevronLeftIcon
                className={`absolute cursor-pointer -right-3 top-5 w-7 bg-white border-indigo-900 border-2 rounded-full  ${!open && "rotate-180"}`}
                onClick={(e) => { e.stopPropagation(); setOpen(!open) }}
            />
            <div className="flex gap-x-4 items-center">
                <img src={circle360url}
                    className={`h-7 w-7 cursor-pointer duration-500 ${open && "rotate-[360deg]"}`}
                />
                <h1 className={`text-white origin-left font-medium text-xl duration-200 ${!open && "scale-0"}`}>
                    Smart 360
                </h1>
            </div>
            <ul className="pt-6">
                {showSmartCard && <li className="flex flex-col">
                    <SideBarSubMenu parentSideBarOpen={open} menu={smartCardSubmenu} />
                </li>}
                <li className="flex flex-col">
                    <SideBarSubMenu parentSideBarOpen={open} menu={dataLoaderSubmenu} />
                </li>
                <li className="flex flex-col">
                    <SideBarSubMenu parentSideBarOpen={open} menu={adminSubmenu} />
                </li>
            </ul>
            <div className="flex justify-center mt-10">
                {
                    isAuthenticated
                        ? <button type="button" className={`flex bg-hte-orange hover:bg-orange-600 text-indigo-50 font-semibold py-2 ${!open ? "rounded-full px-2" : "rounded-md px-6"}`} onClick={() => logout(instance)}>
                            <ArrowLeftOnRectangleIcon className="w-5 h-5" />
                            <span className={`${!open && "hidden"} origin-left duration-200 ml-1`}>Sign Out</span>
                        </button>
                        : <button type="button" className={`flex bg-green-500 hover:bg-green-600 text-indigo-50 font-semibold py-2 ${!open ? "rounded-full px-2" : "rounded-md px-5"}`} onClick={() => login(instance)}>
                            <ArrowRightOnRectangleIcon className="w-5 h-5" />
                            <span className={`${!open && "hidden"} origin-left duration-200 ml-1`}>Sign In</span>
                        </button>

                }
            </div>
        </div>
    )
}