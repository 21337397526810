import { FormEvent, ReactNode, useState } from "react";
import Modal from "react-modal";
import { ModalAppSelector } from '../hooks'
import { NoOp } from "../types/Item";

Modal.setAppElement(ModalAppSelector());
class Deferred {
    promise: Promise<boolean>;
    reject!: (reason?: any) => void;
    resolve!: (value: boolean | PromiseLike<boolean>) => void;
    constructor() {
        this.promise = new Promise<boolean>((resolve, reject) => {
            this.reject = reject;
            this.resolve = resolve;
        });
    }
}

export interface useConfirmModalResult {
    modal: ReactNode;
    open: () => Promise<boolean>;
    close: () => void;
    cancelButtonText?: string
    confirmButtonText?: string
}

interface useConfirmModalProps {
    message?: string
    confirm: () => void
    cancelButtonText?: string
    confirmButtonText?: string
}

export function useConfirmModal({
    message = "Are you sure to proceed?",
    confirm= NoOp,
    cancelButtonText='Cancel',
    confirmButtonText='Confirm'}: useConfirmModalProps
): useConfirmModalResult {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [confirmPromise, setConfirmPromise] = useState(new Deferred());

    function openModal() {
        const myPromise = new Deferred()
        setConfirmPromise(myPromise);
        setIsOpen(true);
        return myPromise.promise
    }

    function closeModal() {
        setIsOpen(false);
    }

    /**
     * This handles user clicking the cancel button
     */
    const submitHandler = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        confirmPromise.resolve(false);
        closeModal();
    };

    const modal = (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Confirm modal"
            overlayClassName="itemDeleteModalOverlay"
            shouldCloseOnOverlayClick={true}
            className="itemDeleteModal"
            closeTimeoutMS={200}
        >
            <div className="bg-white px-16 py-14 rounded-md text-center z-50">
                <h1 className="text-xl mb-4 font-bold text-slate-500">
                    {message}
                </h1>
                <form
                    onSubmit={submitHandler}
                    className="flex justify-center gap-1"
                >
                    <button type="submit" autoFocus className="btn-danger">
                        {cancelButtonText}
                    </button>
                    <button
                        type="button"
                        className="btn-primary"
                        onClick={(e) => {
                            confirmPromise.resolve(true);
                            confirm();
                        }}
                    >
                        {confirmButtonText}
                    </button>
                </form>
            </div>
        </Modal>
    );

    return {
        modal,
        open: openModal,
        close: closeModal,
    };
}