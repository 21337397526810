import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { useClientStore } from "../../hooks/clientStore"
import { cardApi, getImportJobLogs } from "../../Services/ProcdedureCardApi";
import { qkImportJobErrors } from "../../hooks/queryKeys";
import { z } from "zod"
import { GuidID } from "../../types/Item";

const ImportJobErrorSchema = z.object({
    id: z.number(),
    message: z.string(),
    createdDt: z.string(),
    state: z.string()
  })

const apiResponse = ImportJobErrorSchema.array()

const fetchImportJobErrors = ({ queryKey }: QueryFunctionContext) => {
    const clientId = queryKey[1]
    const jobId = queryKey[2]
    return cardApi.post(`${getImportJobLogs}`, { clientId: clientId, importJobKey: jobId }).then(response => apiResponse.parse(response.data))
}

export function useImportJobErrors(jobId: GuidID|undefined) {
    const clientId = useClientStore(s => s.clientId)

    return useQuery({ queryKey: [qkImportJobErrors, clientId, jobId], queryFn: fetchImportJobErrors, enabled: !!jobId })
}