import { useReducer } from "react";

interface CustomMappingPageState {
    gridFormat: Array<string>;
}

export const ActionType = {
    defaultPageOn: "DEFAULT_PAGE_ON",
    VIEW_STANDARD_MAP: "VIEW_STANDARD_MAP",
    VIEW_CUSTOM_MAP: "VIEW_CUSTOM_MAP",
};

interface Action {
    type: string;
    payload?: unknown;
}

const standardMapFormat = [" sm:grid sm:grid-cols-12 ", "  sm:col-span-5 ", " sm:col-span-1 ", "  sm:col-span-1 ", "  sm:col-span-5 "] 
const customMapFormat = [" sm:grid sm:grid-cols-12 ", "  sm:col-span-3 ", " sm:col-span-1 ", "  sm:col-span-1 ", "  sm:col-span-3 ", "  sm:col-span-4 "]
const initialState = {
    gridFormat: standardMapFormat,
};

function reducer(state: CustomMappingPageState, action: Action): CustomMappingPageState{
    switch (action.type) {
        case ActionType.VIEW_STANDARD_MAP: {
            return { ...state, gridFormat: standardMapFormat };
        }
        case ActionType.VIEW_CUSTOM_MAP: {
            return { ...state, gridFormat: customMapFormat };
        }
        default:
            return state;
    }
}

export function useCustomMappingState() {
    return useReducer(reducer, initialState);
}
