import { EmptyGuid } from './../settings';
// Process response from Azure Product Search

import { Item } from "../types/Item";

//export const url = "https://qa-uks-rg-azuresearch.azurewebsites.net/api/Search?code=emDAGTqYWx9iHa9S0ZjmsvOz9RohUysSvyY3sCQGDAh5AzFumZXHEQ=="

interface FoundItem {
    ItemId: string;
    ItemName: string;
    ItemCode: string;
    ItemImage: string;
    UnitPrice: string;
    PackQty: string;
    Uom: string;
    UomName: string;
}

interface Manufacturer {
    ManufacturerId: string;
    ManufacturerName: string;
    Manufacturerlogo: string;
    ManufacturerCode: string;
    ManufacturerItemCode: string;
}

interface SupplierDto {
    SupplierId: string;
    SupplierName: string;
    SupplierItemCode: string;
    Supplierlogo: string;
}

interface Response {
    ClientItemId: string;
    Client: string;
    Category: string;
    Deleted: boolean;
    Item: FoundItem[];
    Manufacturer: Manufacturer[];
    Suppliers: any[];
}

interface Facet2 {
    count: number;
    facetType: string;
    displayName?: any;
    key: string;
    from: string;
    to: string;
}

interface Facet {
    facets: Facet2[];
    key: string;
}

export interface SearchResponseDto {
    totalCount: number;
    response: Response[];
    facets: Facet[];
}

export interface ItemSourceDto {
    itemID: number,
    name: string,
    code: string,
    image: string,
    manufacturerName: string,
    manufacturerCode: string,
    supplierName: string,
    supplierItemCode: string,
}

export function getItemsForDisplay(response: Array<Response>): Array<Item> {
    return response.map((x)=>{
        const found = x.Item[0];
        const maker:Manufacturer|undefined = x.Manufacturer[0];
        const item: Item = {
            itemID: +found.ItemId,
            name: found.ItemName,
            code: found.ItemCode,
            image: found.ItemImage,
            supplier: "",
            manufacturer: maker?.ManufacturerName||'',
            uomName: found.UomName,
            pickQty: 0,
            minonhandqty: 0,
            maxonhandqty: 0,
            lastcheckonhandqty: 0,
            pickLocationID: EmptyGuid,
        }
        return item
    })
}

/**
 * Just extract some manufacturer/supplier info we need to submit a basket request to c360
 * @param response 
 * @returns 
 */
export function extractItemSource(response: Array<Response>): Array<ItemSourceDto> {
    return response.map((x)=>{
        const found = x.Item[0];
        const manu:Manufacturer|undefined = x.Manufacturer[0];
        const supplier:SupplierDto|undefined = x.Suppliers[0];
        const item: ItemSourceDto = {
            itemID: +found.ItemId,
            name: found.ItemName,
            code: found.ItemCode,
            image: found.ItemImage,
            supplierName: supplier?.SupplierName||'',
            supplierItemCode: supplier?.SupplierItemCode||'',
            manufacturerName: manu?.ManufacturerName||'',
            manufacturerCode: manu?.ManufacturerCode||'',
        }
        return item
    })
}