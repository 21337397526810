import { ApprovalChainType } from "../hooks/useApprovalChains";

//events.js
export const EVENT_APPROVAL_CHAIN_CREATED = 'EventApprovalChainCreated'
export const EVENT_APPROVAL_CHAIN_UPDATED = 'EventApprovalChainUpdated'
export const EVENT_APPROVAL_CHAIN_DELETED = 'EventApprovalChainDeleted'

class CustomEventTarget extends EventTarget {}

export const createPubsub = <T = Record<string, unknown>>() => {
  const customEventTarget = new CustomEventTarget();

  const subscribe = (eventName: string, eventHandler: (data: T) => void) => {
    const scriptContentResizedEventHandler = (event: Event) => {
      const data = (event as CustomEvent).detail as T;

      eventHandler(data);
    };

    customEventTarget.addEventListener(eventName, scriptContentResizedEventHandler);

    return () => {
      customEventTarget.removeEventListener(eventName, scriptContentResizedEventHandler);
    };
  };

  const publish = (eventName: string, data: T) => {
    customEventTarget.dispatchEvent(new CustomEvent(eventName, { detail: data }));
  };

  return { subscribe, publish };
};

export const ApprovalChainPubsub = createPubsub<ApprovalChainType>()