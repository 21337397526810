import { DeleteCustomMappingDto } from "../../dtos";
import { CustomMapType } from "../data-services/useCustomMaps";
import { DefaultMappingType, UpdateCustomMappingDto } from "../data-services/useDefaultMapping";

export class MapModifications {
    importTypeName: string | undefined = undefined;
    mapId: number | null | undefined = undefined;
    mapName: string | null | undefined = undefined;
    mapNewName: string | undefined = undefined;
    mapDesc: string | null | undefined = undefined;
    mapNewDesc: string | undefined = undefined;
    modified: boolean = false;
    updatedColumns: Map<string, DefaultMappingType> = new Map<
        string,
        DefaultMappingType
    >();

    constructor({
        mapId,
        mapName,
        description,
        importTypeName,
    }: CustomMapType) {
        this.importTypeName = importTypeName;
        this.mapDesc = description;
        this.mapName = mapName;
        this.mapId = mapId;
    }

    columnNameChange(column: DefaultMappingType) {
        this.updatedColumns.set(column.mappableName, column);
        this.setModified();
    }

    mapNameChanged(name: string) {
        this.mapNewName = name;
        this.setModified();
    }

    mapDescChanged(desc: string) {
        this.mapNewDesc = desc;
        this.setModified();
    }

    /**
     * Current map changed. Empty tracked data.
     */
    onMapChanged(
        importTypeName: string,
        mapId: number | null,
        mapName: string | null,
        mapDesc: string | null
    ) {
        this.importTypeName = importTypeName;
        this.mapId = mapId;
        this.mapName = mapName;
        this.mapDesc = mapDesc;
        this.updatedColumns = new Map<string,DefaultMappingType>();
        this.setPristine();
    }

    setModified() {
        this.modified = true;
    }

    isModified() {
        return this.modified;
    }

    setPristine() {
        this.modified = false;
        this.mapNewName = undefined;
        this.updatedColumns.clear();
    }

    /**
     * Create object for backend api to update the custom mapping
     */
    mkUpdateDto(clientId: string): UpdateCustomMappingDto {
        return {
            clientId: clientId,
            importTypeName: this.importTypeName!,
            mapId: this.mapId!,
            mapName: this.mapNewName ?? this.mapName!,
            mapDesc: this.mapNewDesc ?? this.mapDesc!,
            updatedColumns: [...this.updatedColumns.values()],
        };
    }

    mkDeleteDto(clientId: string): DeleteCustomMappingDto {
        return {
            clientId: clientId,
            importTypeName: this.importTypeName!,
            mapId: this.mapId!,
        };
    }
}