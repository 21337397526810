import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { z } from "zod"
import { cardApi, getClientUsers } from "../Services/ProcdedureCardApi"
import { useClientStore } from "./clientStore"
import { qkClientUsers } from "./queryKeys"

export const ClientUserSchema = z.object({
  id: z.number(),
  name: z.string(),
  emailAddress: z.string(),
})

export type ClientUserType = z.infer<typeof ClientUserSchema>

const apiResponse = ClientUserSchema.array()

const fetchClientUsers = ({ queryKey }: QueryFunctionContext) => {
    const clientId = queryKey[1]
    return cardApi.get(`${getClientUsers}?clientId=${clientId}`).then(response => apiResponse.parse(response.data))
}

export function useClientUsers() {
    const clientId = useClientStore(s => s.clientId)
    return useQuery({
        queryKey: [qkClientUsers, clientId],
        queryFn: fetchClientUsers,
        staleTime: Infinity,
        refetchOnWindowFocus: true,
    })
}