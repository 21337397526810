import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactNode, useRef, useState } from "react";
import Modal from 'react-modal';
import { ProductSearchList } from "../components/ProductSearchList";
import { SearchBox } from "../components/SearchBox";
import { Item, NoOp } from "../types/Item";
import { ModalAppSelector } from ".";

Modal.setAppElement(ModalAppSelector());
export interface useProductSearchModalResult {
    modal: ReactNode,
    open: () => void,
    close: () => void,
}

export function useProductSearchModal(onProductAdded: (product: Item) => void = NoOp): useProductSearchModalResult {

    function openModal() {
        setModalIsOpen(true);
    }

    function closeModal() {
        setModalIsOpen(false);
    }

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const selectedProducts = useRef<Array<Item>>([])
    let setSearchTerm: React.Dispatch<React.SetStateAction<string>> = NoOp

    function searchTermUpdated(newValue: string) {
        setSearchTerm(newValue)
    }

    /**
     * Only add selected product to list if not already there
     * @param product - Selected product (item)
     */
    function productSelected(product: Item) {
        onProductAdded(product)
        const found = selectedProducts.current.find(x => x.itemID === product.itemID)
        if (!found) {
            selectedProducts.current.push(product)
        }
    }

    function getSearchTermSetter(setTerm: React.Dispatch<React.SetStateAction<string>>) {
        setSearchTerm = setTerm
    }

    const modal = <Modal
        style={{
            content: {
                borderRadius: '10px'
            }
        }}
        isOpen={modalIsOpen}
        onRequestClose={() => alert("Close")}
        contentLabel="Search modal"
        overlayClassName="searchModalOverlay"
        shouldCloseOnOverlayClick={true}
        closeTimeoutMS={200}>
        <div className="bg-white h-full text-center no-y-scroll">
            <XMarkIcon className="h-5 w-5 absolute right-1 top-1 text-slate-400 hover:text-slate-900 hover:cursor-pointer" onClick={closeModal}/>
            <SearchBox onUpdated={searchTermUpdated} />
            <ProductSearchList onItemSelected={productSelected} onMount={getSearchTermSetter} />
        </div>
    </Modal>

    return (
        {
            modal,
            open: openModal,
            close: closeModal,
        }
    )
}