import { useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { BasketContext } from "../App"
import { Item } from "../types/Item"
import { SearchItemCard } from "./SearchItemCard"

interface ItemsSearchListProps {
    data: Array<Item> | undefined
}

export function ItemsSearchList({ data }: ItemsSearchListProps) {
    const [items, setItems] = useState(data ?? [])
    const basketContext = useContext(BasketContext)
    const [addFromList, setAddFromList] = useState(new Map<number, number>())

    const quantityChanged = (itemId: number) => (quantity: number | undefined) => {
        setAddFromList(map => new Map<number, number>(map.set(itemId, quantity ?? 0)))
    }

    const addToBasket = (item: Item) => (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        let quantity = addFromList.get(item.itemID)
        if (!quantity) {
            quantity = 1
        }
        item.pickQty = quantity
        basketContext?.setBasket(new Map<number, Item>(basketContext.basket).set(item.itemID, item))
        toast.info('Item added to basket', { autoClose: 1000 })
    }

    useEffect(() => {
        setItems(data ?? [])
    }, [data])

    return (
        <div className="min-w-full shadow-lg flex flex-col" role="table">
            {/* Heading */}
            <div className="text-xs font-medium text-left uppercase bg-sky-500 text-white 
                hidden sm:grid sm:grid-cols-5" role="rowgroup">
                <div className="py-3 hidden sm:block" role="columnheader">
                </div>
                <div className="py-3" role="columnheader">
                    Item Name
                </div>
                <div className="py-3 flex flex-row justify-center" role="columnheader">
                    Quantity
                </div>
                <div className="py-3" role="columnheader">
                    Manufacturer
                </div>
                <div className="py-3 flex flex-row justify-center" role="columnheader">
                    Add to cart
                </div>
            </div>
            <div className="bg-white" role="rowgroup">
                {items?.map((item) => {
                    return (
                        <SearchItemCard key={item.itemID} item={item} buttonClicked={addToBasket(item)} quantityChanged={quantityChanged(item.itemID)} />
                    )
                })}
            </div>
        </div>
    )
}