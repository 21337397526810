import { z } from "zod";
import { cardApi, logRequestItems } from "../../Services/ProcdedureCardApi";
import { useMutation } from "@tanstack/react-query";

const RequestItemSchema = z.object({
    requestOrderRef: z.string(),
    userOid: z.string(),
    clientId: z.string(),
    smartCardId: z.string(),
    locationId: z.string(),
    locationName: z.string(),
    itemId: z.number(),
    pickQuantity: z.number(),
    pickLocationId: z.string().or(z.undefined()),
});

const LogRequestItemsDtoSchema = z.object({
    requestItems: RequestItemSchema.array(),
});

type RequestItemType = z.infer<typeof RequestItemSchema>;
export type LogRequestItemsDto = z.infer<typeof LogRequestItemsDtoSchema>;

export function useLogRequestItems() {
    return useMutation({
        mutationFn: (data: LogRequestItemsDto) => cardApi.post(logRequestItems, data),
        onSuccess: (data, variables, context) => { },
        onError: (error, variables, context) => {
            if (error instanceof Error) {
                console.error("Error logging submitted request items!", error)
            }
        },
    });
}
