import { useMutation, useQueryClient } from "@tanstack/react-query"
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react"
import { useClientStore } from "../../hooks/clientStore"
import { EllipsisSpinner } from "../../components/EllipsisSpinner/EllipsisSpinner"
import { FormInput } from "../../components/FormInput"
import { LocationsSelect } from "../../components/LocationsSelect"
import { PageTitle } from "../../components/PageTitle"
import {
    MappingType,
    useCurrentMappings,
} from "../data-services/useCurrentMappings"
import { Tabs } from "../../components/Tabs"
import { IdName } from "../../types/Item"
import {
    DefaultMappingType,
    UpdateCustomMappingDto,
    useDefaultMapping,
} from "../data-services/useDefaultMapping"
import { capitalize, istrEqual, randomInteger } from "../../utils/string-utils"
import { useConfirmModal } from "../../hooks/useConfirmModal"
import {
    cardApi,
    deleteCustomMapping,
    updateCustomMapping,
} from "../../Services/ProcdedureCardApi"
import { useImportTypes } from "../data-services/useImportTypes"
import { toast } from "react-toastify"
import { qkCustomMaps, qkMappings } from "../../hooks/queryKeys"
import { DeleteCustomMappingDto } from "../../dtos"
import { CustomMapType, useCustomMaps } from "../data-services/useCustomMaps"
import { ActionType, useCustomMappingState } from "./useCustomMappingState"
import { MapModifications } from "./MapModifications"
import { CheckIcon } from "@heroicons/react/24/solid"
import { AxiosResponse } from "axios"

type Mapping = {
    id: number | null
    name: string | null
    description: string | null
}

type MapState = {
    id: number
    name: string | null
    description: string | null
}

/**
 * Used for tracking user modifications to the current mapping
 */

export function MultiMappingsPage() {
    const clientId = useClientStore((state) => state.clientId)
    const [pageState, dispatch] = useCustomMappingState()

    const [importTypeName, setImportTypeName] = useState("")
    const [defaultMapSelected, setDefaultMapSelected] = useState(true)
    const [newCustomMap, setNewCustomMap] = useState<Mapping>()
    const [uniqueMapNames, setUniqueMapNames] = useState<Array<Mapping>>([])

    /**
     * All client custom mappings in smart360 database. !!!TODO-Needs revisiting as we now use default mapping from data dictionary
     */
    const [clientMappings, setClientMappings] = useState<Array<MappingType>>([])

    /**
     * This tracks the current mapping displayed on the page
     */
    const [currentMap, setCurrentMap] = useState<Mapping>(defaultMapId())
    const [currentMapState, setCurrentMapState] = useState<MapState>()
    /**
     * All mappable columns in the current mapping on display with default mapping from data dictionary and custom mapping from Smart360 database.
     * Basically a copy of the default mapping overwritten by custom column mappings from the current map
     */
    const [currentColumns, setCurrentColumns] = useState<
        Array<DefaultMappingType>
    >([])

    const queryClient = useQueryClient()

    /**
     * All the custom maps in the current import type
     */
    const { data: customMaps } = useCustomMaps()

    /**
     * All the custom column mappings for all the maps in the current import type
     */
    const { data: serverMappings, isLoading } = useCurrentMappings()

    /**
     * List of unique import type names (entityname) retrieved from client database data dictionary
     */
    const { data: importTypeNames } = useImportTypes()

    const importTypes = useMemo(() => {
        return importTypeNames?.map((x) => ({
            id: x.importTypeName,
            name: x.importTypeName,
        }))
    }, [importTypeNames])

    /**
     * Default mapping for import type (entity) retrieved from client database data dictionary
     */
    const { data: defaultMapping } = useDefaultMapping(importTypeName)

    /**
     * Modal for confirming a delete
     */
    const {
        modal: deleteModal,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useConfirmModal({
        message: "Are you sure to delete the custom map?",
        confirm: close,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
    })

    /**
     * Prompt user to save data
     */
    const {
        modal: saveModal,
        open: openSaveModal,
        close: closeSaveModal,
    } = useConfirmModal({
        message: "Save changes made to the current map?",
        confirm: () => {
            updateMappings()
            closeSaveModal()
        },
        confirmButtonText: "Yes",
        cancelButtonText: "No",
    })

    function doDeleteCustomMap(dto: DeleteCustomMappingDto) {
        deleteCustomMapApi.mutate(dto, {
            onSuccess: (data, variables, context) => {
                toast.success("Custom mapping deleted", {
                    autoClose: 1000,
                })
            },

            onError: (error, variables, context) => {
                if (error instanceof Error) {
                    toast.error(
                        `Error deleting custom mapping: , ${error.message}`,
                        { autoClose: 1000 }
                    )
                }
            },
        })
    }

    /**
     * Change the table display columns
     */
    useEffect(() => {
        if (defaultMapSelected) {
            dispatch({ type: ActionType.VIEW_STANDARD_MAP })
        } else {
            dispatch({ type: ActionType.VIEW_CUSTOM_MAP })
        }
    }, [defaultMapSelected])

    /**
     * Update clientMappings.
     */
    useEffect(() => {
        setClientMappings(serverMappings ?? [])
    }, [serverMappings])

    /**
     * Current columns on display depends on import type, map and the default mapping for the type
     */
    useEffect(() => {
        setCurrentColumns(getMapColumns(importTypeName, currentMap?.id ?? null))
    }, [importTypeName, currentMap, defaultMapping, clientMappings])

    function updateUniqueMapNames() {
        const uniqMapIds: Array<Mapping> =
            customMaps
                ?.filter((x) => istrEqual(x.importTypeName, importTypeName))
                .map((x) => ({
                    id: x.mapId,
                    name: x.mapName,
                    description: x.description,
                })) ?? []

        if (uniqMapIds.length === 0) {
            uniqMapIds.push(defaultMapId())
            setDefaultMapSelected(true)
        } else {
            setDefaultMapSelected(false)
        }

        setUniqueMapNames([...uniqMapIds])
    }

    /**
     * When import type changes (user selecting a different import type)
     * We have to change the list of map names to hold maps for this import type
     * We only hold map names for the currently selected import type
     */
    useEffect(() => {
        updateUniqueMapNames()
    }, [importTypeName])

    /**
     * List of map names can also change when a user deletes a map
     * We'll need to update the list to remove the deleted map. We do
     * this indirectly by invalidating the query (react-query) behind clientMappings
     */
    useEffect(() => {
        updateUniqueMapNames()
    }, [customMaps])

    /**
     * When map names list is updated we need to update the current map too
     * but only if is not in the list else we'll just leave it where it was.
     * When the list is empty we'll add the default one. So when user deletes
     * all the custom maps they can still see the default one.
     */
    useEffect(() => {
        if (uniqueMapNames.length === 0) {
            // Show default map when there are no custom map
            setUniqueMapNames([defaultMapId()])
            setDefaultMapSelected(true)
        }

        const found = uniqueMapNames.find((x) => x.id == currentMap.id)
        if (!found) {
            setCurrentMap({ ...uniqueMapNames[0] })
        }
    }, [uniqueMapNames])

    /**
     * User added a new map
     * Add it to the list and remove the default one
     */
    useEffect(() => {
        // Add new map
        if (newCustomMap) {
            setUniqueMapNames([
                ...uniqueMapNames.filter((x) => x.id != null),
                newCustomMap,
            ])
            setDefaultMapSelected(false)
        }
    }, [newCustomMap])

    /**
     * User selected a different map
     * Update current map state. Currently just the map name.
     */
    useEffect(() => {
        if (currentMap) {
            // Clientmappings current has no description stored
            const found = customMaps?.find((x) => x.mapId === currentMap?.id)
            setCurrentMapState({
                id: currentMap.id!,
                name: currentMap.name,
                description: found?.description ?? null,
            })
            trackUserModifications.current = new MapModifications({
                mapId: currentMap.id!,
                mapName: currentMap.name,
                description: found?.description ?? null,
                importTypeName: importTypeName,
            })
        }
    }, [currentMap])

    const trackUserModifications = useRef<MapModifications>()

    function refetchFromServer() {
        trackUserModifications.current?.setPristine()
        queryClient.invalidateQueries({ queryKey: [qkMappings, clientId] })
        queryClient.invalidateQueries({ queryKey: [qkCustomMaps, clientId] })
    }

    function defaultApiErrorHandler<T>(
        error: unknown,
        variables: T,
        context: unknown
    ) {
        if (error instanceof Error) {
        }
    }
    
    const updateCustomMap = useMutation({
        mutationFn: (data: UpdateCustomMappingDto) => cardApi.post(updateCustomMapping, data),
        onSuccess: refetchFromServer,
        onError: defaultApiErrorHandler<UpdateCustomMappingDto>,
    })

    const deleteCustomMapApi = useMutation({
        mutationFn: (data: DeleteCustomMappingDto) => cardApi.post(deleteCustomMapping, data),
        onSuccess: refetchFromServer,
        onError: defaultApiErrorHandler<DeleteCustomMappingDto>,
    })

    function defaultMapId(): Mapping {
        return {
            id: null,
            name: "Standard",
            description: null,
        }
    }

    function defaultMapIdOption(): IdName {
        return {
            id: null,
            name: "Standard",
        }
    }

    /**
     * Get the custom mapping with defaults (retrieved from data dictionary)
     * @param importType Entity type
     * @param mapId !!!Note a mapId of null currently signifies the default mapping
     * @returns Array<DefaultMappingType>
     */
    function getMapColumns(importTypeName: string, mapId: number | null) {
        const mapWithDefaults: Array<DefaultMappingType> =
            defaultMapping?.map((x) => ({ ...x, mappedName: null })) ?? []
        const customMappings = clientMappings.filter(
            (m) =>
                istrEqual(m.importTypeName, importTypeName) && m.mapId == mapId
        )
        mapWithDefaults.forEach((x) => {
            // A map might not have any column mappings yet!
            const found = customMappings.find((y) =>
                y.mappableName
                    ? istrEqual(y.mappableName, x.mappableName)
                    : false
            )

            if (found?.mappedName) {
                x.mappedName = found.mappedName
            }
        })

        return mapWithDefaults
    }

    if (isLoading) {
        return <EllipsisSpinner />
    }

    function doUpdateCustomMap(
        dto: UpdateCustomMappingDto,
        cbSuccess: (
            data: AxiosResponse<CustomMapType>,
            variables: UpdateCustomMappingDto,
            context: unknown
        ) => void,
        cbError: (
            error: unknown,
            variables: UpdateCustomMappingDto,
            context: unknown
        ) => void
    ) {
        updateCustomMap.mutate(dto, { onSuccess: cbSuccess, onError: cbError })
    }

    /**
     * Save the current custom column mappings
     */
    const updateMappings = () => {
        if (!trackUserModifications.current) {
            throw new Error("Cannot find custom map!")
        }

        const dto = trackUserModifications.current.mkUpdateDto(clientId)

        doUpdateCustomMap(
            dto,
            (
                data: AxiosResponse<CustomMapType>,
                variables: UpdateCustomMappingDto,
                context
            ) => {
                toast.success("Custom mapping updated", {
                    autoClose: 1000,
                })
            },
            (
                error: unknown,
                variables: UpdateCustomMappingDto,
                context: unknown
            ) => {
                if (error instanceof Error) {
                    toast.error(
                        `Error updating custom mapping: , ${error.message}`,
                        { autoClose: 1000 }
                    )
                }
            }
        )
    }

    /**
     * User just selected a different import type
     */
    const onImportTypeChange = async (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        // Check for current changes
        if (trackUserModifications.current?.isModified()) {
            // Prompt to save changes
            await openSaveModal()
        }

        const found = importTypes!.find((x) => x.id === e.target.value)!
        setImportTypeName(found.name)
        setCurrentMap({ ...defaultMapId() })
    }

    const onColumnNameChanged = (
        e: ChangeEvent<HTMLInputElement>,
        newColumnMapping: DefaultMappingType
    ) => {
        const updated = [...currentColumns]
        const found = updated.find((x) =>
            istrEqual(x.mappableName, newColumnMapping.mappableName)
        )!
        found.mappedName = e.target.value
        setCurrentColumns([...updated])
        trackUserModifications.current?.columnNameChange(found)
    }

    /**
     * Map name is changed but the current map we are viewing is not changed to a different map
     */
    const onMapNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentMapState({ ...currentMapState!, name: e.target.value })
        trackUserModifications.current?.mapNameChanged(e.target.value)
    }

    const onMapDescChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentMapState({
            ...currentMapState!,
            description: e.target.value,
        })
        trackUserModifications.current?.mapDescChanged(e.target.value)
    }

    /**
     * User creating a new custom map.
     * We will add the map to the backend and retrieve the real id for the new map.
     * When that's successfully done we'll set the new map as the current map on the UI.
     */
    function addCustomMap() {
        // We use negative id as the convention to identify new mappings
        let newMapId = -randomInteger()
        // Check for id clash
        while (uniqueMapNames.find((x) => x.id == newMapId))
            newMapId = -randomInteger()
        const newMap: Mapping = {
            id: newMapId,
            name: "Your new custom map",
            description: null,
        }

        setNewCustomMap({ ...newMap })
        setDefaultMapSelected(false)

        const dto: UpdateCustomMappingDto = {
            clientId: clientId,
            importTypeName: importTypeName,
            mapId: newMapId,
            mapName: newMap.name!,
            mapDesc: newMap.description,
            updatedColumns: [],
        }

        doUpdateCustomMap(
            dto,
            (
                data: AxiosResponse<CustomMapType>,
                variables: UpdateCustomMappingDto,
                context
            ) => {
                const customMap: CustomMapType = data.data
                setCurrentMap({
                    id: customMap.mapId,
                    name: customMap.mapName,
                    description: customMap.description,
                })
                toast.success("Custom mapping added", {
                    autoClose: 1000,
                })
            },
            (
                error: unknown,
                variables: UpdateCustomMappingDto,
                context: unknown
            ) => {
                if (error instanceof Error) {
                    toast.error(
                        `Error adding custom mapping: , ${error.message}`,
                        { autoClose: 1000 }
                    )
                }
            }
        )
    }

    function selectCustomMap(item: IdName): void {
        if (item.id === null) {
            setCurrentMap(defaultMapId())
            setDefaultMapSelected(true)
        } else {
            const found = uniqueMapNames.find(
                (x) => x.id?.toString() === item.id
            )
            setCurrentMap({
                id: parseInt(item.id),
                name: item.name,
                description: found?.description ?? null,
            })
            setDefaultMapSelected(false)
        }
    }

    /**
     * When user the x on a tab to delete a custom map
     * Dont't delete default map (id===null)
     */
    function deleteCustomMap(item: IdName): void {
        if (item.id) {
            const foundIdx = uniqueMapNames.findIndex(
                (x) => x.id === parseInt(item.id!)
            )

            if (foundIdx === -1) {
                console.error("Custom map id not found!")
                return
            }

            // Only reset the current map if it's being deleted
            if (currentMap.id === parseInt(item.id)) {
                // Use following tab if any
                if (foundIdx + 1 < uniqueMapNames.length) {
                    // Set current map to uniqueMapNames[foundIdx + 1]
                    setCurrentMap({ ...uniqueMapNames[foundIdx + 1] })
                } else if (foundIdx - 1 >= 0) {
                    // Use preceding one if any. Else list will be empty and default map chosen.
                    // Set current map to uniqueMapNames[foundIdx - 1]
                    setCurrentMap({ ...uniqueMapNames[foundIdx - 1] })
                } else {
                    setCurrentMap({ ...defaultMapId() })
                    setDefaultMapSelected(false)
                }
            }

            const itemToDelete = { ...uniqueMapNames[foundIdx] }

            // Remove found item from UI
            setUniqueMapNames([
                ...uniqueMapNames.slice(0, foundIdx),
                ...uniqueMapNames.slice(foundIdx + 1),
            ])

            // Remove custom map from erver
            const dto: DeleteCustomMappingDto = {
                clientId: clientId,
                mapId: parseInt(item.id),
                importTypeName: importTypeName,
            }
            doDeleteCustomMap(dto)
        }
    }

    async function onDeleting(item: IdName) {
        // Confirm delete
        if (!(await openDeleteModal())) {
            return false // No delete
        }

        // Do delete
        closeDeleteModal()
        deleteCustomMap(item)
    }

    /**
     * Table heading
     */
    function heading() {
        return (
            <div
                className={`sticky top-0 flex flex-col ${
                    pageState.gridFormat[0]
                } ${
                    defaultMapSelected
                        ? "bg-hte-shadow-gray-400"
                        : "bg-hte-mid-blue"
                }`}
            >
                <div
                    className={`break-all px-1 text-white ${pageState.gridFormat[1]}`}
                >
                    <span>Default column name</span>
                </div>
                <div className={`px-1 text-white  ${pageState.gridFormat[2]}`}>
                    <span>Mandatory</span>
                </div>
                <div className={`px-1 text-white  ${pageState.gridFormat[3]}`}>
                    <span>Datatype</span>
                </div>
                <div
                    className={`break-all px-1 text-white  ${pageState.gridFormat[4]}`}
                >
                    <span>Description</span>
                </div>
                {!defaultMapSelected && (
                    <div
                        className={`break-all px-1 text-white ${pageState.gridFormat[5]}`}
                    >
                        <span>Mapped column name</span>
                    </div>
                )}
            </div>
        )
    }

    return (
        <>
            <PageTitle title="Import Mappings" />
            <form
                name="importtype"
                noValidate
                onSubmit={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
                className="mt-10 flex flex-col items-center"
            >
                <div className="flex w-10/12 flex-col sm:w-3/12">
                    <div className="w-full bg-hte-mid-blue px-1 align-middle text-white">
                        Select Import Types
                    </div>
                    <div className="w-full">
                        <LocationsSelect
                            name="import-type"
                            className="w-full rounded py-1.5"
                            data={importTypes ?? []}
                            onChange={onImportTypeChange}
                        />
                    </div>
                </div>
                <hr className="mt-3" />
            </form>
            {importTypeName && (
                <form
                    name="mapping"
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    className="flex flex-col items-center"
                >
                    <div className="mt-2 w-10/12">
                        <Tabs
                            options={uniqueMapNames.map((x) => {
                                if (x.id === null) {
                                    return defaultMapIdOption()
                                }

                                return {
                                    id: x.id?.toString(),
                                    name: x.name,
                                }
                            })}
                            onTabSelected={selectCustomMap}
                            defaultOption={{
                                id: currentMap?.id?.toString() ?? null,
                                name: currentMap?.name ?? null,
                            }}
                            hasDataChanged={() =>
                                trackUserModifications.current?.modified ??
                                false
                            }
                            onNewTab={addCustomMap}
                            saveChanges={updateMappings}
                            onTabDeleted={onDeleting}
                        />
                        <div className="flex flex-col border-x border-b bg-hte-light-gray px-2 sm:max-h-[60vh]">
                            <div className="flex pb-1 pt-2 sm:grid sm:grid-cols-12">
                                <div
                                    className={`px-1 ${
                                        defaultMapSelected
                                            ? "bg-hte-shadow-gray-400"
                                            : "bg-hte-mid-blue"
                                    } flex items-center text-white sm:col-span-2`}
                                >
                                    <span>Mapping name</span>
                                </div>
                                <FormInput
                                    className={`border sm:col-span-10 ${
                                        defaultMapSelected
                                            ? "bg-hte-shadow-gray-100 font-bold text-gray-500"
                                            : ""
                                    }`}
                                    name={`map-${currentMapState?.id}`}
                                    value={currentMapState?.name ?? ""}
                                    label=""
                                    errorMessage="Please a name for your mapping"
                                    onChange={onMapNameChanged}
                                    disabled={defaultMapSelected}
                                />
                            </div>
                            <div className="flex pb-2 sm:grid sm:grid-cols-12">
                                <div
                                    className={`px-1 ${
                                        defaultMapSelected
                                            ? "bg-hte-shadow-gray-400"
                                            : "bg-hte-mid-blue"
                                    } flex items-center text-white sm:col-span-2`}
                                >
                                    <span>Mapping description</span>
                                </div>
                                <FormInput
                                    className={`border sm:col-span-10 ${
                                        defaultMapSelected
                                            ? "bg-hte-shadow-gray-100 font-bold text-gray-500"
                                            : ""
                                    }`}
                                    name={`mapdesc-${currentMapState?.id}`}
                                    value={currentMapState?.description ?? ""}
                                    label=""
                                    errorMessage="Please a description for your mapping"
                                    onChange={onMapDescChanged}
                                    disabled={defaultMapSelected}
                                />
                            </div>

                            <div className="overflow-y-auto bg-hte-shadow-gray-300 scrollbar-thin scrollbar-track-hte-mid-blue-200 scrollbar-thumb-hte-mid-blue-100 sm:max-h-[26rem]">
                                {heading()}
                                {currentColumns.map((column) => {
                                    return (
                                        <div
                                            key={
                                                column.mappableName +
                                                currentMapState?.id?.toString()
                                            }
                                            className={`flex flex-col ${pageState.gridFormat[0]} mb-0.5 border-y border-l last:border-b`}
                                        >
                                            <div
                                                className={`break-all bg-gray-100 px-1 text-gray-500 ${pageState.gridFormat[1]}`}
                                            >
                                                <span>
                                                    {column.mappableName}
                                                </span>
                                            </div>
                                            <div
                                                className={`bg-gray-100 px-1 text-gray-500 ${pageState.gridFormat[2]}`}
                                            >
                                                {column.mandatory && (
                                                    <CheckIcon className="h-4 w-4 stroke-red-400" />
                                                )}
                                            </div>
                                            <div
                                                className={`bg-gray-100 px-1 font-medium text-gray-500 ${pageState.gridFormat[3]}`}
                                            >
                                                <span>
                                                    {capitalize(
                                                        column.datatype
                                                    )}
                                                </span>
                                            </div>
                                            <div
                                                className={`break-all bg-gray-100 px-1 text-gray-500 ${pageState.gridFormat[4]}`}
                                            >
                                                <span>
                                                    {column.description}
                                                </span>
                                            </div>
                                            {!defaultMapSelected && (
                                                <input
                                                    className={`w-full pl-1 ${pageState.gridFormat[5]}`}
                                                    name={`input-${
                                                        column.mappableName
                                                    }+${currentMapState?.id?.toString()}`}
                                                    value={
                                                        column.mappedName ?? ""
                                                    }
                                                    onChange={(e) => {
                                                        onColumnNameChanged(
                                                            e,
                                                            column
                                                        )
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                            {!defaultMapSelected && (
                                <div className="flex flex-row justify-end border-x border-b py-2">
                                    <button
                                        type="button"
                                        className="btn-primary"
                                        disabled={
                                            isLoading ||
                                            !trackUserModifications.current
                                                ?.modified
                                        }
                                        onClick={updateMappings}
                                    >
                                        Update
                                    </button>
                                    <button
                                        type="button"
                                        className="btn-danger ml-1"
                                        onClick={() =>
                                            onDeleting({
                                                id:
                                                    currentMap.id?.toString() ??
                                                    null,
                                                name: currentMap.name,
                                            })
                                        }
                                    >
                                        Delete
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            )}
            {deleteModal}
            {saveModal}
        </>
    )
}
