import { Basket } from "../types/Item"

export function getBasket(): Basket {
    return new Map(JSON.parse(localStorage.getItem('basket') ?? "[]"))
}

export function saveBasket(basket: Basket) {
    localStorage.setItem('basket', JSON.stringify([...basket]))
}

export function removeBasket() {
    localStorage.removeItem('basket');
}
