import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { ProcedureCardListDto } from "../dtos";
import { cardApi, cardApiList } from "../Services/ProcdedureCardApi";
import { qkSmartCardList } from "./queryKeys";

const fetchProcedureCardList = ({ queryKey }: QueryFunctionContext): Promise<Array<ProcedureCardListDto>> => {
    const clientId = queryKey[1]
    if (!clientId) {
        return new Promise(resolve => { })
    }
    return cardApi.get(`${cardApiList}clientId=${clientId}`).then(response => response.data)
}

const sortByName = (a: ProcedureCardListDto, b: ProcedureCardListDto) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
}

/**
 * 
 * @param clientId 
 * @returns The full list of [smart card, accounting location] pairs for the client.
 *          For a card with no assigned accounting location the location properties will be set as follows:
 * 
 *              accountingLocationName: ""
 *              accountingLocationId: "00000000-0000-0000-0000-000000000000"
 */
export function useProcedureCardListData(clientId: string | '') {
    return useQuery({
        queryKey: [qkSmartCardList, clientId], queryFn: fetchProcedureCardList,
        select: (data) => {
            return data.sort(sortByName)
        }
    })
}