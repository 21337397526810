import { useNavigate, useParams } from "react-router-dom"
import { ImportJobType, useImportJobs } from "./data-services/useImportJobs"
import { EllipsisSpinner } from "../components/EllipsisSpinner/EllipsisSpinner"
import { useImportJobErrors } from "./data-services/useImportJobErrors"
import { PageTitle } from "../components/PageTitle"
import { humanFileSize, istrEqual } from "../utils/string-utils"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { previewPrefix } from "../Services/ProcdedureCardApi"
import { APPROVAL_DENIED, AWAITING_APPROVAL, IMPORT_COMPLETED } from "./ImportJobStatus"

export function ImportJobPage() {
    const { jobId } = useParams()
    const navigate = useNavigate()
    const { data: importJobs, isLoading, isError } = useImportJobs()
    const { data: jobErrors } = useImportJobErrors(jobId)

    if (isLoading) {
        return <EllipsisSpinner />
    }

    if (isError) {
        return <div>Error getting data.</div>
    }

    const job: ImportJobType | undefined = importJobs?.find(
        (x) => x.key === jobId
    )

    const heading = job?.headerRow?.split(",") ?? []
    const firstRow = job?.topFileRow?.split(",") ?? []
    let columnCount = heading ? heading.length : 0
    if (firstRow && firstRow.length > columnCount) {
        columnCount = firstRow.length
    }

    const fieldMap = new Map<string, string>([
        ["key", "Key"],
        ["catalogName", "Catalog Name"],
        ["originalFileName", "Original File Name"],
        ["fileName", "File Name"],
        ["fileFormat", "File Format"],
        ["importTypeName", "Import Type Name"],
        ["importStatusId", "Import Status Id"],
        ["importStatus", "Import Status"],
        ["fileUrl", "File Url"],
        ["totalLineCount", "Total Line Count"],
        ["fileSizeBytes", "File Size"],
        ["headerRow", "Header Row"],
        ["topFileRow", "Top File Row"],
        ["username", "User Name"],
        ["createdDt", "Created Date"],
        ["updatedDt", "Last Processed Date"],
        ["active", "Active"],
    ])

    function approveLink(url: string|null) {
        if (!url)
            return  <button type="button" className="h-10 w-40 bg-green-600 flex items-center justify-center rounded cursor-not-allowed opacity-50" disabled>Approve/Reject</button>
        else 
            return <a href={url} target="_blank" className="h-10 w-40 bg-green-600 flex items-center justify-center rounded">Approve/Reject</a>
    }

    function showButtons(job: ImportJobType | undefined) {
        if (!job) return null

        const containerStyle = 'flex flex-row sm:justify-center gap-2 sm:gap-8 text-white font-medium mt-2'
        const originalDataUrl = getOriginalDataUrl(job.fileUrl)
        const ext = originalDataUrl.split('.').pop()! //Must have extension
        const resultsUrl = originalDataUrl.replace('archive', 'result').replace('.' + ext, '_RESULTS.csv')

        switch (job.importStatusId) {
            case AWAITING_APPROVAL:
                return <>
                    <div className={containerStyle}>
                        {approveLink(job.approveUrl)}
                        <a href={`${previewPrefix}${resultsUrl}`} target="_blank" className="h-10 w-40 bg-blue-600 flex items-center justify-center rounded">Review</a>
                    </div>
                </>
            case IMPORT_COMPLETED:
            case APPROVAL_DENIED:
                return <>
                    <div className={containerStyle}>
                        <a href={`${previewPrefix}${resultsUrl}`} target="_blank" className="h-10 w-40 bg-blue-600 flex items-center justify-center rounded">View results</a>
                        <a href={`${previewPrefix}${originalDataUrl}`} target="_blank" className="h-10 w-40 bg-blue-600 flex items-center justify-center rounded">View original</a>
                    </div>
                </>
            default:
                return null
        }

        function getOriginalDataUrl(fileUrl:string) {
            const tempArr = fileUrl.split('/')
            tempArr.splice(-1, 0, 'archive')
            const originalDataUrl = tempArr.join('/')
            return originalDataUrl
        }
    }

    return (
        <>
            <PageTitle title="Import Job Details" />
            <div className="relative">
                <XMarkIcon
                    role="button"
                    onClick={() => {
                        navigate(-1)
                    }}
                    className="absolute right-0 -top-3 h-6 w-6 text-slate-500 hover:bg-slate-200"
                />
                <div className="mt-3 flex flex-col items-center">
                    <div className="sm:w-10/12">
                        {/* Job details */}
                        <div className={`bg-hte-dark-blue-600 text-xl font-medium text-white ${jobErrors?.length??0>0?'sm:mt-3':''} sm:flex sm:justify-center`}>
                            Import Job Details
                        </div>
                        <div className="border sm:border-none">
                            {Object.entries(job ?? {}).map(([key, val]) => {
                                if (
                                    [
                                        "key",
                                        "catalogName",
                                        "fileName",
                                        "importStatusId",
                                        "fileUrl",
                                        "headerRow",
                                        "topFileRow",
                                        "approveUrl",
                                        "rejectUrl",
                                    ].includes(key)
                                ) {
                                    return
                                }

                                if (key === "importTypeName" && !val) {
                                    val = "To be confirmed"
                                }

                                return (
                                    <div
                                        key={key}
                                        className="mb-[2px] grid grid-cols-1 sm:grid-cols-12 sm:border-b sm:border-r"
                                    >
                                        <div className="bg-slate-100 px-1 sm:col-span-3 md:col-span-2">
                                            {fieldMap.get(key)}
                                        </div>
                                        <div className="px-1 sm:col-span-9 md:col-span-9">
                                            {key !== "fileSizeBytes"
                                                ? val?.toString()
                                                : humanFileSize(Number(val))}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {/* File content preview */}
                        <div className="bg-hte-dark-blue-600 text-xl font-medium text-white sm:mt-3 sm:flex sm:justify-center">
                            File preview
                        </div>
                        {columnCount > 0 && (
                            <div className="max-w-screen-2xl overflow-auto">
                                <table className="w-full bg-slate-100 text-left">
                                    <thead className="bg-slate-200 ">
                                        <tr>
                                            {heading.map((x, index) => (
                                                <th
                                                    key={index}
                                                    scope="col"
                                                    className="border-b border-l"
                                                >
                                                    {x}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {firstRow.map((x, index) => (
                                                <td
                                                    key={index}
                                                    className="border-b border-l"
                                                >
                                                    {x}
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {showButtons(job)}
                        {jobErrors && jobErrors.length>0 && <>
                            <div className="mt-2 text-lg font-medium text-white bg-hte-dark-blue-600 grid grid-cols-1 sm:grid-cols-[1.6fr_0.5fr_10.5fr] ">
                                <div className="px-1 hidden sm:block">
                                        <span>Date</span>
                                </div>
                                <div className="px-1 hidden sm:block">
                                        <span>Status</span>
                                </div>
                                <div className="px-1">
                                        Messages
                                </div>
                            </div>
                            {jobErrors.map((x) => (
                                <div
                                    key={x.id}
                                    className={`grid grid-cols-1 border-x sm:mb-1 sm:grid-cols-[1.6fr_0.5fr_10.5fr] ${istrEqual(x.state, 'error') ? 'text-red-500' : ''}`}
                                >
                                    <div className="border-b px-1 hidden sm:block">
                                        {x.createdDt}
                                    </div>
                                    <div className="border-b border-l px-1 hidden sm:block">
                                        {x.state}
                                    </div>
                                    <div className="overflow-auto border-b border-l px-1">
                                        {x.message}
                                    </div>
                                </div>
                            ))}
                        </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
