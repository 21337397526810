import { ChangeEvent, InputHTMLAttributes, useState } from 'react'
type Props = InputHTMLAttributes<HTMLInputElement> & { label: string, errorMessage: string, className: string }
export function FormInput2(props: Props) {
    const { label, errorMessage, className, ...defaults } = props
    const [blurred, setBlurred] = useState(false)
    const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
        setBlurred(true)
    }
    return (<>
        <input {...defaults}
            onBlur={handleBlur} data-blurred={blurred.toString()} className={`form-input pl-1 rounded ${className}`}/>
        <span className="hidden text-xs p-0.5 text-red-700">{errorMessage}</span>
    </>)
}