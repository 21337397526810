import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { useClientStore } from "../../hooks/clientStore"
import { cardApi, getImportJobs } from "../../Services/ProcdedureCardApi";
import { qkImportJobs } from "../../hooks/queryKeys";
import { z } from "zod"

const ImportJobSchema = z.object({
  key: z.string(),
  catalogName: z.string(),
  originalFileName: z.string(),
  fileName: z.string(),
  fileFormat: z.string(),
  importTypeName: z.string().nullable(),
  importStatusId: z.number(),
  importStatus: z.string(),
  fileUrl: z.string(),
  totalLineCount: z.number(),
  fileSizeBytes: z.number(),
  headerRow: z.string().nullable(),
  topFileRow: z.string().nullable(),
  username: z.string(),
  createdDt: z.string(),
  updatedDt: z.string(),
  active: z.boolean(),
  approveUrl: z.string().nullable(),
  rejectUrl: z.string().nullable(),
})

const apiResponse = ImportJobSchema.array()

export type ImportJobType = z.infer<typeof ImportJobSchema>

const fetchImportJobs = ({ queryKey }: QueryFunctionContext) => {
    const clientId = queryKey[1]
    return cardApi.post(`${getImportJobs}`, { clientId: clientId }).then(response => apiResponse.parse(response.data))
}

export function useImportJobs() {
    const clientId = useClientStore(s => s.clientId)

  return useQuery({
    queryKey: [qkImportJobs, clientId],
    queryFn: fetchImportJobs,
    refetchOnWindowFocus: true,
  })
}