import { NavBar } from './components/NavBar'
import { Route, Routes } from 'react-router-dom'
import { NewProcedureCard } from './components/NewProcedureCard'
import { createContext, useEffect, useState } from 'react'
import { SmartCardPage } from './components/SmartCardPage'
import { Item } from './types/Item'
import { BasketPage } from './components/BasketPage'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { getBasket, saveBasket } from './utils/basket'
import { SmartCardListPage } from './components/SmartCardListPage'
import { QRPage } from './components/QRPage'
import { AssignmentPage } from './components/AssignmentPage'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { InviteUserPage } from './components/InviteUserPage'
import { NoClientPage } from './components/NoClientPage'
import { ValidateUser } from './components/ValidateUser'
import { ManageUsersPage } from './components/ManageUsersPage'
import { UpdateUserPage } from './components/UpdateUserPage'
import { ProtectedRoute } from './routing/ProtectedRoute'
import { TopLayout } from './components/layouts/TopLayout'
import { SmartCardLayout } from './components/layouts/SmartCardLayout'
import { ViewImports } from './DataLoader/ViewImports'
import * as routeConstants from './routing/routes'
import { ADD_USER_PAGE, ASSIGNED_CARD_PAGE, USERS } from './routing/routes'
import { ImportJobPage } from './DataLoader/ImportJobPage'
import { FileUploadPage } from './DataLoader/FileUploadPage'
import { MultiMappingsPage } from './DataLoader/CustomMappings/MultiMappingsPage'
import { SelectPortalPage } from './components/SelectPortalPage'
import { useClientStore } from './hooks/clientStore'
import { ApprovalChainsPage } from './components/ApprovalChainsPage'


const queryClient = new QueryClient()
interface IBasketContext {
	basket: Map<number, Item>,
	setBasket: React.Dispatch<React.SetStateAction<Map<number, Item>>>
}

export const BasketContext = createContext<IBasketContext | null>(null)

function App() {
	const userRole = useClientStore(s => s.userRole)
	const [basket, setBasket] = useState(getBasket())
	const showSmartCard = true;

	useEffect(() => {
		saveBasket(basket)
	}, [basket])

	return (
		<QueryClientProvider client={queryClient}>
			<BasketContext.Provider value={{ basket, setBasket }}>
				<ValidateUser />
				<UnauthenticatedTemplate>
					<NavBar />
					<div className='flex justify-center'>
						<h3 className="text-3xl font-normal leading-normal mt-0 mb-2 text-sky-400">
							Please sign in to use your smart cards.
						</h3>
					</div>
				</UnauthenticatedTemplate>
				<AuthenticatedTemplate>
					<Routes>
						<Route element={<ProtectedRoute roles={['admin']} />}>
							<Route element={<TopLayout />}>
								<Route element={<SmartCardLayout />}>
									{showSmartCard &&
									<>
									<Route path='/' element={<SmartCardListPage />} />
									<Route path='/new' element={<NewProcedureCard />} />
									<Route path='/basket' element={<BasketPage />} />
									<Route path='/pc/list' element={<SmartCardListPage />} />
									<Route path="/pc/:pcId" element={<SmartCardPage />} />
									<Route path="/pc/:pcId/locations" element={<AssignmentPage />} />
									<Route path="/pc/:pcId/locations/:locId/qr" element={<QRPage />} />

									<Route path={ASSIGNED_CARD_PAGE} element={<SmartCardPage />} />
									</>
									}
									
									<Route path='/message' element={<NoClientPage />} />

									<Route path={ADD_USER_PAGE} element={<InviteUserPage />} />
									<Route path={USERS} element={<ManageUsersPage />} />
									<Route path={`${USERS}/:userId`} element={<UpdateUserPage />} />
									
									<Route path={routeConstants.SELECT_PORTAL} element={<SelectPortalPage />} />
									<Route path={routeConstants.APPROVAL_CHAINS} element={<ApprovalChainsPage />} />

									{!showSmartCard && <Route path='/' element={<ViewImports />} />}
									<Route path={routeConstants.DL_NEWIMPORT} element={<FileUploadPage />} />
									<Route path={routeConstants.DL_VIEWIMPORTS} element={<ViewImports />} />
									<Route path={`${routeConstants.DL_VIEWIMPORTS}/:jobId`} element={<ImportJobPage />} />
									<Route path={routeConstants.DL_MAPPINGS} element={<MultiMappingsPage />} />
								</Route>
							</Route>
						</Route>
						<Route element={<ProtectedRoute roles={['user']} />}>
							<Route element={<SmartCardLayout />}>
								<Route path="/pc/:pcId/locations/:locId/user" element={<SmartCardPage />} />
							</Route>
						</Route>
					</Routes>
				</AuthenticatedTemplate>
				<ToastContainer position="top-center" autoClose={1000}/>
			</BasketContext.Provider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	)
}

export default App 
