import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useClientStore } from "../hooks/clientStore";
import { useLocationData } from "../hooks/useLocationData";
import { useSmartCardData } from "../hooks/useSmartCardData"
import { EmptyGuid } from "../settings";
import { EnumLocations } from "../types/Item";
import { AccountingLocation } from "../utils/location";
import { EllipsisSpinner } from "./EllipsisSpinner/EllipsisSpinner";
import { ItemsTable } from "./ItemsTable"
import { UserItemsTable } from "./UserItemsTable";

/**
 * This component allows viewing/editing items in a card currently handle 3 routes.
 * '/pc/:pcId' - Admin user viewing/editing a card
 * '/pc/:pcId/locations/:locId/user' - Regular user viewing a card
 * '/pc/:pcId/locations/:locId/admin' - Admin user assigning a card
 */
export function SmartCardPage() {
    const clientId = useClientStore(s => s.clientId)
    const isAdmin = useClientStore(state => state.isAdmin)
    const { pcId, locId: accountingLocationId } = useParams();

    const [accountingLocation, setAccountingLocation] = useState<AccountingLocation | null>(null)
    const { data: smartCard, isLoading: isCardLoading } = useSmartCardData(pcId ?? EmptyGuid, accountingLocationId ?? EmptyGuid)
    const { data: clientLocations, isLoading: isLocLoading, isError: isLocError, error: locError } = useLocationData(clientId)

    useEffect(() => {
        setAccountingLocation(getAccountingLocation(accountingLocationId))
    }, [clientLocations])

    if (isLocLoading) {
        return <EllipsisSpinner />
    }

    if (locError instanceof Error) {
        return <div>Error! {locError.message}</div>
    }

    function getAccountingLocation(locID: string | undefined): AccountingLocation | null {
        if (!locID || locID === EmptyGuid) return null
        const found = clientLocations?.find(x => x.locationID === locID && (x.locationType === EnumLocations.AccountingLocation || x.locationType === EnumLocations.ShipLocation))
        if (found) {
            return {
                salesAccountUid: found.salesAccountUid,
                salesAccountCode: found.salesAccountCode,
                billToAddressUid: found.billToAddressUid,
                billToAddressCode: found.billToAddressCode,
                shipToAddressUid: found.shipToAddressUid,
                shipToAddressCode: found.shipToAddressCode,
                locationID: found.locationID,
                locationName: found.locationName,
                clientID: found.clientID
            }
        }
        return null
    }

    return (
        <>
            <div className="w-full flex items-stretch flex-col bg-gray-200 text-gray-600 rounded mb-1 p-1">
                <div className="inline-flex mb-1 sm:w-1/2">
                    <div className="rounded w-1/3 bg-sky-600 pl-1 text-sky-50 align-middle">Name</div>
                    <div className="w-2/3 pl-1">{smartCard?.name}</div>
                </div>
                {accountingLocation && <div className="inline-flex sm:w-1/2">
                    <div className="rounded w-1/3 bg-sky-600 pl-1 text-sky-50 align-middle">Location</div>
                    <div className="w-2/3 pl-1">{accountingLocation?.locationName}</div>
                </div>}
            </div>
            {isCardLoading && 'Loading...'}
            {!isCardLoading && smartCard && isAdmin() && <ItemsTable data={smartCard.items} smartCard={smartCard} accountingLocation={accountingLocation} />}
            {!isCardLoading && smartCard && !isAdmin() && accountingLocation && <UserItemsTable data={smartCard.items} smartCard={smartCard} accountingLocation={accountingLocation} />}
        </>
    )
}