import { useEffect, useState } from "react";
import { CellContext, Row, RowData } from "@tanstack/react-table";
import { SelectOption } from "./LocationsSelect";
import { ApprovalChainType } from "../hooks/useApprovalChains";

// Type updateData
declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: <T>(rowIndex: number, columnId: string, value: T) => void
  }
}

//-1, 0, or 1 - access any row data using rowA.original and rowB.original
export function makeSelectSortFn<T>(options: Array<SelectOption>) {
  return (rowA: Row<T>, rowB: Row<T>, columnId: string) => {
    const nameA = options.find(x=>x.id===rowA.getValue<string>(columnId))?.name??'unknown'
    const nameB = options.find(x=>x.id===rowB.getValue<string>(columnId))?.name??'unknown'
    return nameA.localeCompare(nameB)
  }
}

export function makeCellSelect(options: Array<SelectOption>) {
  return function <T>({ getValue, row, column, table }: CellContext<ApprovalChainType, T>) {
    const initialValue = getValue();
    const [value, setValue] = useState<T>(initialValue);

    function onChangeHandler(e: React.ChangeEvent<HTMLSelectElement>) {
      setValue(e.target.value as T);
      table.options.meta?.updateData<T>(row.index, column.id, e.target.value as T);
    }

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <select
        
        value={value as string}
        className="w-full text-gray-700 border border-solid border-gray-200"
        aria-label="Select"
        onChange={onChangeHandler}>
        {options?.map((x) => (<option key={x.id} value={x.id}>{x.name}</option>))}
      </select>
    );
  };
}
export default makeCellSelect;