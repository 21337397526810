import { ArrowUpOnSquareStackIcon, Bars4Icon, BriefcaseIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CardChecklistIcon } from "../icons/CardCheckListIcon";
import { menuNames } from "./SideBar";

export interface SideBarSubMenuSpec {
    name: string
    options: Array<{ name: string, url: string, }>
}

interface SideBarSubMenuProps {
    menu: SideBarSubMenuSpec,
    parentSideBarOpen: boolean
}

export function SideBarSubMenu({ menu, parentSideBarOpen }: SideBarSubMenuProps) {
    const [openSubmenu, setOpenSubmenu] = useState(false)

    useEffect(() => {
        if (!parentSideBarOpen) {
            setOpenSubmenu(false)
        }
    }, [parentSideBarOpen])

    /**
     * Set icon for the submenu
     * @param subMenuName 
     * @returns 
     */
    function getIcon(subMenuName: string) {
        switch (subMenuName) {
            case menuNames.Admin:
                return <BriefcaseIcon className="w-5 h-5" />
            case menuNames.SmartCard:
                return <CardChecklistIcon className="w-5 h-5" />
            case menuNames.DataLoader:
                return <ArrowUpOnSquareStackIcon className="w-5 h-5" />
            default:
                return <Bars4Icon className="w-5 h-5" />
        }
    }

    return (
        <>
            <button type="button" className={`flex rounded-md p-2 cursor-pointer hover:bg-slate-600 text-gray-300 text-base items-center ${!parentSideBarOpen && "justify-center"} gap-x-4 mt-2 false`} aria-controls="dropdown-example" data-collapse-toggle="dropdown-example"
                onClick={() => setOpenSubmenu(!openSubmenu)}>
                {getIcon(menu.name)}<span className={`${!parentSideBarOpen && "hidden"} origin-left duration-200`}>{menu.name}</span>
                <div className={`flex justify-end grow ${!parentSideBarOpen && "hidden"}`}>
                    <ChevronDownIcon className={`w-5 h-5 ${openSubmenu && 'rotate-180'}`} />
                </div>
            </button>
            {openSubmenu && <ul id="dropdown-example" className="py-2 space-y-2 ml-3">
                {menu.options.map((x, i) => (
                    <li key={x.name + i}>
                        <NavLink to={x.url} className="flex items-center w-full p-2 text-base font-normal text-gray-300 transition duration-75 rounded-lg pl-11 group hover:bg-slate-600">{x.name}</NavLink>
                    </li>
                ))}
            </ul>}
        </>
    )
}