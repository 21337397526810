import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { useClientStore } from "../../hooks/clientStore"
import { cardApi, getImportTypes } from "../../Services/ProcdedureCardApi";
import { qkImportTypes } from "../../hooks/queryKeys";
import { z } from "zod"

const ImportTypeSchema = z.object({
  importTypeName: z.string(),
})

const apiResponse = ImportTypeSchema.array()

type ImportType = z.infer<typeof ImportTypeSchema>

const fetchImportTypes = ({ queryKey }: QueryFunctionContext) => {
    const clientId = queryKey[1]
    return cardApi.get(`${getImportTypes}?clientId=${clientId}`).then(response => apiResponse.parse(response.data))
}

export function useImportTypes() {
    const clientId = useClientStore(s => s.clientId)

  return useQuery({ queryKey: [qkImportTypes, clientId], queryFn: fetchImportTypes, staleTime: Infinity })
}