import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { useClientStore } from "../../hooks/clientStore"
import { cardApi, getCurrentMappings } from "../../Services/ProcdedureCardApi";
import { qkMappings } from "../../hooks/queryKeys";
import { z } from "zod"

const MappingSchema = z.object({
  importTypeName: z.string(),
  mappableName: z.string().nullable(), // When map doesn't have any mappings yet!
  mapId: z.number().nullable(),
  mapName: z.string().nullable(),
  mappedName: z.string().nullable(),
})

const apiResponse = MappingSchema.array()

export type MappingType = z.infer<typeof MappingSchema>

const fetchCurrentMappings = ({ queryKey }: QueryFunctionContext) => {
  const clientId = queryKey[1]
  return cardApi.post(getCurrentMappings, {clientId: clientId}).then(response => apiResponse.parse(response.data))
}

export function useCurrentMappings() {
    const clientId = useClientStore(s => s.clientId)

  return useQuery({ queryKey: [qkMappings, clientId], queryFn: fetchCurrentMappings, staleTime: Infinity })
}