import { qkIdSearch } from './queryKeys';
import { cardApi, itemApiUrl } from '../Services/ProcdedureCardApi';
import { extractItemSource, SearchResponseDto } from '../Services/ProductSearch';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { useClientStore } from '../hooks/clientStore';

/**
 * 
 * @param queryKey[1] - comma list of item ids 
 * @param queryKey[2] - clientId
 * @returns 
 */
const fetchItems = ({ queryKey }: QueryFunctionContext): Promise<SearchResponseDto> => {
    const commaIds = queryKey[1]
    const clientId = queryKey[2]
    if (!commaIds) {
        return new Promise(resolve => { })
    }
    return cardApi.post(`${itemApiUrl}`, {
        "RequestType": "IdSearch",
        "ClientId": `${clientId}`,
        "Search": `${commaIds}`,
        "Skip": "0",
        "Top": 50,
    })
    .then(response => response.data)
}

export function useIdSearch(commaIds: string | '') {
    const clientId = useClientStore(s => s.clientId)
    return useQuery({
        queryKey: [qkIdSearch, commaIds, clientId], queryFn: fetchItems,
        select: (data) => {
            const items = extractItemSource(data.response)
            return items
        }
    })
}
