import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { useClientStore } from "../hooks/clientStore"
import { ItemInventoryDto } from "../dtos"
import { cardApi, cardApiInventory } from "../Services/ProcdedureCardApi"


const fetchInventory = ({ queryKey }: QueryFunctionContext): Promise<ItemInventoryDto[]> => {
    const clientId = queryKey[1]
    const accountingLocationId = queryKey[2]
    const itemIds = queryKey[3]
    if (!itemIds || itemIds === ''||!accountingLocationId) {
        return Promise.resolve<ItemInventoryDto[]>([])
    }
    return cardApi.get(`${cardApiInventory}clientId=${clientId}&salesaccountId=${accountingLocationId}&itemIds=${itemIds}`).then(response => response.data)
}

/**
 * Get inventory info for given list of items
 * @param pcId Smart card
 * @param accountingLocationId Accounting location
 * @param itemIds Comma separated list of item ids
 * @returns 
 */
export function useInventory(accountingLocationId: string, itemIds: string) {
    const clientId = useClientStore(s => s.clientId)

    return useQuery({ queryKey: ['inventory', clientId, accountingLocationId, itemIds], queryFn: fetchInventory })
}