export const MainData = {
    "procedureCardID": "1b47a265-5e9b-4891-868e-198d0e1b00f9",
    "clientName": "JUNERELEASETEST",
    "procedureType": "Test Procedure",
    "createdDate": null,
    "items": [
        {
            "itemID": 130,
            "code": "V_000130",
            "name": "Optical Medical Device",
            "image": "images/meditemimages/Optical Medical Device.png",
            "supplier": "TestCatSupplier",
            "manufacturer": "Millwood Inc",
            "uomName": "Pack",
            "minonhandqty": 30.00000000,
            "maxonhandqty": 50.00000000,
            "lastcheckonhandqty": 3.00000000,
            "pickQty": 111,
            pickLocationID: '',
        },
        {
            "itemID": 131,
            "code": "V_000131",
            "name": "Opthalmology Medical Equipment",
            "image": "images/meditemimages/Opthalmology Medical Equipment.png",
            "supplier": "Testcatameysupplier",
            "manufacturer": "Milner Inc",
            "uomName": "Each",
            "minonhandqty": 30.00000000,
            "maxonhandqty": 50.00000000,
            "lastcheckonhandqty": 3.00000000,
            "pickQty": 6,
            pickLocationID: '',
        },
        {
            "itemID": 132,
            "code": "V_000132",
            "name": "Surgery Operating table bed for opthalmology",
            "image": "images/meditemimages/Surgery Operating table bed for opthalmology.png",
            "supplier": "mb2020test",
            "manufacturer": "Milosi",
            "uomName": "Pack",
            "minonhandqty": 30.00000000,
            "maxonhandqty": 50.00000000,
            "lastcheckonhandqty": 3.00000000,
            "pickQty": 7,
            pickLocationID: '',
        },
        {
            "itemID": 133,
            "code": "V_000133",
            "name": "Opthalmic Forceps",
            "image": "images/meditemimages/Opthalmic Forceps.png",
            "supplier": "testpocircleinvite",
            "manufacturer": "Miltex",
            "uomName": "Box",
            "minonhandqty": 30.00000000,
            "maxonhandqty": 50.00000000,
            "lastcheckonhandqty": 3.00000000,
            "pickQty": 4,
            pickLocationID: '',
        }
    ]
}

export interface ProcedureType {
    ProcedureTypeID: number,
    ProcedureTypeName: string,
    Createddt: string,
}

export const procedureTypes: Array<ProcedureType> = [
    {
      "ProcedureTypeID": 1,
      "ProcedureTypeName": "Test Procedure",
      "Createddt": "2022-07-11T09:44:19.393"
    },
    {
      "ProcedureTypeID": 2,
      "ProcedureTypeName": "Cardiac / Cardiothoracic",
      "Createddt": "2022-08-12T07:44:10.670"
    },
    {
      "ProcedureTypeID": 3,
      "ProcedureTypeName": "General / Miscellaneous",
      "Createddt": "2022-08-12T07:44:46.480"
    },
    {
      "ProcedureTypeID": 4,
      "ProcedureTypeName": "Common Procedures & Surgeries",
      "Createddt": "2022-08-12T07:45:17.690"
    },
    {
      "ProcedureTypeID": 5,
      "ProcedureTypeName": "Neonatal / NICU Procedures",
      "Createddt": "2022-08-12T07:45:45.637"
    },
    {
      "ProcedureTypeID": 6,
      "ProcedureTypeName": "Oncology",
      "Createddt": "2022-08-12T07:46:55.413"
    },
    {
      "ProcedureTypeID": 7,
      "ProcedureTypeName": "Ophthalmology",
      "Createddt": "2022-08-12T07:47:09.533"
    },
    {
      "ProcedureTypeID": 8,
      "ProcedureTypeName": "Orthopedic",
      "Createddt": "2022-08-12T07:47:27.680"
    },
    {
      "ProcedureTypeID": 9,
      "ProcedureTypeName": "Pediatric",
      "Createddt": "2022-08-12T07:47:46.593"
    },
    {
      "ProcedureTypeID": 10,
      "ProcedureTypeName": "Sports Medicine",
      "Createddt": "2022-08-12T07:48:03.607"
    },
    {
      "ProcedureTypeID": 11,
      "ProcedureTypeName": "Transplantation",
      "Createddt": "2022-08-12T07:48:20.750"
    }
  ]