import { ChangeEvent, SelectHTMLAttributes, useState } from "react"

export interface SelectOption {
    id: string,
    name: string
}

interface LocationsSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
    currentSelection?: string,
    errorMessage?: string,
    data: SelectOption[],
    className?: string,
}

export function LocationsSelect({ data, currentSelection = '', errorMessage = '', className = '', ...rest }: LocationsSelectProps) {
    const [blurred, setBlurred] = useState(false)
    const handleBlur = (e: ChangeEvent<HTMLSelectElement>) => {
        setBlurred(true)
    }

    return (
        <>
            <select {...rest}
                onBlur={handleBlur} data-blurred={blurred.toString()}
                className={`form-select 
                    block
                    text-gray-700
                    bg-white bg-clip-padding bg-no-repeat
                    border border-solid border-gray-300
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:outline-none ${className}`}
                aria-label="Locations select"
                defaultValue={currentSelection}>
                <option value="" disabled>Please select...</option>
                {data?.map((x) => (<option key={x.id} value={x.id}>{x.name}</option>))}
            </select>
            <span className="hidden text-xs p-0.5 text-red-700">{errorMessage}</span>
        </>
    )
}
