import { Outlet } from "react-router-dom";
import { NavBar } from "../NavBar";

export function SmartCardLayout() {
    return (
        <div id="smartcard-layout" className="w-full h-full">
            <NavBar />
            <Outlet />
        </div>
    )
}