import { Link } from "react-router-dom"
import { useProcedureCardListData } from "../hooks/useProcedureCardListData"
import { CheckCircleIcon, ChevronDoubleRightIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { useClientStore } from "../hooks/clientStore"
import { SmartCardDeleteDto, sortByName } from "../types/Item"
import { EnumTasks } from "./BasketPage"
import { PageTitle } from "./PageTitle"
import { useConfirmModal } from "../hooks/useConfirmModal"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { cardApi, cardApiDelete } from "../Services/ProcdedureCardApi"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { ProcedureCardListDto } from "../dtos"
import { qkSmartCardList } from "../hooks/queryKeys"

interface assignedStatus {
    id: string,
    name: string,
    isAssigned: boolean,
}

const assignedReducer = (currentResult: Array<assignedStatus>, currentValue: ProcedureCardListDto): Array<assignedStatus> => {
    if (!currentResult)
        currentResult = []
    const found = currentResult.find((item: assignedStatus) => item.id == currentValue.id)

    if (found) {
        if (!found.isAssigned) {
            found.isAssigned = !!currentValue.accountingLocationName
        }
        return currentResult
    }
    currentResult.push({ id: currentValue.id, name: currentValue.name, isAssigned: !!currentValue.accountingLocationName })
    return currentResult
}

/**
 * Build list with assignment status. Assigned smart cards are cards with at least one accounting location
 * @param cards - List of smart cards with no assignment indications.
 * @returns Array<assignedStatus>
 */
function normalize(cards: Array<ProcedureCardListDto>): Array<assignedStatus> {
    return cards.reduce(assignedReducer, []).sort(sortByName)
}

export function SmartCardListPage() {

    const clientId = useClientStore(state => state.clientId)
    const { data, isLoading } = useProcedureCardListData(clientId)
    const [smartCards, setSmartCards] = useState<Array<assignedStatus>>([])

    useEffect(() => {
        setSmartCards(normalize(data ?? []))
    }, [data])

    const { modal, open: openModal, close: closeModal } = useConfirmModal({message:'Please confirm you wish to delete this smart card?', confirm: removeSmartCard})

    const queryClient = useQueryClient()

    const removeCard = useMutation({
        mutationFn: (data: SmartCardDeleteDto) => cardApi.post<SmartCardDeleteDto>(cardApiDelete, data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [qkSmartCardList] })
        },
    })
    const [cardIdToDelete, setCardIdToDelete] = useState<assignedStatus>()

    function removeSmartCard() {
        closeModal()
        if (cardIdToDelete) {
            if (smartCards) {
                setSmartCards([...smartCards?.filter((item) => item.id !== cardIdToDelete.id)])
            }
            removeCard.mutate({ smartCardID: cardIdToDelete?.id, clientId: clientId })
            toast.info('Smart card deleted.', { autoClose: 1000 })
        }
    }

    const confirmDelete = (smartCard: assignedStatus) => (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        setCardIdToDelete(smartCard)
        openModal()
    }

    return (
        <>
            <PageTitle title="View Smart Cards" />
            <div className="flex justify-center mt-4">
                <div className="grid gap-y-1 grid-cols-1 sm:grid-cols-2 sm:gap-3 md:grid-cols-4 mt-1 mx-2 w-10/12">
                    {smartCards?.map((x, idx) => {
                        return (
                            <div key={x.id} className="flex flex-col relative text-white ">
                                <Link to={`/pc/${x.id}`} className={`h-32 font-medium break-all bg-sky-700 hover:bg-sky-600 shadow-md rounded-md pl-1 flex flex-col relative`}>
                                    <div className="relative">{x.isAssigned && <CheckCircleIcon className="h-4 w-4 absolute left-1 top-1" />}
                                        <button type="button" onClick={confirmDelete(x)}><XCircleIcon className="h-4 w-4 absolute right-1 bottom-1 fill-orange-400 hover:fill-red-700" /></button>
                                    </div>
                                    <div className="text-center">{x.name}</div>
                                </Link>
                                <Link to={`/pc/${x.id}/locations`} className='flex text-xs items-center justify-center absolute right-1 bottom-1 hover:bg-sky-500 pl-1 rounded-md'><span className="pb-1">locations</span><ChevronDoubleRightIcon className="h-3 w-3" /></Link>
                            </div>
                        )
                    })}
                </div>
            </div>
            {modal}
        </>
    )
}
