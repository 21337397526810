import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { z } from "zod"
import { cardApi, getApprovalChains } from "../Services/ProcdedureCardApi"
import { useClientStore } from "./clientStore"
import { qkApprovalChains } from "./queryKeys"

export const ApprovalChainSchema = z.object({
    portal: z.string(),
    approvalType: z.string(),
    level: z.number(),
    limit: z.number(),
    rank: z.number(),
    email: z.string(),
    username: z.string(),
    costCentreGuid: z.string().uuid(),
    costCentreName: z.string(),
    userId: z.string().optional()
})

export type ApprovalChainType = z.infer<typeof ApprovalChainSchema>

const apiResponse = ApprovalChainSchema.array()

const fetchApprovalChains = ({ queryKey }: QueryFunctionContext) => {
    const clientId = queryKey[1]
    return cardApi.get(`${getApprovalChains}?clientId=${clientId}`).then(response => apiResponse.parse(response.data))
}

export function useApprovalChains(userDataReady: boolean) {
    const clientId = useClientStore(s => s.clientId)
    return useQuery({
        queryKey: [qkApprovalChains, clientId],
        queryFn: fetchApprovalChains,
        staleTime: Infinity,
        refetchOnWindowFocus: true,
    })
}