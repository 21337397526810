import { IPublicClientApplication, LogLevel, RedirectRequest } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_AADAPPCLIENTID,
    authority: "https://login.microsoftonline.com/common/",
    redirectUri: import.meta.env.VITE_AADAPPREDIRECTURI,
    postLogoutRedirectUri: import.meta.env.VITE_AADAPPREDIRECTURI,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};


export function login(instance: IPublicClientApplication) {
  instance.loginRedirect(loginRequest).catch(e => {
    console.error(e);
  });
}

export function logout(instance: IPublicClientApplication) {
  instance.logoutRedirect().catch(e => {
    console.error(e);
  });
}
