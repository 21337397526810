import { useMutation } from "@tanstack/react-query";
import { FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useClientStore } from "../hooks/clientStore";
import { InviteUserDto } from "../dtos";
import { useFormState } from "../hooks/useFormState";
import { cardApi, cardApiInviteUser } from "../Services/ProcdedureCardApi";
import { FormInput } from "./FormInput";
import { PageTitle } from "./PageTitle";


export function InviteUserPage() {
    const navigate = useNavigate()

    const tenantId = useClientStore(s => s.tenantId)
    const clientId = useClientStore(s => s.clientId)

    const inviteUser = useMutation({ mutationFn: (data: InviteUserDto) => cardApi.post(cardApiInviteUser, data) })

    const emailInputSpec = {
        id: 'email',
        name: 'email',
        label: 'Email',
        placeholder: 'user@company.com',
        errorMessage: 'Email is required.',
        type: 'email',
        required: true,
    }
    const { formRef, formValues, formIsValid, onChangeHandler, isModified, setIsModified } = useFormState({ email: '', userRole: 'User' })
    const { id, ...noid } = emailInputSpec
    const formSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        inviteUser.mutate({ tenantId: tenantId, clientId: clientId, email: formValues.email, role: formValues.userRole }, {
            onSuccess: (data) => {
                setIsModified(false)
                toast.info(`Submitted add user request for email ${formValues.email}`)
            },
            onError: (data, variables, context) => {
                toast.info(`Error found with add user request for email ${formValues.email}`)
            },
        })
    }

    const cancelHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        navigate(-1)
    }

    return (
        <>
            <PageTitle title="Add User Page" />
            <form onSubmit={formSubmit} ref={formRef} className="px-4 text-md font-normal text-gray-600">
                <div className="mb-6">
                    <label htmlFor={emailInputSpec.name} className="block mb-2 text-xl font-medium">Please enter email of the user you wish to add</label>
                    <FormInput className="w-full h-11 rounded border border-gray-100" key={id} {...emailInputSpec} onChange={onChangeHandler} />
                    <h3>User role</h3>
                    <div className="flex flex-col border border-gray-100 pl-1 w-full sm:w-1/4">
                        <div className="flex items-center">
                            <input className="" id="role-radio-1" type="radio" value="User" name="userRole" onChange={onChangeHandler} defaultChecked={formValues.userRole === 'User'} />
                            <label htmlFor="role-radio-1" className="ml-1">Regular user</label>
                        </div>
                        <div className="flex items-center">
                            <input id="role-radio-2" type="radio" value="Admin" name="userRole" onChange={onChangeHandler} />
                            <label htmlFor="role-radio-2" className="ml-1">Admin</label>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-start items-center my-1">
                    <button type="submit" className="btn-primary m-1" disabled={!isModified || !formIsValid()}>Add user</button>
                    <button type="button" className="btn-danger m-1" onClick={cancelHandler}>Cancel</button>
                </div>
            </form>
        </>
    )
}
