import { XMarkIcon } from "@heroicons/react/24/outline";
import { FormEvent, ReactNode, useState } from "react";
import Modal from 'react-modal';
import { RequestStatus, RequestStatusProps } from "../components/RequestStatus";
import { ModalAppSelector } from ".";

Modal.setAppElement(ModalAppSelector());
export interface useRequestStatusModalResult {
    modal: ReactNode,
    open: () => void,
    close: () => void,
    setStatusData: React.Dispatch<React.SetStateAction<RequestStatusProps | undefined>>,
}

export function useRequestStatusModal(): useRequestStatusModalResult {

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    const [statusData, setStatusData] = useState<RequestStatusProps>();

    const modal = <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Request Status Modal"
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        className="requestStatus"
        overlayClassName="itemDeleteModalOverlay"
    >
        <div className="relative bg-white sm:p-3">
            <XMarkIcon role="button" onClick={closeModal} className="w-6 h-6 text-slate-500 absolute top-2 right-2 hover:bg-slate-200" />
            {statusData && <RequestStatus {...statusData} />}
        </div>
    </Modal>

    return (
        {
            modal,
            open: openModal,
            close: closeModal,
            setStatusData
        }
    )
}