import { ChangeEvent, useMemo, useRef, useState } from "react"
import { EmptyGuid } from "../settings"
import { getImageUrl, IProcedureCard, Item } from "../types/Item"
import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query'
import { NumberSpin2 } from "./NumberSpin2"
import { stockLevelStyles } from "../inventory"
import { cardApi, cardApiUpdate } from "../Services/ProcdedureCardApi"
import { useClientStore } from "../hooks/clientStore"
import { useLocationData } from "../hooks/useLocationData"
import { LocationsSelect } from "./LocationsSelect"
import { TrashIcon } from "@heroicons/react/24/outline"
import { toast } from "react-toastify"
import { matchPath, useLocation, useNavigate } from "react-router-dom"
import { useConfirmModal } from "../hooks/useConfirmModal"
import { AccountingLocation, getSelectPickLocations } from "../utils/location"
import { ProcedureCardEditDto } from "../dtos"
import { useInventory } from "../hooks/useInventory"
import { useProductSearchModal } from "../hooks/useProductSearchModal"
import { toTitleCase } from "../utils/string-utils"
import { useImagePrefix } from "../hooks/useImagePrefix"
import { qkSmartCardList, qkSmartCard } from "../hooks/queryKeys"
import { ASSIGNED_CARD_PAGE, mkCardLocationsRoute } from "../routing/routes"

interface ItemsTableProps {
    data: Item[] | undefined,
    smartCard: IProcedureCard,
    accountingLocation: AccountingLocation | null
}

/**
 * Handles Admin user views of a smart card
 */
export function ItemsTable({ data: initialItems, smartCard, accountingLocation }: ItemsTableProps) {

    let buttonText = 'Assign'
    // Don't show pick location when there's no accounting location
    let adminCols = ["", "Item Name", "Quantity", "Qty on hand", "Pick location", "Manufacturer", ""]
    if (!accountingLocation?.locationID) {
        adminCols = adminCols.filter(x => x !== 'Pick location')
        buttonText = 'Update'
    }

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const queryClient = useQueryClient()
    const [itemsOnCard, setItemsOnCard] = useState<Array<Item>>(initialItems ?? [])
    const [isModified, setIsModified] = useState(false)

    const isCardUpdating = Boolean(useIsMutating());
    const clientId = useClientStore(s => s.clientId)
    const { data: clientLocations } = useLocationData(clientId)
    const { data: imagePrefix } = useImagePrefix(clientId)

    const itemIds = useMemo(() => {
        return itemsOnCard.map(x => x.itemID).join()
    }, [itemsOnCard])

    const isCardAssignmentPage = !!matchPath(ASSIGNED_CARD_PAGE, pathname)

    const updateCard = useMutation({
        mutationFn: (data: ProcedureCardEditDto) => cardApi.post(cardApiUpdate, data),
        onSuccess: (data, variables, context) => {
            setIsModified(false)
            if (isCardAssignmentPage) {
                queryClient.invalidateQueries({ queryKey: [qkSmartCardList, clientId] })
                navigate(mkCardLocationsRoute(smartCard.procedureCardID))
            }
        },
        onError: (error, variables, context) => {
            if (error instanceof Error) {
                setIsModified(true)
            }
        },
    })

    // Inventory
    const inventoryQuery = useInventory(accountingLocation?.locationID ?? '', itemIds)

    // Product search modal
    function onProductSelected(product: Item) {
        const found = itemsOnCard.find(x => x.itemID === product.itemID)
        if (!found) {
            setItemsOnCard([...itemsOnCard, product])
            setIsModified(true)
        }
    }

    const { modal: productSearchModal, open: showProductSearchModal, close: hideProductSearchModal } = useProductSearchModal(onProductSelected)

    /**
     * Remove item
     */
    const { modal: removeItemModal, open: openItemModal, close: closeItemModal } = useConfirmModal({message: 'Please confirm removal of the item', confirm: removeItemFromCard})
    const itemToRemoveID = useRef<number | null>(null)
    const confirmItemRemoval = (itemId: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
        itemToRemoveID.current = itemId
        openItemModal()
    }

    function removeItemFromCard() {
        closeItemModal()
        if (itemToRemoveID.current) {
            const remain = itemsOnCard.filter(x => x.itemID !== itemToRemoveID.current)
            setItemsOnCard([...remain])
            updateCard.mutate(makeDto(remain), {
                onSuccess: (data, variables, context) => {
                    queryClient.invalidateQueries({ queryKey: [qkSmartCard] })
                    itemToRemoveID.current = null
                    toast.info('Item removed from card', { autoClose: 500 })
                },
                onError: (error, variables, context) => {
                    if (error instanceof Error) {
                        toast.error(`Error removing item from card: , ${error.message}`)
                        // Reinstate item from itemToRemoveID.current?
                    }
                },
            })
        }
    }

    /**
     * Navigation
     */
    const previousPage = (event: React.MouseEvent<HTMLButtonElement>) => {
        navigate(-1)
    }

    /**
     * Update item
     */
    const quantityChanged = (itemId: number) => (quantity: number | undefined) => {
        let copy = [...itemsOnCard]
        let found = copy.find((item) => item.itemID === itemId)
        if (!found) {
            throw new Error('Smart card item not found')
        }

        if (found.pickQty === quantity) {
            return
        }

        found.pickQty = quantity ?? 0
        setItemsOnCard([...copy])
        setIsModified(true)
    }

    const pickLocationChanged = (itemId: number) => (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let copy = [...itemsOnCard]
        let found = copy.find((item) => item.itemID === itemId)
        if (!found) {
            throw new Error('Smart card item not found')
        }

        if (found.pickLocationID == e.target.value)
            return

        found.pickLocationID = e.target.value
        setItemsOnCard([...copy])
        setIsModified(true)
    }

    function makeDto(itemsInCard: Array<Item>): ProcedureCardEditDto {
        return {
            ...smartCard,
            locationAccountID: accountingLocation?.locationID ?? EmptyGuid,
            locationName: accountingLocation?.locationName ?? '',
            requiredStock: [...itemsInCard.map(x => ({ itemId: x.itemID, quantity: x.pickQty, pickLocationID: x.pickLocationID }))]
        }
    }

    function smGridStyles(colCount: number) {
        if (colCount === 7) {
            return 'smartcard'
        }
        return `sm:grid sm:grid-cols-${colCount} sm:gap-x-2`
    }

    /**
     * Display an item in an admin user view
     * @param item - item data
     * @returns JSX.Element
     */
    function showAdminItem(item: Item) {
        return (
            <div role="row" key={item.itemID} aria-rowindex={item.itemID}
                className={`bg-sky-200 sm:bg-white text-sm font-normal text-gray-600 pb-5 sm:pb-0 mb-3 sm:mb-0 rounded-lg sm:rounded-none m-2 sm:m-0 
                    flex flex-col ${smGridStyles(adminCols.length)} ${stockLevelStyles(item, inventoryQuery.data ?? [])} shadow-lg sm:shadow-none row-hover odd:bg-white even:bg-gray-100`}>
                <div className="py-4 sm:py-0 px-6 sm:px-0 sm:m-1 hidden sm:flex sm:justify-center">
                    <img className="img-24" src={getImageUrl(item.image, imagePrefix)} />
                </div>
                <div role="cell" className="py-4 sm:py-0 px-6 sm:px-0 text-xl sm:text-sm font-large flex sm:self-center">
                    <div className="w-full text-center sm:text-start mt-1">
                        <div>{toTitleCase(item.name)}</div>
                        <div className="text-red-600">{item.code}</div>
                    </div>
                </div>
                <div role="cell" className="py-4 sm:py-1 px-6 sm:px-0 whitespace-nowrap sm:flex-auto h-30 sm:h-fit flex flex-row justify-center sm:justify-start items-center sm:self-center">
                    <div className="text-5xl sm:text-sm w-1/2 sm:w-16 h-32 sm:h-fit ">
                        <NumberSpin2
                            className="border-sky-300 sm:border-gray-300 shadow-md sm:shadow-none"
                            name='quantity'
                            start={item.pickQty}
                            min={0}
                            display='vertical'
                            onChange={quantityChanged(item.itemID)} />
                    </div>
                    <span className="ml-1">{item.uomName}</span>
                </div>
                <div role="cell" className="hidden sm:block pt-4 sm:pt-0 px-6 sm:self-center">
                    <div className="line-heading">On hand quantity</div>
                    <div className="line-text">
                        {item.lastcheckonhandqty}
                    </div>
                </div>
                {accountingLocation?.locationID &&
                    <div role="cell" className="px-6 sm:px-0 sm:py-1 sm:self-center">
                        <div className="line-heading">Pick location</div>
                        <div>
                            <LocationsSelect name={`pickLocation${item.itemID}`} errorMessage="Location is required."
                                data={getSelectPickLocations(clientLocations ?? [], accountingLocation?.salesAccountUid) ?? []}
                                disabled={!!!accountingLocation}
                                className="w-full sm:h-fit"
                                currentSelection={item.pickLocationID === EmptyGuid ? '' : item.pickLocationID}
                                onChange={pickLocationChanged(item.itemID)}
                            />
                        </div>
                    </div>
                }
                <div role="cell" className="hidden sm:block px-6 sm:px-0 sm:self-center">
                    <div className="line-heading">Manufacturer</div>
                    <div className="line-text">
                        {toTitleCase(item.manufacturer)}
                    </div>
                </div>
                <div className="flex justify-center items-start mt-3 sm:mt-1 sm:self-center">
                    <button onClick={confirmItemRemoval(item.itemID)}>
                        <TrashIcon className="h-5 w-5 stroke-red-600 hover:bg-orange-300" />
                    </button></div>
            </div>
        )
    }

    /**
     * Show table grid header row
     * @param headerCols 
     * @returns JSX.Element
     */
    function showHeader(headerCols: string[]): JSX.Element {
        return (
            <div className={`uppercase font-medium bg-sky-500 text-white hidden ${smGridStyles(headerCols.length)}`} role="row">
                {headerCols.map((x, idx) => (<div className="py-3 text-xs" role="columnheader" key={idx}>{x}</div>))}
            </div>
        )
    }

    const updateButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.stopPropagation()

        updateCard.mutate(makeDto(itemsOnCard), {
            onSuccess: (data, variables, context) => {
                toast.success('Card updated.')
            },
            onError: (error, variables, context) => {
                if (error instanceof Error) {
                    toast.error(`Error updating card: , ${error.message}`)
                }
            },
        })
    }

    return (
        <>
            <div className="items-table flex flex-col bg-gray-100">
                <div className="min-w-full divide-y divide-gray-200" role="table">
                    {showHeader(adminCols)}

                    <div className=" sm:bg-white pb-4 sm:pb-0" role="rowgroup">
                        {itemsOnCard?.map(showAdminItem)}
                    </div>
                </div>
                <hr />
                <div className="flex flex-row justify-end items-center my-1">
                    {<button type="button" className="btn-primary m-1" onClick={() => showProductSearchModal()}>Add item...</button>}
                    {<button type="button" className="btn-danger m-1" onClick={previousPage}>Cancel</button>}
                    <button type="button" disabled={isCardUpdating} className="btn-primary m-1" onClick={updateButtonClick}>{buttonText}</button>
                </div>
            </div>
            {removeItemModal}
            {productSearchModal}
        </>
    )
}