import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { logout } from "../authentication";
import { NavBar } from "./NavBar";

export function UnauthorizedPage() {
    const { instance } = useMsal();
    return (
        <>
            <NavBar />
            <div className="flex min-h-full items-center justify-center bg-white sm:py-44">
                <div className="flex flex-col items-center">
                    <div className="text-center text-7xl font-bold text-sky-600">
                        Welcome to Smart360
                    </div>

                    <div className="mt-10 text-center text-3xl font-bold md:text-4xl lg:text-4xl">
                        It appears you are not authorized to view this page. Please sign in as a user
                        with the appropriate permissions.
                    </div>

                    <div className="mt-8 text-sm font-medium text-gray-400 md:text-xl lg:text-2xl">
                        <AuthenticatedTemplate>
                            <button
                                type="button"
                                className="btn-warning m-1"
                                onClick={() => logout(instance)}
                            >
                                Sign Out
                            </button>
                        </AuthenticatedTemplate>
                    </div>
                </div>
            </div>
        </>
    );
}
