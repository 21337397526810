interface RoundPlusIconProps {
    className: string
}

export default function RoundPlusIcon({ className = 'w-10 h-10' }: RoundPlusIconProps) {
    return (
        <svg viewBox="0 0 512 512" className={className}>
            <path fill="#6DC82A" d="M256 512C114.839 512 0 397.161 0 256S114.839 0 256 0s256 114.839 256 256-114.839 256-256 256z" />
            <path fill="#61B325" d="M512 256C512 114.839 397.161 0 256 0v512c141.161 0 256-114.839 256-256z" />
            <path d="M389.594 239.301H272.699V122.406c0-9.225-7.475-16.699-16.699-16.699-9.225 0-16.699 7.475-16.699 16.699v116.895H122.406c-9.225 0-16.699 7.475-16.699 16.699 0 9.225 7.475 16.699 16.699 16.699h116.895v116.895c0 9.225 7.475 16.699 16.699 16.699 9.225 0 16.699-7.475 16.699-16.699V272.699h116.895c9.225 0 16.699-7.475 16.699-16.699.001-9.225-7.474-16.699-16.699-16.699z" />
            <path d="M389.594 239.301H272.699V122.406c0-9.225-7.475-16.699-16.699-16.699v300.587c9.225 0 16.699-7.475 16.699-16.699V272.699h116.895c9.225 0 16.699-7.475 16.699-16.699.001-9.225-7.474-16.699-16.699-16.699z" />
        </svg>
    )
}