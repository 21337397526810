import { Column, Table } from "@tanstack/react-table"
import React from "react"
import { SelectOption } from "./LocationsSelect"

export function TableColumnFilter({
    column,
    table,
    selectOptions
}: {
    column: Column<any, unknown>
    table: Table<any>,
    selectOptions?: Array<SelectOption>
}) {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id)

    const columnFilterValue = column.getFilterValue()

    const sortedUniqueValues = React.useMemo(
        () => Array.from(column.getFacetedUniqueValues().keys()).sort(),
        [column.getFacetedUniqueValues()]
    )

    return (
        <>
            <select
                onChange={(e) =>
                    column.setFilterValue(
                        typeof firstValue === "number"
                            ? Number(e.target.value)
                            : e.target.value
                    )
                }
                value={columnFilterValue?.toString()}
                className="w-full border border-solid border-gray-300 rounded text-gray-500 focus:outline-none"
            >
                <option
                    value={
                        typeof firstValue === "number"
                            ? `${Number.NEGATIVE_INFINITY}`
                            : ""
                    }
                >
                    All
                </option>
                {sortedUniqueValues.map((value) => (
                    <option value={value} key={value}>
                        {selectOptions?(selectOptions.filter(x=>x.id===value)[0]?.name):value}
                    </option>
                ))}
            </select>
        </>
    )
}
