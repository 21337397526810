import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { cardApi, getDefaultMapping } from "../../Services/ProcdedureCardApi"
import { useClientStore } from "../../hooks/clientStore"

import { z } from "zod"
import { qkDefaultMapping } from "../../hooks/queryKeys"

export const DefaultMappingSchema = z.object({
  mappableName: z.string(),
  datatype: z.string(),
  description: z.string().nullable(),
  mandatory: z.boolean(),
  mappedName: z.string().nullable()
})

const apiResponse = DefaultMappingSchema.array()

export type DefaultMappingType = z.infer<typeof DefaultMappingSchema>

const UpdateCustomMappingDtoSchema = z.object({
  clientId: z.string(),
  importTypeName: z.string(),
  mapId: z.number(),
  mapName: z.string(),
  mapDesc: z.string().nullable().default(null),
  updatedColumns: DefaultMappingSchema.array()
})

export type UpdateCustomMappingDto = z.infer<typeof UpdateCustomMappingDtoSchema>

const fetchCurrentMappings = ({ queryKey }: QueryFunctionContext) => {
    const clientId = queryKey[1]
    const importType = queryKey[2]
    return cardApi.get(`${getDefaultMapping}?clientId=${clientId}&importType=${importType}`).then(response => apiResponse.parse(response.data))
}

export function useDefaultMapping(importType: string) {
    const clientId = useClientStore(s => s.clientId)
  return useQuery({ queryKey: [qkDefaultMapping, clientId, importType], queryFn: fetchCurrentMappings, staleTime: Infinity, enabled: !!importType })
}