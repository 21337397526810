import { useState, useEffect } from "react";
import * as LR from "@uploadcare/blocks";
import { PACKAGE_VERSION } from "@uploadcare/blocks/env";
import { PageTitle } from "./../components/PageTitle";
import { useClientStore } from "../hooks/clientStore";
import { uploadcarePublicKey } from "../settings";

LR.registerBlocks(LR);

export function FileUploadPage() {
    const clientId = useClientStore((s) => s.clientId);
    const username = useClientStore((s) => s.username);

    useEffect(() => {
        const config: {metadata: Object} & Element | null = document.querySelector('lr-config')
        if (config) {
            config['metadata'] = { clientId: clientId, username: username }
        }
    }, []);

    return (
        <>
            <PageTitle title={"Import Files"} />
            <div className="flex flex-col gap-8 h-4/5">
                <lr-config
                    ctx-name= "smart360"
                    pubkey= {`${uploadcarePublicKey}`}
                    multiple
                    confirm-upload= "true"
                    img-only="false"
                    accept= ".csv,.xlsx"
                    store
                    camera-mirror="false"
                    source-list= "onedrive, local, url, dropbox, gdrive"
                    max-files= "10"
                    remove-copyright="true"
                    >
                </lr-config>
                <lr-file-uploader-inline
                    css-src={`https://unpkg.com/@uploadcare/blocks@${PACKAGE_VERSION}/web/lr-file-uploader-inline.min.css`}
                    ctx-name="smart360"
                >
                    <lr-upload-ctx-provider ctx-name="smart360" id="uploaderctx">
                    </lr-upload-ctx-provider>
                </lr-file-uploader-inline>
            </div>
        </>
    );
}
