import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { useClientStore } from "../../hooks/clientStore"
import { cardApi, getCustomMaps } from "../../Services/ProcdedureCardApi";
import { qkCustomMaps } from "../../hooks/queryKeys";
import { z } from "zod"

const CustomMapSchema = z.object({
  mapId: z.number(),
  mapName: z.string().nullable(),
  description: z.string().nullable(), 
  importTypeName: z.string()
})

const apiResponse = CustomMapSchema.array()

export type CustomMapType = z.infer<typeof CustomMapSchema>

const fetchCustomMaps = ({ queryKey }: QueryFunctionContext) => {
  const clientId = queryKey[1]
  return cardApi.get(`${getCustomMaps}?clientId=${clientId}`).then(response => {
    return apiResponse.parse(response.data)
  })
}

export function useCustomMaps() {
    const clientId = useClientStore(s => s.clientId)

  return useQuery({ queryKey: [qkCustomMaps, clientId], queryFn: fetchCustomMaps, staleTime: Infinity })
}