import { useEffect } from "react"
import { useNumberSpin } from "../hooks/useNumberSpin"
import { ArrowDownIcon } from "../icons/ArrowDownIcon"
import { ArrowUpIcon } from "../icons/ArrowUpIcon"
import { QtyChangeHandler } from "../types/Item"

export interface NumberSpinProps {
    name: string,
    start?: number,
    className?: string,
    min?: number,
    max?: number,
    vertical?: boolean, // stack arrows vertically
    display?: 'horizontal' | 'vertical'
    onChange?: QtyChangeHandler
}

export function NumberSpin2({ name, vertical = false, start = 1, min = 1, max = 100, className = '', display = 'horizontal', onChange = () => { } }: NumberSpinProps) {
    const noMax = !!max
    min = min ?? 1
    start = start ?? min

    const { inc, dec, value, valueChanged } = useNumberSpin(start, min, max)

    useEffect(() => {
        onChange(value)
    }, [value])

    return (
        <div className={`${className} border grid gap-0 grid-cols-2 grid-rows-2 sm:grid-cols-spin-r sm:grid-rows-1`}>
            <input type="number" name={name} value={value}
                className={`block outline-none px-1 text-center col-span-2 sm:col-span-1`}
                onChange={valueChanged} />
            <button className="outline-none outline-0 min-w-[20px] min-h-[20px] border flex-1 flex justify-center relative" type="button" onClick={inc}>
                <ArrowUpIcon className="absolute h-full w-full" />
            </button>
            <button className="outline-none outline-0 min-w-[20px] min-h-[20px] border flex-1 flex justify-center relative" type="button" onClick={dec}>
                <ArrowDownIcon className="absolute h-full w-full" />
            </button>
        </div>

    )
}