import { EmptyGuid } from './../settings';
import { ClientLocation, EnumLocations } from './../types/Item';
import { cardApi, cardApiLocations } from "../Services/ProcdedureCardApi";
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { AccountingLocationsDto } from '../dtos';
import { istrEqual } from '../utils/string-utils';

const fetchLocations = ({ queryKey }: QueryFunctionContext): Promise<Array<AccountingLocationsDto>> => {
    const clientId = queryKey[1]
    if (!clientId || clientId === EmptyGuid) {
        return new Promise(resolve => [])
    }
    return cardApi.get(`${cardApiLocations}clientId=${clientId}`).then(response => response.data)
}

export function useLocationData(clientId: string | '') {

    return useQuery({queryKey: ['locations', clientId], queryFn: fetchLocations, 
        select: (data): Array<ClientLocation> => {
            return data.map((x: AccountingLocationsDto) => {
                return {
                    salesAccountUid: x.salesAccountUid,
                    salesAccountCode: x.salesAccountCode,
                    billToAddressUid: x.billToAddressUid,
                    billToAddressCode: x.billToAddressCode,
                    shipToAddressUid: x.shipToAddressUid,
                    shipToAddressCode: x.shipToAddressCode,

                    clientID: clientId,
                    locationType: x.addressType,
                    locationCode: x.code,
                    locationName: `${istrEqual(x.addressType, EnumLocations.PickLocation) ? x.address1 : (x.department ? x.name + '-' + x.department : x.name)}`,
                    locationID: x.salesAccountAddressUid,
                }
            })
        }
    })
}
