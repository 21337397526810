import { useParams, useSearchParams } from "react-router-dom";
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useState } from "react";
import { useSmartCardData } from "../hooks/useSmartCardData";


function smSize() {
    if (window.matchMedia("(max-width: 640px)").matches) {
        return true
    }
    return false
}

export function QRPage() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const hasValidId = !!params.pcId
    const [qrSize, setQRSize] = useState(smSize() ? 240 : 320)
    const { data: smartCard, isLoading: isCardLoading } = useSmartCardData(params.pcId!, params.locId!)


    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 640) {
                setQRSize(240)
            } else {
                setQRSize(320)
            }
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (<>
        {hasValidId && <>
            <div className="flex flex-col justify-center items-center w-full">
                <h2 className="text-2xl font-bold text-center">Scan the QR code to view your smart card</h2>
                <div className="text-2xl font-bold">Smart card name:</div>
                <div className="text-xl font-medium">{searchParams.get('name') ?? 'unknown'}</div>
                <QRCodeSVG className="mt-4 sm:mt-24" size={qrSize} value={`${window.location.origin}/pc/${params.pcId}/locations/${params.locId}/user`} />
                <div className="text-xs font-medium text-center">{`${window.location.origin}/pc/${params.pcId}/locations/${params.locId}/user`}</div>
            </div>
            <div className="sm:mx-20 mt-10 border-r border-gray-200">
                <div className="grid grid-cols-1 sm:grid-cols-7 print:grid-cols-7 bg-gray-600 text-slate-100">
                    <div className="print:col-span-2 sm:col-span-2">Name</div>
                    <div className="print:col-span-1 sm:col-span-1">Pick quantity</div>
                    <div className="print:col-span-4 sm:col-span-4">Manufacturer</div>
                </div>
                {smartCard?.items.map((item) => (
                    <div key={item.itemID} className="grid grid-cols-1 sm:grid-cols-7 print:grid-cols-7">
                        <div className="print:col-span-2 sm:col-span-2 border-l border-b border-gray-200">{item.name}</div>
                        <div className="print:col-span-1 sm:col-span-1 border-l border-b border-gray-200">{item.pickQty}</div>
                        <div className="print:col-span-4 sm:col-span-4 border-l border-b border-gray-200">{item.manufacturer}</div>
                    </div>
                ))}
            </div>
        </>
        }
        {!hasValidId &&
            <div>
                <h2>Please provide a valid procedure card id</h2>
            </div>}
    </>)
}