import { QueryFunctionContext, useQuery } from "@tanstack/react-query"
import { z } from "zod"
import { cardApi, getCostCentres } from "../Services/ProcdedureCardApi"
import { useClientStore } from "./clientStore"
import { qkCostCentres, qkUserPortals } from "./queryKeys"

export const CostCentreSchema = z.object({
  guid: z.string().uuid(),
  name: z.string()
})

const apiResponse = CostCentreSchema.array()

const fetchCostCentres = ({ queryKey }: QueryFunctionContext) => {
    const clientId = queryKey[1]
    return cardApi.get(`${getCostCentres}?clientId=${clientId}`).then(response => apiResponse.parse(response.data))
}

export function useCostCentres() {
    const clientId = useClientStore(s => s.clientId)
    return useQuery({
        queryKey: [qkCostCentres, clientId],
        queryFn: fetchCostCentres,
        staleTime: Infinity,
        refetchOnWindowFocus: true,
        select: (data) => {
            return [{id: 'unknown', name: 'Unknown'},...data.map(x => ({ id: x.guid.toUpperCase(), name: x.name }))]
        }
    })
}