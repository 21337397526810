/**
 * Query keys for use with react query (tanstack-query) library
 */
export const qkSmartCardList = 'procedureCardList'
export const qkSmartCard = 'smartCard'
export const qkImagePrefix = 'imagePrefix'
export const qkItemSearch = 'items'
export const qkIdSearch = 'itemsIds'
export const qkImportJobs = 'importJobs'
export const qkImportJobErrors = 'importJobErrors'
export const qkMappingOptions = 'mappingOptions'
export const qkMappings = 'customMappings'
export const qkDefaultMapping = 'defaultMapping'
export const qkImportTypes = 'importTypes'
export const qkCustomMaps = 'customMaps'
export const qkUserPortals = 'userPortals'
export const qkCostCentres = 'costCentres'
export const qkApprovalChains = 'approvalChains'
export const qkApprovalTypes = 'approvalTypes'
export const qkClientUsers = 'clientUsers'

export const fiveMinutes = 5 * 60 * 1000