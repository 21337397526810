import { useRef, MouseEvent, useState, useEffect } from 'react'
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { login, logout } from '../authentication';
import { useClientStore } from '../hooks/clientStore';
import { BasketButton } from './BasketButton';
import { AccountInfo } from '@azure/msal-browser';
import { istrEqual } from '../utils/string-utils';

export function NavBar() {
    const { instance } = useMsal()
    const isAuthenticated = useIsAuthenticated();
    const showSmartCard = true;
    const isProduction = istrEqual('production', import.meta.env.MODE);

    const isAdmin = useClientStore(state => state.isAdmin)
    const clientName = useClientStore(state => state.clientName)

    const [loggedInUser, setLoggedInUser] = useState<AccountInfo | null>(null)

    useEffect(() => {
        if (isAuthenticated) {
            const account = instance.getActiveAccount()
            if (account) {
                setLoggedInUser(account)
            }
        }
    }, [isAuthenticated])

    const ref = useRef<HTMLDivElement>(null)

    /**
     * Toggles mobile view menu
     * @param event 
     */
    const toggleMenu = (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault()
        if (ref) {
            if (ref.current?.classList.contains('hidden')) {
                ref.current?.classList.remove('hidden')
            }
            else {
                ref.current?.classList.add('hidden')
            }
        }
    }

    function displayUserName(name: string) {
        if (isAdmin()) {
            return (
                <div className='text-base px-2 py-2 m-1'>Admin: {name}</div>
            )
        }
        return name
    }

    return (
        <nav className="bg-hte-dark-blue border-gray-200 px-2 sm:px-4 py-2.5 print:hidden">
            <div className="flex flex-wrap justify-between items-center text-sky-50">
                <div className='text-white text-2xl font-medium'>{clientName}</div>
                {!isProduction && <div className='text-white text-2xl font-medium'>({import.meta.env.MODE})</div>}
                <div className='flex flex-row items-center'>
                    <AuthenticatedTemplate>
                        <div className='flex justify-center items-center'>{loggedInUser ? displayUserName(loggedInUser.name ?? 'unknown') : 'Anonymous'}</div>
                        {showSmartCard && isAdmin() && <BasketButton outline />}
                    </AuthenticatedTemplate>
                    <div>
                        {isAuthenticated ?
                            !isAdmin() && <button type="button" className="btn-warning m-1" onClick={() => logout(instance)}>Sign Out</button>
                            : <button type="button" className="btn-success m-1" onClick={() => login(instance)}>Sign In</button>}
                    </div>
                </div>
            </div>
        </nav>
    )
}