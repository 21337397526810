interface BasketIconProps {
    className: string
}
export default function BasketIcon({ className }: BasketIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 460 460"
            version="1.1"
            viewBox="0 0 460 460"
            xmlSpace="preserve"
            className={className}
        >
            <path
                fill="#C2FBFF"
                d="M230 0C102.974 0 0 102.975 0 230s102.974 230 230 230c5.514 0 10.982-.194 16.399-.575l206.802-285.159C428.288 74.171 337.806 0 230 0z"
            ></path>
            <path
                fill="#71E2F0"
                d="M246.398 459.412C365.769 451.001 460 351.511 460 230c0-19.219-2.372-37.882-6.813-55.726l-71.197-71.197-125.843 103.777L152.37 103.077l-80.193 6.193 35.415 35.416-5.415 170.584 144.221 144.142z"
            ></path>
            <path
                fill="#121149"
                d="M181.205 362.77l7.5 27.5c15.188 0 27.5-12.312 27.5-27.5s-12.312-27.5-27.5-27.5l-7.5 27.5z"
            ></path>
            <path
                fill="#273B7A"
                d="M161.205 362.77c0 15.188 12.312 27.5 27.5 27.5v-55c-15.188 0-27.5 12.312-27.5 27.5z"
            ></path>
            <path
                fill="#121149"
                d="M327.785 362.77l7.5 27.5c15.188 0 27.5-12.312 27.5-27.5s-12.312-27.5-27.5-27.5l-7.5 27.5z"
            ></path>
            <path
                fill="#273B7A"
                d="M307.785 362.77c0 15.188 12.312 27.5 27.5 27.5v-55c-15.188 0-27.5 12.312-27.5 27.5z"
            ></path>
            <path
                fill="#D48B07"
                d="M381.99 103.077L381.99 305.27 266.685 305.27 282.076 113.077 302.083 103.076z"
            ></path>
            <path
                fill="#FFC61B"
                d="M282.076 113.077L282.076 305.27 182.177 305.27 182.177 103.077 262.083 103.076z"
            ></path>
            <path fill="#FFF" d="M262.083 103.076H302.083V163.076H262.083z"></path>
            <path
                fill="#121149"
                d="M421.813 315.27L102.177 315.27 102.177 99.27 122.177 99.27 122.177 295.27 142.177 295.27 152.177 285.27 162.177 295.27 421.813 295.27z"
            ></path>
            <path
                fill="#273B7A"
                d="M162.177 295.27h-20v-166c0-11.028-8.972-20-20-20h-50v-20h50c22.056 0 40 17.944 40 40v166z"
            ></path>
        </svg>
    );
}