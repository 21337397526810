import { ChangeEvent, useRef, useState } from "react";

export function useFormState<T extends Object>(state: T) {
    const formRef = useRef<HTMLFormElement>(null)
    const [values, setValues] = useState<T>(state)
    const [isModified, setIsModified] = useState(false)
    const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setValues({ ...values, [e.target.name]: e.target.value })
        setIsModified(true)
    }

    function formIsValid() {
        if (formRef.current) {
            return formRef.current.checkValidity()
        }
        return false
    }

    function clearForm(obj: T) {
        setValues({ ...obj })
        setIsModified(false)
    }

    return {
        formRef: formRef,
        formValues: values,
        onChangeHandler: handleOnChange,
        formIsValid: formIsValid,
        isModified: isModified,
        setIsModified: setIsModified,
        clearForm: clearForm
    }
}